import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { FC, useState } from "react";
import {
    CreateAcademicAuthoritySection,
    CreateAcademicAuthorityMain,
    CreateAcademicAuthorityTop,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useForm } from "react-hook-form";

export const CreateAcademicAuthority: FC = () => {
    const breadcrumbLinks: BreadcrumbLink[] = [
        { title: "Academics / ", path: siteRoutes.academicSessionListing },
        { title: "Authorities / ", path: siteRoutes.academicAuthorityListing },
        { title: "Add Authority", path: siteRoutes.createAcademicAuthority },
    ]
    const [formData, setFormData] = useState({ is_active: 1 });
    const { register } = useForm<any>();

    const handleChange = (event: any) => {

    }

    return (
        <CreateAcademicAuthorityMain>
            <CreateAcademicAuthorityTop>
                <div className="left">
                    <span className="page-heading">Add Authority</span>
                    <Breadcrumb links={breadcrumbLinks} />
                </div>
            </CreateAcademicAuthorityTop>

            <CreateAcademicAuthoritySection className="content-radius-shadow">
                <div className="common-fields">
                    <div className="input-field">
                        <label>Authority Title</label>
                        <div className="field-wrap">
                            <div className="field">
                                <input type="text" placeholder="Enter Title" />
                            </div>
                        </div>
                    </div>
                    <div className="input-field">
                        <label>Description</label>
                        <div className="field-wrap">
                            <div className="field">
                                <input type="text" placeholder="Enter Description" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="submit-buttons">
                    <div className="buttons">
                        <button className="lg-rounded-btn gray" type="button">
                            Reset
                        </button>
                        <button className="lg-rounded-btn">Submit</button>
                    </div>
                </div>
            </CreateAcademicAuthoritySection>
        </CreateAcademicAuthorityMain>
    );
};

export default CreateAcademicAuthority;
