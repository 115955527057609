export class UserManagementDTO {
    id!: number;
    first_name: string = '';
    last_name: string = '';
    cnic: string = '';
    phone_no: string = '';
    email: string = '';
    father_name: string = '';
    date_of_birth: string = '';
    city: string = '';
    status: string = '';
    profile_image: any = '';
    passport_no: string = '';
    country: string = '';
    cnic_image: any = '';
    address: any = '';
    gender: any = '';
    type: string = '';

}