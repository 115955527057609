import { FC, useEffect, useState } from "react";
import { EnrollPoolTeacherForm, EnrollPoolTeacherMain, EnrollPoolTeacherTop } from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { useForm } from "react-hook-form";
import useUtils from "hooks/useUtils";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { EnrollCourseTeacherDTO } from "utils/helpers/models/academics/enroll-course-teacher.dto";
import { CourseTypeDTO } from "utils/helpers/models/academics/course-type.dto";
import { TemplateCourseDTO } from "utils/helpers/models/academics/template-course.dto";
import useAcademics from "../../useHooks";

interface EnrollPoolTeacherProps { }

const EnrollPoolTeacher: FC<EnrollPoolTeacherProps> = ({ }) => {
    const breadcrumbLinks: BreadcrumbLink[] = [
        { title: 'Academics / ', path: siteRoutes.academicSessionListing },
        { title: 'Common Courses Pools / ', path: siteRoutes.commonCoursesPoolsListing },
        { title: 'Pool Teachers /', path: siteRoutes.poolTeachersListing },
        { title: 'Enroll Teacher', path: siteRoutes.enrollPoolTeacher }
    ]
    const { handleSubmit, register, setValue, trigger, formState: { errors } } = useForm<EnrollCourseTeacherDTO>();
    const { getQueryParams } = useUtils();
    const { teacher_id } = getQueryParams();
    const [courseTypes, setCourseTypes] = useState<CourseTypeDTO[]>([]);
    const [templateCourses, setTemplateCourses] = useState<TemplateCourseDTO[]>([]);
    const { getCourseTypes, getTemplateCourses, enrollCourseTeacher } = useAcademics();
    let [formData, setFormData] = useState<EnrollCourseTeacherDTO>({ ...new EnrollCourseTeacherDTO(), teacher_id: '1' });

    const onSubmit = () => {
        enrollCourseTeacher(formData);
    }

    const handleChange = (event: any) => {
        const { value, name } = event.target;
        setValue(name, value);
        trigger(name);
        setFormData({ ...formData, [name]: value });
    }

    const resetForm = () => {
        formData = new EnrollCourseTeacherDTO();
        for (let key in formData) {
            setValue(key as keyof EnrollCourseTeacherDTO, (formData as any)[key]);
        }

        setFormData({ ...formData });
    }

    useEffect(() => {
        getCourseTypes(setCourseTypes);
        getTemplateCourses(setTemplateCourses);
    }, []);

    return (
        <EnrollPoolTeacherMain>
            <EnrollPoolTeacherTop>
                <div className="heading">
                    <span className="page-heading"> Enroll Teacher </span>
                    <Breadcrumb links={breadcrumbLinks} />
                </div>
            </EnrollPoolTeacherTop>
            <EnrollPoolTeacherForm className="content-radius-shadow" onSubmit={handleSubmit(onSubmit)}>
                <div className="common-fields">
                    <div className="input-field">
                        <label htmlFor="application">Course</label>
                        <div className="field-wrap">
                            <div className="field">
                                <select {...register('course_id', { required: true })} value={formData.course_id} onChange={handleChange}>
                                    <option value="">Select Course</option>
                                    {templateCourses.map((course, index) => {
                                        return <option value={course.id} key={index}>{course.tc_title}</option>
                                    })}
                                </select>
                            </div>
                            <FormErrorMessage error={errors?.course_id} />
                        </div>
                    </div>
                    <div className="input-field">
                        <label htmlFor="application">Workload</label>
                        <div className="field-wrap">
                            <div className="field">
                                <select {...register('course_type_id', { required: true })} value={formData.course_type_id} onChange={handleChange}>
                                    <option value="">Select Workload</option>
                                    {courseTypes.map((type, index) => {
                                        return <option value={type.id} key={index}>{type.title}</option>
                                    })}
                                </select>
                            </div>
                            <FormErrorMessage error={errors?.course_type_id} />
                        </div>
                    </div>
                </div>
                <div className="action-buttons">
                    <div className="buttons">
                        <button
                            className="lg-rounded-btn gray"
                            type="button"
                            onClick={resetForm}
                        >
                            Reset
                        </button>
                        <button className="lg-rounded-btn">
                            Submit
                        </button>
                    </div>
                </div>
            </EnrollPoolTeacherForm>
        </EnrollPoolTeacherMain>
    )
}

export default EnrollPoolTeacher;