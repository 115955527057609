import { FC, Fragment, useEffect, useState } from "react";
import {
  DeleteTableSvg,
  EditTableSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  PoolTeachersListingMain,
  PoolTeachersListingSection,
  PoolTeachersListingTop,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { useSelector } from "react-redux";
import useUtils from "hooks/useUtils";
import DataNotFound from "components/particles/table/data-not-found";
import AddTrainingTeacher from "./components/add-teacher-training";
import AddFacultyVisitors from "./components/add-fuculty-visitors";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useAcademics from "../../useHooks";

const PoolTeachersListing: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    {
      title: "Common Courses Pools / ",
      path: siteRoutes.commonCoursesPoolsListing,
    },
    { title: "Pool Teachers (ICT SKILLS)", path: siteRoutes.poolTeachersListing },
  ];
  const columns: string[] = ["Name", "CNIC", "Job Status", "Action"];
  const navigate = useNavigate();
  const { handleSearchChange, handleTableSearch, getQueryParams } = useUtils();
  const { pool_id } = getQueryParams();
  const [data, setData] = useState<any[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [openTrainingModal, setOpenTrainingModal] = useState<boolean>(false);
  const [openFacultyModal, setOpenFacultyModal] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const { getPoolCourseBodies } = useAcademics();
  const [search, setSearch] = useState<string>("");
  const { hasAccess } = useStore();

  useEffect(() => {
    if(!openFacultyModal){
      getAllPoolCourseBodies(pagination.page, search);
    }
  }, [openFacultyModal]);

  const getAllPoolCourseBodies = (page: number, search: string) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      // search,
      pool_id
    };
    getPoolCourseBodies(setData, queryParams, setPagination);
  };

  const goToEnrollTeacher = () => {
    navigate(siteRoutes.enrollPoolTeacher);
  };

  const goToVisitingFuculty = () => {
    setOpenFacultyModal(true);
  };

  return (
    <PoolTeachersListingMain>
      <PoolTeachersListingTop>
        <div className="left">
          <span className="page-heading">Pool Teachers (ICT SKILLS)</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <button className="lg-rounded-btn" onClick={goToVisitingFuculty}>
            + Add Visiting Faculty
          </button>
        </div>
      </PoolTeachersListingTop>
      <PoolTeachersListingSection
        isTableOverflowing={false}
        className="content-radius-shadow"
      >
        <div className="list-header">
          <div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(e, setSearch, getAllPoolCourseBodies)
              }
              onKeyUp={(e) => handleTableSearch(e, getAllPoolCourseBodies)}
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((body, index) => {
                const teacher = body?.teachers?.[0];
                const { teacher_firstname = '', teacher_middlename = '', teacher_lastname = ''} = teacher || {};
                return (
                  <tr key={index}>
                    <td>
                      <div className="mw-150">
                        {`${teacher_firstname} ${teacher_middlename} ${teacher_lastname}`}
                      </div>
                    </td>
                    <td>
                      <div className="mw-100">
                        {body?.cnic ?? '-'}
                      </div>
                    </td>
                    <td>
                      {teacher?.is_active ? <span className="status-tile">Active</span> : <span className="status-tile yellow">Inactive</span>}
                    </td>
                    <td>
                      <div className="table-action-icons">
                        <div className="action-icon">
                          <EditTableSvg />
                        </div>
                        {hasAccess(sitePermissions.enrollPoolTeacher) && <div
                          className="table-action-button"
                          onClick={goToEnrollTeacher}
                        >
                          <button className="black">Enroll Course</button>
                        </div>}
                        <div className="action-icon">
                          <DeleteTableSvg />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          {/* <Pagination onPageChange={onPageChange} {...pagination} /> */}
          {openTrainingModal && (
            <AddTrainingTeacher setOpen={setOpenTrainingModal} />
          )}
          {openFacultyModal && (
            <AddFacultyVisitors setOpen={setOpenFacultyModal} />
          )}
        </Fragment>
      </PoolTeachersListingSection>
    </PoolTeachersListingMain>
  );
};

export default PoolTeachersListing;
