export class SurveyTypesDTO {
  is_active!: number;
  title: string = "";
  relation: string = "";
  do_survey: string = "";
  filled_by: string = "";
  performa: string = "";
  term_id: string = "";
  file: string = "";
}
