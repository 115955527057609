import { ChangeEvent, FC, useEffect, useState } from "react";
import Select from "react-select";
import { CreateOrgMain, Form, TopSection } from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import profileLogo from "assets/images/organization/others/profile-logo.png";
import Editor from "components/particles/forms/editor";
import { useForm } from "react-hook-form";
import { OrganizationDTO } from "utils/helpers/models/organization/organization.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useUtils from "hooks/useUtils";
import useOrganization from "../../useHooks";
import { warningToaster } from "utils/helpers/common/alert-service";
import { warningMessages } from "utils/helpers/enums/messages.enum";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const CreateOrganization: FC = () => {
  const breadcrumbLinks = [
    { title: "Organization /", path: siteRoutes.organizationListing },
    { title: "Add Organization", path: siteRoutes.createOrganization },
  ];
  const { getQueryParams } = useUtils();
  let [formData, setFormData] = useState<OrganizationDTO>(new OrganizationDTO());
  const {
    createOrganization,
    getCitiesSuperAdmin,
    getDistrictsSuperAdmin,
    getOrganizationById,
    updateOrganization,
    getStatesBySuperAdmin,
  } = useOrganization();
  const params = getQueryParams();

  const [cities, setCities] = useState<any[]>([]);
  const [districts, setDistricts] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<OrganizationDTO>({
    defaultValues: formData,
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue(name, value);
    formData[name as keyof OrganizationDTO] = value as never;
    setFormData({ ...formData });
    trigger(name);
  };

  const onSubmit = (data: OrganizationDTO) => {
    if (!formData.logo) {
      warningToaster(warningMessages.logoRequiredMsg);
      return;
    }
    const form_data = new FormData();
    for (let key in data) {
      form_data.append(key, data[key as keyof OrganizationDTO]);
    }
    if (params?.id) {
      updateOrganization(form_data, params?.id);
    } else {
      createOrganization(form_data);
    }
  };

  useEffect(() => {
    if (params?.id)getOrganizationById(params?.id, getValues, setValue, setFormData);
    getCitiesSuperAdmin(setCities);
    getDistrictsSuperAdmin(setDistricts);
    getStatesBySuperAdmin(setStates);
  }, []);

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file: any = event.target.files![0];
    if (file) {
      setValue("logo", file);
      setFormData({ ...formData, logo: URL.createObjectURL(file) });
      trigger(["logo"]);
    }
  };

  const resetForm = () => {
    for (let key in getValues()) {
      setValue(key as keyof OrganizationDTO, "");
    }
    formData = new OrganizationDTO();
    setFormData({ ...formData });
  };

  const onRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target as any;
    setValue(name, value);
    setFormData({ ...formData, [name]: value });
    trigger([name]);
  };
  // Prepare data for react-select
  const cityOptions = cities.map((city: any) => ({
    value: city.id,
    label: city.title,
  }));

  const districtOptions = districts.map((district: any) => ({
    value: district.id,
    label: district.title,
  }));

  const stateOptions = states.map((state: any) => ({
    value: state.id,
    label: state.name,
  }));

  return (
    <CreateOrgMain>
      <TopSection>
        <span className="page-heading">
          {params?.id ? "Update" : "Add"} Organization
        </span>
        {!params?.id && <Breadcrumb links={breadcrumbLinks} />}
      </TopSection>
      <Form className="content-radius-shadow" onSubmit={handleSubmit(onSubmit)}>
        <div className="upload-profile-image-field">
          <div className="field">
            <label className="image" htmlFor={"org-logo"}>
              <img src={formData.logo ?? profileLogo} alt="organization logo" />
              <input
                type="file"
                className="d-none"
                id="org-logo"
                {...register("logo", { required: false })}
                onChange={handleUpload}
              />
            </label>
            <label htmlFor={"org-logo"}>Organization Logo</label>
          </div>
          <FormErrorMessage error={errors?.logo} />
        </div>

        <div className="common-fields">
          {/* Organization Name */}
          <div className="input-field">
            <label>Organization Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("name", { required: true })}
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="xyz"
                />
              </div>
              <FormErrorMessage error={errors?.name} />
            </div>
          </div>

          {/* Website */}
          <div className="input-field">
            <label>Website</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="url"
                  placeholder="xyzdomain.com"
                  {...register("website", { required: true })}
                  value={formData.website}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors?.website} />
            </div>
          </div>

          {/* Email */}
          <div className="input-field">
            <label>Email</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="email"
                  placeholder="xyz@gmail.com"
                  {...register("email", { required: true })}
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors?.email} />
            </div>
          </div>

          {/* Phone */}
          <div className="input-field">
            <label>Phone</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="tel"
                  placeholder="+92 000000000"
                  {...register("phone", { required: true })}
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors?.phone} />
            </div>
          </div>

          {/* Address */}
          <div className="input-field">
            <label>Address</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  placeholder="Street abc"
                  {...register("address1", { required: true })}
                  value={formData.address1}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors?.address1} />
            </div>
          </div>

          {/* City */}
          <div className="input-field">
            <label>City</label>
            <div className="field-wrap">

              <Select
                options={cityOptions}
                onChange={(option: any) => {
                  setValue("city", option?.value);
                  setFormData({ ...formData, city: option?.value });
                  trigger("city");
                }}
                value={cityOptions.find((option) => option.value === formData.city)}

                placeholder="Select City"
              />
              <FormErrorMessage error={errors?.city} />
            </div>

          </div>

          {/* District */}
          <div className="input-field">
            <label>District</label>
            <div className="field-wrap">
              <Select

                options={districtOptions}
                onChange={(option: any) => {
                  setValue("district", option?.value);
                  setFormData({ ...formData, district: option?.value });
                  trigger("district");
                }}
                value={districtOptions.find((option) => option.value === formData.district)}
                placeholder="Select District"
              />
              <FormErrorMessage error={errors?.district} />
            </div>
          </div>

          {/* State */}
          <div className="input-field">
            <label>Select State</label>
            <div className="field-wrap">

              <Select

                options={stateOptions}
                onChange={(option: any) => {
                  setValue("state", option?.value);
                  setFormData({ ...formData, state: option?.value });
                  trigger("state");
                }}
                value={stateOptions.find((option) => option.value == formData.state)}
                placeholder="Select State"
              />

              <FormErrorMessage error={errors?.state} />
            </div>
          </div>

          {/* Password (only for create) */}
          {!params?.id && (
            <div className="input-field">
              <label>Password</label>
              <div className="field-wrap">
                <div className="field">
                  <input
                    type="text"
                    {...register("password", { required: true })}
                    placeholder="Enter Password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </div>
                <FormErrorMessage error={errors?.password} />
              </div>
            </div>
          )}

          {/* Domain Name */}
          <div className="input-field">
            <label>Domain Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("username", { required: true })}
                  placeholder="Enter Domain name"
                  value={formData.username}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors?.username} />
            </div>
          </div>

          {/* Structure Type Radio Buttons */}
          <div className="radio-field">
            <label>Allow Organization to Add Structure Type</label>
            <div className="field-wrap">
              <div className="field">
                <label htmlFor="yes">Yes</label>
                <input
                  type="radio"
                  id="yes"
                  value={1}
                  checked={formData.add_structure_type == 1}
                  {...register("add_structure_type", { required: true })}
                  onChange={onRadioChange}
                />
              </div>
              <div className="field">
                <label htmlFor="no">No</label>
                <input
                  type="radio"
                  id="no"
                  value={0}
                  checked={formData.add_structure_type == 0}
                  {...register("add_structure_type", { required: true })}
                  onChange={onRadioChange}
                />
              </div>
            </div>
            <FormErrorMessage error={errors?.add_structure_type} />
          </div>
        </div>

        <div className="detail-fields">
          {/* Mission */}
          <div className="editor-field">
            <label>Mission</label>
            <div className="field-wrap">
              <div className="field">
                <Editor
                  value={formData.mission}
                  onChange={(name: string, value: string) =>
                    handleChange({ target: { name, value } })
                  }
                  name="mission"
                />
                <input
                  type="text"
                  className="d-none"
                  {...register("mission", { required: false })}
                />
              </div>
            </div>
          </div>

          {/* Vision */}
          <div className="editor-field">
            <label>Vision</label>
            <div className="field-wrap">
              <div className="field">
                <Editor
                  value={formData.vision}
                  onChange={(name: string, value: string) =>
                    handleChange({ target: { name, value } })
                  }
                  name="vision"
                />
                <input
                  type="text"
                  className="d-none"
                  {...register("vision", { required: false })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="action-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
            <button className="lg-rounded-btn" type="submit">
              {params?.id ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </Form>
    </CreateOrgMain>
  );
};

export default CreateOrganization;
