import { FC, Fragment, useEffect, useState } from "react";
import Breadcrumb from "components/particles/breadcrumb";
import {
  RollManagementCreateMain,
  RollManagementCreateTop,
  Form,
  RollManagementCreateSection,
} from "./style";
import useOrganization from "../../useHooks";
import { AddRoleDTO } from "utils/helpers/models/organization/add-role.dto";
import { useForm } from "react-hook-form";
import { warningToaster } from "utils/helpers/common/alert-service";
import { warningMessages } from "utils/helpers/enums/messages.enum";
import useUtils from "hooks/useUtils";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

interface AdmissionStudentListingProps {}

const RollManagementCreate: FC<AdmissionStudentListingProps> = ({}) => {
  const breadcrumbLinks = [
    { title: "System Administration /", path: "" },
    { title: "Create New Role", path: siteRoutes.rollManagementCreate },
  ];
  const [permissions, setPermissions] = useState<any>({});
  const {
    getPermissionsByAdmin,
    createRoleByAdmin,
    updateRoleByAdmin,
    getRoleByIdByAdmin,
  } = useOrganization();
  const [formData, setFormData] = useState<AddRoleDTO>(new AddRoleDTO());
  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<AddRoleDTO>();
  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const toggleRowExpand = (key: string) => {
    permissions[key]["isExpanded"] = !permissions[key]["isExpanded"];
    setPermissions({ ...permissions });
  };

  useEffect(() => {
    if (formData.guard_name) {
      setPermissions([]);
      const { guard_name } = formData;
      const queryParams = { guard_name, per_page: "All" };
      getPermissionsByAdmin(setPermissions, queryParams);
    }
  }, [formData.guard_name]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue(name, value);
    trigger([name]);

    if (name === "guard_name") {
      setFormData({ ...formData, permission: [], [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleChangePermissions = (id: number) => {
    const updatedPermissions = [...formData.permission];
    if (updatedPermissions.includes(id)) {
      updatedPermissions.splice(updatedPermissions.indexOf(id), 1);
    } else {
      updatedPermissions.push(id);
    }
    setFormData({ ...formData, permission: updatedPermissions });
  };

  const onSubmit = (data: AddRoleDTO) => {
    if (!formData.permission.length) {
      warningToaster(warningMessages.permissionsRequiredMsg);
      return;
    }

    if (params?.id) {
      updateRoleByAdmin(params?.id, formData);
    } else {
      createRoleByAdmin(formData);
    }
  };

  const resetForm = () => {
    for (let key in formData) {
      if (key === "permission") {
        setValue(key as keyof AddRoleDTO, []);
      } else {
        setValue(key as keyof AddRoleDTO, "");
      }
    }

    setFormData({ ...new AddRoleDTO() });
  };

  useEffect(() => {
    if (params?.id)
      getRoleByIdByAdmin(params?.id, formData, setValue, setFormData);
  }, []);

  const handleParentCheckboxChange = (key: string, isChecked: boolean) => {
    // Get the permissions for the specified key (parent module)
    const subModulePermissions = permissions[key]?.data;
    if (subModulePermissions) {
      let updatedPermissions = [...formData.permission];

      // Iterate over each submodule and its permissions
      Object.values(subModulePermissions).forEach((permissionArray: any) => {
        if (Array.isArray(permissionArray)) {
          permissionArray.forEach((item: any) => {
            if (isChecked && !updatedPermissions.includes(item.id)) {
              updatedPermissions.push(item.id);
            } else if (!isChecked && updatedPermissions.includes(item.id)) {
              updatedPermissions.splice(updatedPermissions.indexOf(item.id), 1);
            }
          });
        }
      });

      setFormData({ ...formData, permission: updatedPermissions });
    }
  };

  const handleSubModuleChange = (subModulePermissions: any) => {
    const updatedPermissions = [...formData.permission];

    // Check if all permissions for the sub-module are already selected
    const allSelected = subModulePermissions.every((id: any) =>
      updatedPermissions.includes(id)
    );

    if (allSelected) {
      // Remove all permissions if they are already selected
      subModulePermissions.forEach((id: any) => {
        const index = updatedPermissions.indexOf(id);
        if (index > -1) {
          updatedPermissions.splice(index, 1);
        }
      });
    } else {
      // Add all permissions that are not already in the selected list
      subModulePermissions.forEach((id: any) => {
        if (!updatedPermissions.includes(id)) {
          updatedPermissions.push(id);
        }
      });
    }

    setFormData({ ...formData, permission: updatedPermissions });
  };

  return (
    <RollManagementCreateMain>
      <RollManagementCreateTop>
        <div className="left">
          <span className="page-heading">
            {params?.id ? "Update" : "Create New"} Role
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </RollManagementCreateTop>

      <RollManagementCreateSection className="content-radius-shadow">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="filter-fields">
            <div className="input-field">
              <label>Name</label>
              <div className="field-wrap">
                <div className="field">
                  <input
                    type="text"
                    placeholder="Name"
                    {...register("name", { required: true })}
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <FormErrorMessage error={errors.name} />
              </div>
            </div>
            <div className="input-field">
              <label>Role Type</label>
              <div className="field-wrap">
                <div className="field">
                  <select
                    {...register("guard_name", { required: true })}
                    value={formData.guard_name}
                    onChange={handleChange}
                  >
                    <option value="">Select Role Type</option>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                  </select>
                </div>
                <FormErrorMessage error={errors.name} />
              </div>
            </div>
            <div className="input-field">
              <label>Status</label>
              <div className="field-wrap">
                <div className="field">
                  <select
                    {...register("status")}
                    value={formData.status}
                    onChange={handleChange}
                  >
                    <option value="">Select status</option>
                    <option value={0}>No</option>
                    <option value={1}>Yes</option>
                  </select>
                </div>
                <FormErrorMessage error={errors.status} />
              </div>
            </div>
          </div>

          <div className="data-table">
            <table className="bottom-bordered-cells">
              <div className="input-field">
                <label>Permissions:</label>
              </div>
              <tbody>
                {Object.entries(permissions)?.map(
                  ([key, value]: any[], index: number) => (
                    <Fragment key={index}>
                      <tr>
                        <td className="main-drop-down">
                          <div className="check-container">
                            <input
                              type="checkbox"
                              className="parent-check"
                              checked={Object.values(
                                permissions[key]?.data || {}
                              )
                                .flat()
                                .every((item: any) =>
                                  formData.permission.includes(item.id)
                                )}
                              onChange={(e) =>
                                handleParentCheckboxChange(
                                  key,
                                  e.target.checked
                                )
                              }
                            />
                            <div className="mw-150 module-name">{key}</div>
                          </div>
                          <div
                            className="rounded-expand-button"
                            onClick={() => toggleRowExpand(key)}
                          >
                            <span>{value.isExpanded ? "-" : "+"}</span>
                          </div>
                        </td>
                      </tr>

                      {value?.isExpanded && (
                        <tr>
                          <td>
                            <div className="expanded-content">
                              {Object.entries(
                                value?.data as Record<
                                  string,
                                  { id: number; name: string }[]
                                >
                              ).map(([subModuleName, permissions], index) => (
                                <div
                                  className="submodule-container"
                                  key={`submodule-${index}`}
                                >
                                  <div className="sub-module-container">
                                    <span className="sub-module-check">
                                      <input
                                        type="checkbox"
                                        className="child-check"
                                        checked={permissions.every((item) =>
                                          formData.permission.includes(item.id)
                                        )}
                                        onChange={() =>
                                          handleSubModuleChange(
                                            permissions.map((item) => item.id)
                                          )
                                        }
                                      />
                                    </span>
                                    <label className="info">
                                      {subModuleName}
                                    </label>
                                  </div>

                                  <div className="permissions-container">
                                    {Array.isArray(permissions) &&
                                      permissions.map((item, itemIndex) => (
                                        <div
                                          className="permission-container"
                                          key={`permission-${itemIndex}`}
                                        >
                                          <span className="title">
                                            <input
                                              type="checkbox"
                                              className="permission-check"
                                              checked={formData.permission.includes(
                                                item.id
                                              )}
                                              onChange={() =>
                                                handleChangePermissions(item.id)
                                              }
                                            />
                                          </span>
                                          <label className="info">
                                            {item?.name}
                                          </label>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  )
                )}
              </tbody>
            </table>
          </div>
          <div className="submit-buttons">
            <div className="buttons">
              <button
                className="lg-rounded-btn gray"
                type="button"
                onClick={resetForm}
              >
                Reset
              </button>
              <button className="lg-rounded-btn">Submit</button>
            </div>
          </div>
        </Form>
      </RollManagementCreateSection>
    </RollManagementCreateMain>
  );
};

export default RollManagementCreate;
