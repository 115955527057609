import { FC, Fragment, useEffect, useState } from "react";
import {
  CallTableSvg,
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  EditTableSvg,
  ExcelSvg,
  MessageTableSvg,
  PdfSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
  StudentRegListingMain,
  StudentRegListingSection,
  StudentRegListingTop,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";
import useComponentVisible from "hooks/click-outside";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useAdmissions from "../../useHooks";
import { RegStudentDTO } from "utils/helpers/models/admissions/registered-student.dto";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";

interface StudentRegListingProps {}

const StudentRegListing: FC<StudentRegListingProps> = ({}) => {
  const breadcrumbLinks = [
    { title: "Admission /", path: "" },
    { title: "Student Registration", path: siteRoutes.studentRegListing },
  ];

  const { hasAccess } = useStore();
  const [search, setSearch] = useState("");
  const {
    isComponentVisible: showDropdownMenu,
    setIsComponentVisible: setShowDropdownMenu,
    ref: dropdownMenuRef,
  } = useComponentVisible(false);
  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const columns: string[] = [
    "",
    "Application No.",
    "Name",
    "CNIC",
    "Academic Session",
    "Program",
    "Fee Status",
    "Check List",
    "Admission Type",
    "Hostel Facility",
    "Admission Eligibility",
    "Reg Date",
    "Action",
  ];

  // const toggleRowExpand = (index: number) => {
  //   const dat = [...data];
  //   dat[index].isExpanded = !dat[index].isExpanded;
  //   setData([...dat]);
  // };

  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };

  const toggleDropdownMenu = () => {
    setShowDropdownMenu(!showDropdownMenu);
  };
  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const { getRegisteredStudents, getStudentFilterData, getStudentProgram } =
    useAdmissions();

  const getAllprograms = () => {
    const queryParams = {
      category: "",
      academic_session: "",
    };

    getStudentProgram(setPrograms, queryParams);
  };
  const [programs, setPrograms] = useState<any>({});
  const [data, setData] = useState<any>({});
  const [studentData, setStudentData] = useState<any[]>([]);
  const [formData, setFormData] = useState<RegStudentDTO>(new RegStudentDTO());
  const [filters, setFilters] = useState<any>({
    academic_session: "",
    program_id: "",
    fee_status: "",
    is_complete: "",
    type: "",
    hostel_check: "",
    is_eligible: "",
    cnic: "",
    slug: "",
  });
  const toggleRowExpand = (index: number) => {
    const updatedStudentData = [...studentData];
    updatedStudentData[index].isExpanded =
      !updatedStudentData[index].isExpanded;
    setStudentData(updatedStudentData);
  };
  const handleFilterChange = (event: any) => {
    const { name, value } = event.target;
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const getFilteredStudent = (page: number = 1) => {
    const filterOptions = { ...filters };

    for (let key in filterOptions) {
      if (!filters[key]) {
        delete filterOptions[key];
      }
    }

    const queryParams = {
      per_page: pagination.per_page,
      page,
      ...filterOptions,
    };

    getRegisteredStudents((data: any) => {
      const studentsWithExpandState = data?.details.map((student: any) => ({
        ...student,
        isExpanded: false,
      }));
      setStudentData(studentsWithExpandState);
    }, queryParams);
  };

  const handleApplyFilters = () => {
    getFilteredStudent();
  };

  useEffect(() => {
    getRegisteredStudents(setStudentData, formData);
    getStudentFilterData(setData);
    getAllprograms();
  }, []);
  console.log(studentData);

  return (
    <StudentRegListingMain>
      <StudentRegListingTop>
        <div className="left">
          <span className="page-heading">Student Registration</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="menu-bar" ref={dropdownMenuRef}>
            <div className="menu-button cp" onClick={toggleDropdownMenu}>
              <TabPrimaryActionMenu />
            </div>
            {showDropdownMenu && (
              <div className="dropdown-menu content-radius-shadow">
                <div className="particular-menu">
                  <span className="text">City Head Count Campus Wise</span>
                </div>
                <div className="particular-menu">
                  <span className="text">
                    City Head Count Campus Wise(Combine){" "}
                  </span>
                </div>
                <div className="particular-menu">
                  <span className="text">
                    City Head Count Campus Faculty Wise
                  </span>
                </div>
                <div className="particular-menu">
                  <span className="text">
                    City Head Count Campus Faculty Wise(Combine)
                  </span>
                </div>
                <div className="particular-menu">
                  <span className="text">Anomalies</span>
                </div>
                <div className="particular-menu">
                  <span className="text">Bulk Admit Application</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </StudentRegListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label>Academic Session</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="academic_session"
                      onChange={handleFilterChange}
                    >
                      <option value="">Select Academic Session</option>
                      {data?.academic_sessions?.map(
                        (item: any, index: number) => {
                          return (
                            <option value="">{item?.session_title}</option>
                          );
                        }
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Category</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="slug" onChange={handleFilterChange}>
                      <option value="">Select Category</option>
                      {data?.certificate_levels?.map(
                        (item: any, index: number) => {
                          return <option>{item?.title}</option>;
                        }
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Program</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="program_id" onChange={handleFilterChange}>
                      <option value="">All</option>
                      {programs?.programs?.map((item: any, index: number) => {
                        return <option value={item?.id}>{item?.title}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Fee Status</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="fee_status" onChange={handleFilterChange}>
                      <option value="-1">All Status</option>
                      <option value="1">Submitted</option>
                      <option value="0">In-Process</option>
                      <option value="">Not Submitted</option>
                      <option value="2">Invalid</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Check List</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="is_complete" onChange={handleFilterChange}>
                      <option value="0">No</option>
                      <option value="1">Yes</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Admission Type</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="type" onChange={handleFilterChange}>
                      <option value="0">Select Admission Type</option>
                      <option value="Transfer">Transfer</option>
                      <option value="New">New</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Hostel Facility</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="hostel_facility"
                      onChange={handleFilterChange}
                    >
                      <option value="">All</option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Admission Eligibility</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="is_eligible"
                      id="is_eligible"
                      onChange={handleFilterChange}
                    >
                      <option value="-1">All</option>
                      <option value="1">Eligible</option>
                      <option value="0">Not Eligible</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>CNIC</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="cnic"
                      id=""
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray">Reset</button>
                <button className="lg-rounded-btn" onClick={handleApplyFilters}>
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>
      <StudentRegListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {hasAccess(sitePermissions.downloadStudentRegPDF) && (
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>
            )}

            {hasAccess(sitePermissions.downloadStudentRegExcel) && (
              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            )}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              // onKeyUp={handleSearch}
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column, index) => {
                  return (
                    <th
                      key={index}
                      className={
                        index > 6 ? `extra-head extra-head-${index - 6}` : ""
                      }
                    >
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {studentData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <tr className={`expandable ${item.isExpanded && "opened"}`}>
                      <td>
                        <div
                          className="rounded-expand-button"
                          onClick={() => toggleRowExpand(index)}
                        >
                          <span>{item.isExpanded ? "-" : "+"}</span>
                        </div>
                      </td>
                      <td>
                        <div className="mw-100">{item?.app_no}</div>
                      </td>
                      <td>
                        <div className="mw-100">{item?.name}</div>
                      </td>
                      <td>
                        <div className="mw-150">{item?.cnic}</div>
                      </td>
                      <td>
                        <div className="mw-150">{item?.session_title}</div>
                      </td>
                      <td>
                        <div className="mw-150">{item?.program}</div>
                      </td>
                      <td>
                        {item?.fee_status === 1 ? (
                          <span className="status-tile green">Paid</span>
                        ) : item?.fee_status === 0 ? (
                          <span className="status-tile red">Unpaid</span>
                        ) : null}
                      </td>
                      <td className="extra-col extra-col-1">
                        {item?.is_complete === 1 ? "Yes" : "No"}
                      </td>

                      <td className="extra-col extra-col-2">
                        {item?.type === "New" ? "New" : "Transfer"}
                      </td>
                      <td className="extra-col extra-col-3">
                        {item?.hostel_check === 1 ? "Yes" : "No"}
                      </td>
                      <td className="extra-col extra-col-4">
                        {item?.is_eligible === 1 ? "Yes" : "No"}
                      </td>
                      <td className="extra-col extra-col-5">
                        {item?.reg_date}
                      </td>
                      <td className="extra-col extra-col-6">
                        <div className="table-action-icons">
                          {hasAccess(sitePermissions.studentRegEdit) && (
                            <div className="action-icon">
                              <EditTableSvg />
                            </div>
                          )}

                          <div className="action-icon">
                            <CallTableSvg />
                          </div>

                          <div className="action-icon">
                            <MessageTableSvg />
                          </div>

                          {hasAccess(sitePermissions.studentRegDelete) && (
                            <div className="action-icon">
                              <DeleteTableSvg />
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>

                    {item.isExpanded && (
                      <tr className="expanded-row">
                        <td></td>
                        <td colSpan={14}>
                          <div className="expanded-content">
                            <div className="particular-info particular-info-1">
                              <span className="title">Check List</span>
                              <span className="info">
                                {" "}
                                {item?.is_complete === 1 ? "Yes" : "No"}
                              </span>
                            </div>
                            <div className="particular-info particular-info-2">
                              <span className="title">Admission Type</span>
                              <span className="info">
                                {item?.type === "New" ? "New" : "Transfer"}
                              </span>
                            </div>
                            <div className="particular-info particular-info-3">
                              <span className="title">Hostel Facility</span>
                              <div className="info">
                                <span className="status">
                                  {item?.hostel_check === 1 ? "Yes" : "No"}
                                </span>
                              </div>
                            </div>
                            <div className="particular-info particular-info-4">
                              <span className="title">
                                Admission Eligibility
                              </span>
                              <div className="info">
                                <span className="status">
                                  {item?.is_eligible === 1 ? "Yes" : "No"}
                                </span>
                              </div>
                            </div>
                            <div className="particular-info particular-info-5">
                              <span className="title">Reg Date</span>
                              <div className="info">
                                <span className="status">{item?.reg_date}</span>
                              </div>
                            </div>
                            <div className="particular-info particular-info-6">
                              <span className="title">Action</span>
                              <div className="info">
                                <div className="table-action-icons">
                                  {hasAccess(
                                    sitePermissions.studentRegEdit
                                  ) && (
                                    <div className="action-icon">
                                      <EditTableSvg />
                                    </div>
                                  )}

                                  <div className="action-icon">
                                    <CallTableSvg />
                                  </div>

                                  <div className="action-icon">
                                    <MessageTableSvg />
                                  </div>

                                  {hasAccess(
                                    sitePermissions.studentRegDelete
                                  ) && (
                                    <div className="action-icon">
                                      <DeleteTableSvg />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !Object.keys(data)?.length} />
          <Pagination
            onPageChange={(page: any) => console.log(page)}
            {...pagination}
          />
        </Fragment>
      </StudentRegListingSection>
    </StudentRegListingMain>
  );
};

export default StudentRegListing;
