import styled from "styled-components";

export const AcademicSessionListingMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;

export const AcademicSessionListingSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em;
  }

  .list-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      gap: 1em;
    }
  }

  /* table td {
    .org-logo {
      width: 38px;
      height: 35px;
      background: var(--lightgray-extralight);
    }

    .mw-150 {
      min-width: 150px;
    }

    .expanded-content {
      display: flex;
      gap: 0.7em;
      flex-wrap: wrap;
    }
  } */

  table {
    tr {
      td {
        @media screen and (min-width: 2200px) {
          border-bottom: 1px solid var(--lightgray-extralight);
        }
      }
    }
    td {
      &.extra-col {
        display: none;
        ${() => {
          let str = "";
          for (let item of [1, 2, 3, 4, 5, 6, 7, 8, 9]) {
            str += `&.extra-col-${item}{
          @media screen and (min-width: ${1500 + item * 80}px){
            display: table-cell;
          }
        }`;
          }
          return str;
        }}
        &-9 {
          @media screen and (min-width: 2780px) {
            display: table-cell;
          }
        }
      }

      .rounded-expand-button {
        @media screen and (min-width: 1580px) {
          display: none;
        }
      }
    }

    tr.expanded-row {
      @media screen and (min-width: 2780px) {
        display: none;
      }
      .expanded-content {
        .particular-info {
          ${() => {
            let str = "";
            for (let item of [1, 2, 3, 4, 5, 6, 7, 8, 9]) {
              str += `&-${item}{
          @media screen and (min-width: ${1500 + item * 80}px){
            display: none;
          }
        }`;
            }
            return str;
          }}
          .info {
            font-size: 15px;
            font-weight: 400;
            color: var(--lightgray-medium);

            .table-action-icons {
              flex-wrap: wrap;
            }

            .action-button {
              button {
                padding: 0.4em 0.5em;
                color: var(--white-constant);
                background: var(--lightgreen-medium);
                border-radius: 5px;
                font-size: 14px;
                font-weight: 600;

                &.download-lock-list-btn {
                  color: var(--white-color);
                  background: var(--black-text);
                }
                &.download-summary-btn {
                  color: var(--white-constant);
                  background: var(--primary);
                }
                &.extend-date-btn {
                  background: var(--light-orange);
                }
                &.lock-list-btn {
                  background: var(--medium-orange);
                }
                &.unlock-step-btn {
                  background: #718ebf;
                }
                &.bulk-lock-excel-btn {
                  background: #5c5e69;
                }
              }
            }
          }
        }
      }
    }
    thead th {
      &.extra-head {
        display: none;
        ${() => {
          let str = "";
          for (let item of [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
          ]) {
            str += `&-${item}{
          @media screen and (min-width: ${1500 + item * 80}px){
            display: table-cell;
          }
        }`;
          }
          return str;
        }}

        &-9 {
          @media screen and (min-width: 2780px) {
            display: table-cell;
          }
        }
      }
    }
  }
`;

export const AcademicSessionListingTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  @media screen and (max-width: 490px) {
    flex-direction: column;
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }

  .right {
    @media screen and (max-width: 490px) {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
`;
