import { FC, useEffect, useState } from "react";
import { UploadFileMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg, SmallUploadSvg } from "assets/images/common/svgs";
import dummyAvatar from "assets/images/common/others/dummy-avatar.png";

interface UploadAssignmentProps {
  setOpen: Function;
}

const UploadDocument: FC<UploadAssignmentProps> = ({ setOpen }) => {
  return (
    <UploadFileMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Upload Document</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form>
            <div className="common-fields">
              <div className="input-field">
                <label>Document Title</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="text" />
                  </div>
                </div>
              </div>
            </div>
            <div className="upload-field">
              <label>Upload File / File Snapshot</label>
              <label className="field-wrapper">
                <div className="file-name-section">
                  <div className="inner-content">
                    <div className="upload-text">
                      <div className="upload-icon">
                        <SmallUploadSvg className="icon" />
                      </div>
                      <span className="text">Upload File / File Snapshot</span>
                    </div>
                    <div className="upload-restrictions">
                      Select a 300x300 jpg image with maximum size of 400 KB
                    </div>
                  </div>
                </div>
                <div className="uploaded-image">
                  <img src={dummyAvatar} alt="" />
                </div>
                <input type="file" />
              </label>
            </div>

            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="button"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
                <button className="lg-rounded-btn">Save & Close</button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </UploadFileMain>
  );
};

export default UploadDocument;
