import React from "react";
// import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

interface MapComponentProps {
  latitude: string; // Ensure these are numbers
  longitude: string;
  markerTitle?: string;
}

const MapComponent: React.FC<MapComponentProps> = ({
  latitude,
  longitude,
  markerTitle,
}) => {
  const mapContainerStyle = {
    width: "100%",
    height: "300px",
  };

  const center = {
    lat: Number(latitude),
    lng: Number(longitude),
  };

  return (
    //     <LoadScript googleMapsApiKey="https://maps.googleapis.com/maps/api/staticmap?center=Bahawalpur,PK&zoom=13&size=600x300&markers=color:red%7Clabel:C%7CBahawalpur,PK&key=YOUR_API_KEY
    // ">
    //       <GoogleMap
    //         mapContainerStyle={mapContainerStyle}
    //         center={center}
    //         zoom={15}
    //       >
    //         <Marker position={center} title={markerTitle} />
    //       </GoogleMap>
    //     </LoadScript>
    <></>
  );
};

export default MapComponent;
