import { FC, Fragment, useState, useEffect } from "react";

import {
  FDContentSection,
  FDTopSection,
  FinanceDashboardMain,
  HeadlineCard,
  HeadlineCards,
  FilterSection,
  MainStatCard,
  MainStatCards,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  DummyHeadlineGraphSvg,
  GreenFileBgSvg,
} from "assets/images/finance/svgs";
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";
import useTestingServices from "../useHooks";
import useAdmissions from "containers/private/admissions/useHooks";
import { siteRoutes } from "utils/helpers/enums/routes.enum";


ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

interface TestingDashboardProps { }
const TestingDashboard: FC<TestingDashboardProps> = ({ }) => {
  const breadcrumbLinks = [
    { title: "Testing Services /", path: "" },
    {
      title: "Dashboard ",
      path: siteRoutes.testingServicesDashboard,
    },
  ]
  const [data, setData] = useState<any>();
  const { getDashboard, getDashboardContent, getBatch } = useTestingServices();
  const [tests, setTests] = useState<any[]>([]);
  const [sessions, setSessions] = useState<any[]>([]);
  const [batch, setBatch] = useState<any[]>([]);
  const [dashboardContent, setDashboardContent] = useState<any[]>([]);


  const { getAdmissionEntryTests, getAdmissionSessions } = useAdmissions();



  const [filters, setFilters] = useState<any>({
    test_id: '2',
    session_id: '13',
    batch: '',
    date_from: '',
    date_to: ''
  })

  const handleFilterChange = (event: any) => {
    const { value, name } = event.target;
    const updatedFilters = { ...filters, [name]: value };
    setFilters(updatedFilters);
    getAllDashboardContent(updatedFilters); // Call the API with updated filters
  };

  const data1 = [12, 19, 3, 5, 2, 3, 8, 10, 6, 7]; // Data for the first bar
  const data2 = [15, 10, 6, 4, 3, 2, 5, 9, 4, 6]; // Data for the second bar
  const data3 = [8, 14, 7, 3, 5, 6, 9, 12, 5, 4]; // Data for the third bar
  const data4 = [10, 12, 3, 12, 15, 16, 19, 12, 15, 14]; // Data for the third bar
  const data5 = [10, 12, 3, 12, 15, 16, 19, 12, 15, 14]; // Data for the third bar
  const data6 = [10, 12, 3, 12, 15, 16, 19, 12, 15, 14]; // Data for the third bar
  const data7 = [10, 12, 3, 12, 15, 16, 19, 12, 15, 14]; // Data for the third bar
  const labels = data?.lbls || ["Fall 2020", "Spring 2022"];

  const lineLabels = data?.test_dates_lbls || ["2024-10-08", "2024-09-30"];

  // const lineChartData = {
  //   labels: lineLabels, // Use newLabels for the line chart
  //   datasets: [
  //     {
  //       label: "Test Conducted",
  //       data: data2,
  //       backgroundColor: "#128B9B",
  //       borderColor: "#128B9B",
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  const lineChartData = {
    labels: lineLabels,
    datasets: (data?.linechart_tests_dataset || []).map((dataset: any) => ({
      label: dataset?.label || "Default Label", // Provide a default label in case dataset is undefined
      data: dataset?.data || [],               // Fallback to empty data array
      borderColor: dataset?.borderColor || "#000000", // Default border color
      fill: dataset?.fill ?? false,            // Default to false if fill is undefined
      tension: dataset?.tension ?? 0.1,        // Default tension value
    })),
  };

  const chartData = {
    labels, // Extract session names for labels
    datasets: [
      {
        label: "Total Applicants",
        data: data?.chart_session_data?.map((session: any) => session?.total_applicants) || [0], // Extract total applicants
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Total Not Verified",
        data: data?.chart_session_data?.map((session: any) => session?.total_not_verified) || [0], // Extract total not verified
        backgroundColor: "#128B9B",
        borderColor: "#128B9B",
        borderWidth: 1,
      },
      {
        label: "Total Verified",
        data: data?.chart_session_data?.map((session: any) => session?.total_verified) || [0], // Extract total verified
        backgroundColor: "#E9EBEC",
        borderColor: "#E9EBEC",
        borderWidth: 1,
      },
    ],
  };


  // Helper functions to assign colors dynamically based on the index
  const getBackgroundColor = (index: number) => {
    const colors = ["#128B9B", "#78DA5F", "#155096", "#CAF2E1"];
    return colors[index % colors.length];
  };

  const getBorderColor = (index: number) => {
    const colors = ["#128B9B", "#78DA5F", "#155096", "#CAF2E1"];
    return colors[index % colors.length];
  };
  const chartDataTwo = {
    labels, // Set your labels as needed
    datasets: data?.tests_list?.map((test: any, index: number) => ({
      label: test?.title || `Test ${index + 1}`, // Use the test title for label, or fallback to a default
      data: data?.chart_session_data?.map((session: any) => session?.total_applicants) || [0], // Example data mapping
      backgroundColor: getBackgroundColor(index), // Dynamically set background color
      borderColor: getBorderColor(index), // Dynamically set border color
      borderWidth: 1,
    })) || [],
  };


  const pieChartData = {
    labels: [
      "IUB-HAt (for Mphil/MS)",
      "IUB-HAt (for PhD)",
      "IUB-Admission Test (Under Graduate)",
      "IUB-Admission Test (for Engineering)",
      "IUB-HAt (for Law)",
      "IUB-Admission Test (for Business)",
      "IUB-Admission Test (for Medicine)",
    ],
    datasets: data?.datasets3?.map((dataset: any) => ({
      label: dataset?.label || "Test Distribution", // Use dynamic label or fallback
      // data: [dataset?.data] || [0], // Use dynamic data or fallback
      backgroundColor: dataset?.backgroundColor || [
        "#128B9B",
        "#78DA5F",
        "#155096",
        "#CAF2E1",
        "#FF5733",
        "#FFC300",
        "#C70039",
      ], // Use dynamic colors or fallback
      borderColor: dataset?.backgroundColor || [
        "#128B9B",
        "#78DA5F",
        "#155096",
        "#CAF2E1",
        "#FF5733",
        "#FFC300",
        "#C70039",
      ], // Use dynamic border colors (same as background) or fallback
      borderWidth: 1,
    })) || [],
  };


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
  };

  useEffect(() => {
    getDashboard(setData);
    getAllDashboardContent(filters);
    getAdmissionEntryTests(setTests);
    getAdmissionSessions(setSessions);
    getBatch(setBatch);
  }, []);

  const getAllDashboardContent = (filters: any) => {
    const queryParams = {
      ...filters
    };
    getDashboardContent(setDashboardContent, queryParams);
  };

  return (
    <FinanceDashboardMain>
      <FDTopSection>
        <div className="heading">
          <span className="page-heading">Dashboard</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </FDTopSection>

      <FDContentSection>
        <HeadlineCards>
          {data?.total_data?.map((card: any, index: number) => {
            return (
              <HeadlineCard key={index}>
                <div className="header">
                  <div className="file-icon">
                    <GreenFileBgSvg />
                  </div>
                  <div className="title">TOTAL Amount ITS</div>
                </div>

                <div className="lower-part">
                  <div className="stats">
                    <span className="total">{card?.total_verified_amounts}</span>
                    <span className="percentage">52%</span>
                  </div>
                  <div className="graph">
                    <DummyHeadlineGraphSvg />
                  </div>
                </div>
              </HeadlineCard>
            );
          })}
        </HeadlineCards>
        <FilterSection className="content-radius-shadow">
          <div className="filter-fields">
            <div className="input-field">
              <label>Session</label>
              <div className="field-wrap">
                <div className="field">
                  <select name="session_id" value={filters.session_id} onChange={handleFilterChange}>
                    <option value="">Select Session</option>
                    {sessions.map((session, index) => {
                      return <option value={session.id} key={index}>{session.title}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="input-field">
              <label>Test</label>
              <div className="field-wrap">
                <div className="field">
                  <select name="test_id" value={filters.test_id} onChange={handleFilterChange}>
                    <option value="">Select Test</option>
                    {tests.map((test: any, index: number) => {
                      return <option value={test.id} key={index}>{test.title}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="input-field">
              <label>Test Batch</label>
              <div className="field-wrap">
                <div className="field">
                  <select name="batch" value={filters.batch} onChange={handleFilterChange}>
                    <option value="">Select Batch</option>
                    {batch.map((batch, index) => {
                      return <option value={batch} key={index}>
                        {batch}
                      </option>
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="input-field">
              <label>Date From</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="date" name="date_from" id="" onChange={handleFilterChange} />
                </div>
              </div>
            </div>
            <div className="input-field">
              <label>Date To</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="date" name="date_to" id="" onChange={handleFilterChange} />
                </div>
              </div>
            </div>
          </div>
          {dashboardContent.map((item: any, index: number) => {
            return (
              <div className="stats-container">
                <div className="stats-heading-main">
                  <p className="stats-heading">IUB-HAT {item?.test_name}</p>
                  <div className="stat-heading-right">
                    <div className="stats-amount">Total Verified Amount(PKR)</div>
                    <div className="stats-value">{item?.total_verified_amounts}</div>
                  </div>
                </div>
                <div className="stats-main">
                  <div className="sats-item">
                    <div className="stats-title">Total Applications</div>
                    <div className="stats-value">{item?.total_applicants}</div>
                  </div>
                  <div className="sats-item">
                    <div className="stats-title">Total Verify</div>
                    <div className="stats-value">{item?.total_verified}</div>
                  </div>
                  <div className="sats-item">
                    <div className="stats-title">Not Verified</div>
                    <div className="stats-value">{item?.total_not_verified}</div>
                  </div>
                  <div className="sats-item">
                    <div className="stats-title">Result Uploaded </div>
                    <div className="stats-value">{item?.result_uploaded}</div>
                  </div>
                  <div className="sats-item">
                    <div className="stats-title">Total Passed</div>
                    <div className="stats-value">{item?.total_passed}</div>
                  </div>
                  <div className="sats-item">
                    <div className="stats-title">Total Failed</div>
                    <div className="stats-value">{item?.total_failed}</div>
                  </div>
                </div>
              </div>
            );
          })}
          {/* <div className="stats-container">
            <div className="stats-heading-main">
              <p className="stats-heading">IUB-HAT (for phd)</p>
              <div className="stat-heading-right">
                <div className="stats-amount">Total Verified Amount(PKR)</div>
                <div className="stats-value">256,888</div>
              </div>
            </div>
            <div className="stats-main">
              <div className="sats-item">
                <div className="stats-title">Total Applications</div>
                <div className="stats-value">334</div>
              </div>
              <div className="sats-item">
                <div className="stats-title">Total Verify</div>
                <div className="stats-value">334</div>
              </div>
              <div className="sats-item">
                <div className="stats-title">Not Verified</div>
                <div className="stats-value">334</div>
              </div>
              <div className="sats-item">
                <div className="stats-title">Result Uploaded </div>
                <div className="stats-value">334</div>
              </div>
              <div className="sats-item">
                <div className="stats-title">Total Passed</div>
                <div className="stats-value">334</div>
              </div>
              <div className="sats-item">
                <div className="stats-title">Total Failed</div>
                <div className="stats-value">334</div>
              </div>
            </div>
          </div>
          <div className="stats-container">
            <div className="stats-heading-main">
              <p className="stats-heading">
                IUB-Admission Test (under Graduation)
              </p>
              <div className="stat-heading-right">
                <div className="stats-amount">Total Verified Amount(PKR)</div>
                <div className="stats-value">256,888</div>
              </div>
            </div>
            <div className="stats-main">
              <div className="sats-item">
                <div className="stats-title">Total Applications</div>
                <div className="stats-value">334</div>
              </div>
              <div className="sats-item">
                <div className="stats-title">Total Verify</div>
                <div className="stats-value">334</div>
              </div>
              <div className="sats-item">
                <div className="stats-title">Not Verified</div>
                <div className="stats-value">334</div>
              </div>
              <div className="sats-item">
                <div className="stats-title">Result Uploaded </div>
                <div className="stats-value">334</div>
              </div>
              <div className="sats-item">
                <div className="stats-title">Total Passed</div>
                <div className="stats-value">334</div>
              </div>
              <div className="sats-item">
                <div className="stats-title">Total Failed</div>
                <div className="stats-value">334</div>
              </div>
            </div>
          </div> */}

        </FilterSection>
        {/* pie-graph*/}
        <MainStatCards>
          <MainStatCard>
            <div className="header-section">
              <div className="title">
                <span>Total Applicants Test Wise</span>
              </div>
              <div className="description">
                Total Applicants/ Total Verified/ Total Not Verified
              </div>
            </div>

            <div className="pie-chart-section">
              <Pie data={pieChartData} options={options} />
            </div>
          </MainStatCard>
        </MainStatCards>
        {/* line-graph*/}
        <MainStatCards>
          <MainStatCard>
            <div className="header-section">
              <div className="title">
                <span>Tests Conducted </span>
              </div>
              <div className="description">
                Total Applicants/ Total Verified/ Total Not Verified
              </div>
            </div>

            <div className="">
              <Line data={lineChartData} options={options} />
            </div>
          </MainStatCard>
        </MainStatCards>
        {/* bar graph-1*/}
        <MainStatCards>
          <MainStatCard>
            <div className="header-section">
              <div className="title">
                <span>Tests Report Session Wise</span>
              </div>
              <div className="description">
                Total Applicants/ Total Verified/ Total Not Verified
              </div>
            </div>

            <div className="chart-section">
              <Bar data={chartData} options={options} />
            </div>
          </MainStatCard>
        </MainStatCards>
        {/* bar graph-2*/}
        <MainStatCards>
          <MainStatCard>
            <div className="header-section">
              <div className="title">
                <span>Tests Report Session Wise</span>
              </div>
              <div className="description">
                Total Applicants/ Total Verified/ Total Not Verified
              </div>
            </div>

            <div className="chart-section">
              <Bar data={chartDataTwo} options={options} />
            </div>
          </MainStatCard>
        </MainStatCards>
      </FDContentSection>
    </FinanceDashboardMain>
  );
};

export default TestingDashboard;
