export class AddLinkProgramToEntryTestDTO {
  test_id: string = "";
  admission_session_id: string = "";
  allow_result_awaiting!: number;
  min_required_percentage!: number;
  status!: number;
  from_date: string = "";
  to_date: string = "";
  // admission_session_id: string = "";
}
