export class RegStudentDTO {
  academic_session: string = "";
  program_id: string = "";
  fee_status: string = "";
  is_complete: string = "";
  type: string = "";
  hostel_check: string = "";
  is_eligible: string = "";
  cnic: any = "";
  slug: string = "";
}
