export class OrganizationDTO {
    id!: number;
    name: string = '';
    logo: any = null;
    email: string = '';
    password: string = '';
    phone: string = '';
    state: string = '';
    city: string = '';
    add_structure_type: number | string = 0;
    zip_code: number = 0;
    address1: string = '';
    website: string = '';
    username: string = '';
    district: string = '';
    vision: string = '';
    mission: string = '';
    // domain_name: string = ''
    domain: string = ''
;}