import { FC, Fragment, useEffect, useState } from "react";
import { ExcelSvg, PdfSvg, SearchFieldSvg } from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  TransportSceduleListMain,
  TransportSceduleListSection,
  TransportSceduleListTop,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import useTestingServices from "containers/private/testing-services/useHooks";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { useSelector } from "react-redux";
import { TestingCenterRoomDTO } from "utils/helpers/models/testing-service/testing-center-room.dto";
import useUtils from "hooks/useUtils";
import DataNotFound from "components/particles/table/data-not-found";
import { TestingCenterDTO } from "utils/helpers/models/testing-service/testing-center.dto";

const TransportSceduleList: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Student Dashboard / ", path: siteRoutes.academicSessionListing },
    {
      title: "Routes",
      path: siteRoutes.studentTransportScheduleList,
    },
  ];
  const columns: string[] = ["Route Title", "Campus", "Bus Name", "Status"];
  const navigate = useNavigate();
  const {
    getTestingCenterRooms,
    deleteTestingCenterRoom,
    getTestingCenterById,
    updateTestingCenter,
  } = useTestingServices();
  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const [center, setCenter] = useState<TestingCenterDTO>(
    new TestingCenterDTO()
  );
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [search, setSearch] = useState<string>("");
  const { handleSearchChange, handleTableSearch, getQueryParams } = useUtils();
  const params = getQueryParams();

  useEffect(() => {
    // getAllTestingCenterRooms(pagination.page, search);
    // getTestingCenterById(params?.id, center, setCenter);
  }, []);

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
      };
      // deleteTestingCenterRoom(id, setData, queryParams, setPagination);
    }
  };

  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
    getAllTestingCenterRooms(page + 1, search);
  };
  const getAllTestingCenterRooms = (page: number, search: string) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
    };
    // getTestingCenterRooms(setData, queryParams, setPagination);
  };

  const goToCreate = () => {
    navigate(siteRoutes.createStudentVehicleEntry);
  };
  const [data, setData] = useState([
    {
      courseCode: "ARIN-4567",
      course: "Advanced Theory of Computation",
      teacher: "Dr. Muhammad Faheem Mushtaq",
      isDropdownOpen: false,
    },
    {
      courseCode: "CS101",
      course: "Introduction to Computer Science",
      teacher: "Dr. John Doe",
      isDropdownOpen: false,
    },
    // Add more objects here
  ]);

  const toggleRowExpand = (index: number) => {
    const updatedData = data.map((item, idx) => {
      if (idx === index) {
        return { ...item, isDropdownOpen: !item.isDropdownOpen };
      }
      return { ...item, isDropdownOpen: false }; // Close others
    });
    setData(updatedData);
  };

  return (
    <TransportSceduleListMain>
      <TransportSceduleListTop>
        <div className="left">
          <span className="page-heading">Routes</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right"></div>
      </TransportSceduleListTop>

      <TransportSceduleListSection
        isTableOverflowing={false}
        className="content-radius-shadow"
      >
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(e, setSearch, getAllTestingCenterRooms)
              }
              onKeyUp={(e) => handleTableSearch(e, getAllTestingCenterRooms)}
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((bank, index) => {
                return (
                  <tr>
                    <td>AC to BJC</td>
                    <td>Abbasia</td>
                    <td>
                      <div className="mw-150">3423</div>
                    </td>

                    <td>
                      <span className="status-tile green">Active</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          {/* <DataNotFound show={!isLoading && !data.length} /> */}
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </TransportSceduleListSection>
    </TransportSceduleListMain>
  );
};

export default TransportSceduleList;
