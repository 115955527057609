import CreateAcademicSessionOrganization from "containers/private/organization/academic-session/create";
import AcademicSessionOrganizationListing from "containers/private/organization/academic-session/listing";
import CreateAsset from "containers/private/organization/assets-management/create";
import AssetsListing from "containers/private/organization/assets-management/listing";
import BoardManagementCreate from "containers/private/organization/board-management/create";
import BoardManagementListing from "containers/private/organization/board-management/listing";
import CreateBuilding from "containers/private/organization/building-management/create";
import BuildingsListing from "containers/private/organization/building-management/listing";
import CertificateLinkCreate from "containers/private/organization/certificate-link-to-result-type/create";
import CertificateLinksListing from "containers/private/organization/certificate-link-to-result-type/listing";
import CertificateManagementCreate from "containers/private/organization/certificate-management/create";
import CertificateManagementListing from "containers/private/organization/certificate-management/listing";
import CreateMajorCategories from "containers/private/organization/major-categories/create";
import MajorCategoriesListing from "containers/private/organization/major-categories/listing";
import CreateMinorCategories from "containers/private/organization/minor-categories/create";
import MinorCategoriesListing from "containers/private/organization/minor-categories/listing";
import ModuleManagement from "containers/private/organization/module-management";
import CreateOrganization from "containers/private/organization/organization/create";
import OrganizationListing from "containers/private/organization/organization/listing";
import ViewOrganization from "containers/private/organization/organization/view";
import CreateOrganizationalStructure from "containers/private/organization/organizational-structure/create";
import OrganizationalStructureListing from "containers/private/organization/organizational-structure/listing";
import PermissionManagementCreate from "containers/private/organization/permission-management/create";
import PermissionManagementListing from "containers/private/organization/permission-management/listing";
import LinkedSpecializationListing from "containers/private/organization/program-specialization/listing";
import ResultTypeCreate from "containers/private/organization/result-types/create";
import ResultTypeListing from "containers/private/organization/result-types/listing";
import RollManagementCreate from "containers/private/organization/role-management/create";
import RoleManagementListing from "containers/private/organization/role-management/listing";
import CreateRooms from "containers/private/organization/rooms-management/create";
import RoomsListing from "containers/private/organization/rooms-management/listing";
import CreateStructureType from "containers/private/organization/structure-type/create";
import StructureTypeListing from "containers/private/organization/structure-type/listing";
import SubjectManagementCreate from "containers/private/organization/subject-management/create";
import SubjectManagementListing from "containers/private/organization/subject-management/listing";
import SuperAdminManagementCreate from "containers/private/organization/super-admin-management/create";
import SuperAdminManagementListing from "containers/private/organization/super-admin-management/listing";
import SuperRollManagementCreate from "containers/private/organization/super-role-management/create";
import SuperRoleManagementListing from "containers/private/organization/super-role-management/listing";
import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import CreateLinkedProgramEntryTest from "containers/private/organization/linked-program-entry-test/create";
import LinkedProgramsEntryTestListing from "containers/private/organization/linked-program-entry-test/listing";
import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import CertificateLevelListing from "containers/private/organization/certificate-levels/listing";
import CreateCertificateLevels from "containers/private/organization/certificate-levels/create";
import AdminOrganizationListing from "containers/private/organization/admin-management/lising";
import CreateOrganizationAdmin from "containers/private/organization/admin-management/create";
import UpdateAdminOrganization from "containers/private/organization/update-admin-organization";

const OrganizationRoutes: FC = () => {
  const { getPathToSetRoute } = useUtils();
  return (
    <Routes>
      <Route
        path={getPathToSetRoute(siteRoutes.organizationListing, true)}
        Component={OrganizationListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createOrganization, true)}
        Component={CreateOrganization}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.viewOrganization, true)}
        Component={ViewOrganization}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createStructureType, true)}
        Component={CreateStructureType}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.structureTypeListing, true)}
        Component={StructureTypeListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createOrgStructure, true)}
        Component={CreateOrganizationalStructure}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.orgStructureListing, true)}
        Component={OrganizationalStructureListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.assetsListing, true)}
        Component={AssetsListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createAsset, true)}
        Component={CreateAsset}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.buildingListing, true)}
        Component={BuildingsListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createBuilding, true)}
        Component={CreateBuilding}
      />

      <Route
        path={getPathToSetRoute(siteRoutes.resultTypeListing, true)}
        Component={ResultTypeListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.resultTypeCreate, true)}
        Component={ResultTypeCreate}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.certificateLinkListing, true)}
        Component={CertificateLinksListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.certificateLinkCreate, true)}
        Component={CertificateLinkCreate}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.boardManagementListing, true)}
        Component={BoardManagementListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.boardManagementCreate, true)}
        Component={BoardManagementCreate}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.subjectManagementListing, true)}
        Component={SubjectManagementListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.subjectManagementCreate, true)}
        Component={SubjectManagementCreate}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.certificateManagementListing, true)}
        Component={CertificateManagementListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.certificateManagementCreate, true)}
        Component={CertificateManagementCreate}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.superAdminManagementListing, true)}
        Component={SuperAdminManagementListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.superAdminManagementCreate, true)}
        Component={SuperAdminManagementCreate}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.superRoleManagementListing, true)}
        Component={SuperRoleManagementListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.superRoleManagementCreate, true)}
        Component={SuperRollManagementCreate}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.permissionManagementListing, true)}
        Component={PermissionManagementListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.permissionManagementCreate, true)}
        Component={PermissionManagementCreate}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.moduleManagement)}
        Component={ModuleManagement}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.linkedSpecializationListing, true)}
        Component={LinkedSpecializationListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.linkedProgramEntryTestListing, true)}
        Component={LinkedProgramsEntryTestListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createLinkedProgramEntryTest, true)}
        Component={CreateLinkedProgramEntryTest}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.academicSessionOrganizationListing,
          true
        )}
        Component={AcademicSessionOrganizationListing}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.createAcademicSessionOrganization,
          true
        )}
        Component={CreateAcademicSessionOrganization}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.majorCategoriesListing, true)}
        Component={MajorCategoriesListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.CreateMajorCategories, true)}
        Component={CreateMajorCategories}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.minorCategoriesListing, true)}
        Component={MinorCategoriesListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createMinorCategories, true)}
        Component={CreateMinorCategories}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.roomsListing, true)}
        Component={RoomsListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createRooms, true)}
        Component={CreateRooms}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.certificateLevelList, true)}
        Component={CertificateLevelListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createCertificateLevels, true)}
        Component={CreateCertificateLevels}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.organizationAdminListing, true)}
        Component={AdminOrganizationListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createOrganizationAdmin, true)}
        Component={CreateOrganizationAdmin}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.updateOrganizationAdmin)}
        Component={UpdateAdminOrganization}
      />
    </Routes>
  );
};

export default OrganizationRoutes;
