import styled from "styled-components";

export const ProgramsListingMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;

export const ProgrameListingTopButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  background: var(--lightgray-medium-extralight);

  @media screen and (max-width: 360px) {
    flex-direction: column;
  }
`;

export const ProgramsListingSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em;
  }

  .list-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      gap: 1em;
    }
  }

  /* table td {
    .org-logo {
      width: 38px;
      height: 35px;
      background: var(--lightgray-extralight);
    }

    .mw-150 {
      min-width: 150px;
    }

    .expanded-content {
      display: flex;
      flex-direction: column;
      gap: 0.7em;

      .particular-info {
        display: flex;
        gap: 1em;
        .title {
          min-width: 170px;
          color: var(--black-text);
          font-size: 15px;
          font-weight: 400;
        }

        .info {
          font-size: 15px;
          font-weight: 400;
          color: var(--lightgray-medium);

          .table-action-icons {
            @media screen and (max-width: 900px) {
              flex-wrap: wrap;
            }
          }
          .action-button {
            button {
              padding: 0.4em 0.5em;
              color: var(--white-constant);
              background: var(--lightgreen-medium);
              border-radius: 5px;
              font-size: 14px;
              font-weight: 600;

              &.criteria-btn {
                color: var(--white-color);
                background: var(--black-text);
              }
              &.entry-test-btn {
                color: var(--white-constant);
                background: var(--primary);
              }
              &.seats-btn {
                background: var(--light-orange);
              }
              &.special-btn {
                background: var(--medium-orange);
              }
            }
          }
        }
      }
    }
  } */

  table {
    tr {
      td {
        @media screen and (min-width: 2200px) {
          border-bottom: 1px solid var(--lightgray-extralight);
        }
      }
    }
    td {
      &.extra-col {
        display: none;
        ${() => {
          let str = "";
          for (let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]) {
            str += `&.extra-col-${item}{
          @media screen and (min-width: ${1500 + item * 80}px){
            display: table-cell;
          }
        }`;
          }
          return str;
        }}
        &-13 {
          @media screen and (min-width: 2600px) {
            display: table-cell;
          }
        }
      }

      .rounded-expand-button {
        @media screen and (min-width: 2600px) {
          display: none;
        }
      }
    }

    tr.expanded-row {
      @media screen and (min-width: 2200px) {
        display: none;
      }
      .expanded-content {
        .particular-info {
          gap: 2em;
          ${() => {
            let str = "";
            for (let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]) {
              str += `&-${item}{
          @media screen and (min-width: ${1500 + item * 80}px){
            display: none;
          }
        }`;
            }
            return str;
          }}
          .title {
            width: 20%;
          }

          .table-action-icons {
            @media screen and (max-width: 900px) {
              flex-wrap: wrap;
            }
            .action-button {
              button {
                padding: 0.4em 0.5em;
                color: var(--white-constant);
                background: var(--lightgreen-medium);
                border-radius: 5px;
                font-size: 14px;
                font-weight: 600;

                &.criteria-btn {
                  color: var(--white-color);
                  background: var(--black-text);
                }
                &.entry-test-btn {
                  color: var(--white-constant);
                  background: var(--primary);
                }
                &.seats-btn {
                  background: var(--light-orange);
                }
                &.special-btn {
                  background: var(--medium-orange);
                }
              }
            }
          }
        }
      }
    }
    thead th {
      &.extra-head {
        display: none;
        ${() => {
          let str = "";
          for (let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) {
            str += `&-${item}{
          @media screen and (min-width: ${1500 + item * 80}px){
            display: table-cell;
          }
        }`;
          }
          return str;
        }}

        &-12 {
          @media screen and (min-width: 2600px) {
            display: table-cell;
          }
        }
      }
    }
  }
`;

export const ProgramsListingTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  @media screen and (max-width: 490px) {
    flex-direction: column;
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }

  .right {
    .create-btn button {
      &.link-btn {
        display: flex;
        gap: 0.5em;
        align-items: center;
      }
    }
    @media screen and (max-width: 490px) {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
`;
