import styled from "styled-components";

export const StudentDashboardMain = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding: 1em;

    @media screen and (max-width: 630px){
        padding: .5em;
    }
`;

export const StudentDashboardTopSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
`;

export const QuickLinksSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding: 1em;

    .section-header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 1em;
        border-bottom: 1px solid var(--lightgray-extralight);
        
        .heading{
            font-size: 24px;
            font-weight: 500;
            color: var(--black-text);

            @media screen and (max-width: 500px){
                font-size: 20px;
            }
        }
    }
        .quick-links-wrapper{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        gap: 1.5em;
        color: var(--lightgray-medium);
        font-size: 15px;
        font-weight: 400;
    }
`;

export const ParticularQuickLink = styled.div<{ background: string }>`
            padding: 1em;
            display: flex;
            gap: 1em;
            align-items: center;
            border-radius: 10px;
            background: ${({ background }) => background};
            cursor: pointer;

            .icon-section{
                width: 50px;
                height: 50px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: var(--white-constant);
            }

            .title{
                font-size: 16px;
                font-weight: 600;
                color: var(--dark-black-constant);
            }
`;

export const NoticeBoardSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding: 1em 1.5em;

    .section-header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 1em;
        border-bottom: 1px solid var(--lightgray-extralight);
        
        .heading{
            font-size: 24px;
            font-weight: 500;
            color: var(--black-text);

            @media screen and (max-width: 500px){
                font-size: 20px;
            }
        }
    }
    .notice-wrapper{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 2em;

            .particular-notice{
                width: 100%;
                display: flex;
                gap: 1.5em;
                align-items: center;

                .notice-thumbnail{
                    width: 77px;
                    height: 90px;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .notice-info{
                    display: flex;
                    flex-direction: column;
                    /* gap: .5em; */

                    .notice-title{
                        font-size: 15px;
                        font-weight: 500;
                        color: var(--black-text);

                        @media screen and (max-width: 500px){
                            font-size: 14px;
                        }
                    }

                    .notice-description{
                        color: var(--lightgray-light);
                        font-size: 15px;
                        font-weight: 400;

                        @media screen and (max-width: 500px){
                            font-size: 13px;
                        }
                    }
                }
            }
        }
`;