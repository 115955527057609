import AdminDashboard from "containers/private/dashboard/admin-dashboard";
import SuperAdminDashboard from "containers/private/dashboard/super-admin-dashboard";
import useUtils from "hooks/useUtils";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const DashboardRoutes: FC = () => {
    const { getPathToSetRoute } = useUtils();
    return (
        <Routes>
            <Route path={getPathToSetRoute(siteRoutes.superAdminDashboard)} Component={SuperAdminDashboard} />
            <Route path={getPathToSetRoute(siteRoutes.adminDashboard)} Component={AdminDashboard} />


        </Routes>
    )
}

export default DashboardRoutes;