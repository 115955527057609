import { FC, useEffect, useState } from "react";
import { ExtendDateMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { extendDateDto } from "utils/helpers/models/admissions/extend-date.dto";
import { useForm } from "react-hook-form";
import useAdmissions from "containers/private/admissions/useHooks";
import FormErrorMessage from "components/particles/forms/form-error-message";

interface ExtendDateProps {
  setOpen: Function;
  student_id: any;
}

const ExtendDate: FC<ExtendDateProps> = ({ setOpen, student_id }) => {
  const { extendDate } = useAdmissions();
  const [formData, setFormData] = useState<extendDateDto>({
    ...new extendDateDto(),
    student_id,
  });
  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<extendDateDto>();

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };
  const onSubmit = () => {
    extendDate(student_id, formData);
  };

  return (
    <ExtendDateMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Extend Date</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="common-fields">
              <div className="input-field">
                <label>Offer Date</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="date"
                      {...register("offer_date", { required: true })}
                      value={formData.offer_date}
                      onChange={handleChange}
                    />
                  </div>
                  <FormErrorMessage error={errors.offer_date} />
                </div>
              </div>
              <div className="input-field">
                <label>End Date</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="date"
                      {...register("exp_date", { required: true })}
                      value={formData.exp_date}
                      onChange={handleChange}
                    />
                  </div>
                  <FormErrorMessage error={errors.exp_date} />
                </div>
              </div>
            </div>
            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="button"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
                <button className="lg-rounded-btn" type="submit">
                  Save & Close
                </button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </ExtendDateMain>
  );
};

export default ExtendDate;
