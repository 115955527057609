import { ChangeEvent, FC, useEffect, useState } from "react";
import {
  CreateSupportTicketSection,
  CreateSupportTicketMain,
  CreateSupportTicketTop,
} from "./style";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import Breadcrumb from "components/particles/breadcrumb";
import { AddMeritKeyDTO } from "utils/helpers/models/admissions/add-merit-key.dto";
import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

// interface AddHostelProps {
//     setOpen: Function;
// }

const CreateSupportTicket: FC = () => {
  const breadcrumbLinks = [
    { title: "Student Dashboard / ", path: siteRoutes.studentDashboard },
    {
      title: "Create Tickets",
      path: siteRoutes.createStudentPortalSupportTicket,
    },
  ];
  const [formData, setFormData] = useState<AddMeritKeyDTO>(
    new AddMeritKeyDTO()
  );
  // const { createMeritKey, updateMeritKey, getMeritKeyById } = useAdmissions();
  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<AddMeritKeyDTO>({ defaultValues: formData });
  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const onSubmit = (formData: AddMeritKeyDTO, addMore: boolean = false) => {
    if (params?.id) {
      // updateMeritKey(params?.id, formData);
    } else {
      // createMeritKey(formData, addMore, resetForm);
    }
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger([name]);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    for (const key in formData) {
      setValue(key as keyof AddMeritKeyDTO, "");
    }
    setFormData({ ...new AddMeritKeyDTO() });
  };

  useEffect(() => {
    if (params?.id) {
      // getMeritKeyById(params?.id, formData, setValue, setFormData);
    }
  }, []);

  return (
    <CreateSupportTicketMain>
      <CreateSupportTicketTop>
        <div className="left">
          <span className="page-heading">Create Tickets</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right"></div>
      </CreateSupportTicketTop>

      <CreateSupportTicketSection className="p-custom-scrollbar-8">
        <form>
          <div className="common-fields">
            <div className="input-field">
              <label>Support Type</label>
              <div className="field-wrap">
                <div className="field">
                  <select>
                    <option value="">Select Support Type</option>
                  </select>
                </div>
                <FormErrorMessage error={errors.key_title} />
              </div>
            </div>
            <div className="input-field">
              <label>Order Sheet*</label>
              <div className="upload-container">
                <div className="upload-section">
                  <label htmlFor="file-upload" className="upload-label">
                    Choose a file
                  </label>
                  <input type="file" id="file-upload" className="file-input" />
                </div>

                <div className="file-details-section" id="file-details">
                  <p className="file-placeholder">No file chosen</p>
                </div>
              </div>
            </div>
          </div>
          <div className="input-field">
            <label htmlFor="">Description</label>
            <div className="field-wrap">
              <div className="field">
                <textarea placeholder="Enter Description"></textarea>
              </div>
            </div>
          </div>

          <div className="action-buttons">
            <div className="buttons">
              <button
                className="lg-rounded-btn gray"
                type="button"
                onClick={resetForm}
              >
                Reset
              </button>

              {!params?.id && (
                <button
                  className="lg-rounded-btn black"
                  type="submit"
                  onClick={handleSubmit((data: AddMeritKeyDTO) =>
                    onSubmit(data, true)
                  )}
                >
                  Save & Add more
                </button>
              )}

              <button
                className="lg-rounded-btn"
                type="submit"
                onClick={handleSubmit((data: AddMeritKeyDTO) => onSubmit(data))}
              >
                {params?.id ? "Update" : "Save"} & Exit
              </button>
            </div>
          </div>
        </form>
      </CreateSupportTicketSection>
    </CreateSupportTicketMain>
  );
};

export default CreateSupportTicket;
