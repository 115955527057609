import { TESTING_SERVICES_APIs } from "libs/apis/testing-services.api";
import { useNavigate } from "react-router-dom";
import { errorToaster, successToaster } from "utils/helpers/common/alert-service";

const   useTestingServices = () => {
    const navigate = useNavigate();
    const createTestingCenter = async (body: any, addMore: boolean, reset: Function) => {
        const response = await TESTING_SERVICES_APIs.createTestingCenter(body);
        const { status, message } = response || {};
        if (status) {
            successToaster(message);
            if (addMore) {
                reset();
            } else {
                navigate(-1);
            }
        }
    }
    const updateTestingCenter = async (id: number, body: any, shouldNavigate: boolean = true) => {
        const response = await TESTING_SERVICES_APIs.updateTestingCenter(id, body);
        const { status, message } = response || {};
        if (status) {
            successToaster(message);
            if (shouldNavigate) navigate(-1);
        }
    }

    const getTestingCenters = async (setData: Function, queryParams: any = { per_page: 'All' }, setPagination?: Function) => {
        const response = await TESTING_SERVICES_APIs.getTestingCenters(queryParams);
        const { status } = response || {};
        if (status) {
            const data = response?.response?.data;
            if (data) {
                const {
                    total: totalRecords,
                    current_page: page,
                    per_page,
                } = response?.response;
                if (setPagination) {
                    setPagination({ per_page, totalRecords, page });
                }
                setData([...data]);
            } else {
                setData(response?.response);
            }
        }
    }

    const getTestingCenterById = async (id: number, formData: any, setFormData: Function, setValue?: Function) => {
        const response = await TESTING_SERVICES_APIs.getTestingCenterById(id);
        const { response: data } = response || {};
        console.log(response,'rrrr')
        for (let key in formData) {
            formData[key] = data[key];
            if (setValue) setValue(key, formData[key]);
        }

        setFormData({ ...formData });
    }

    const deleteTestingCenter = async (id: number, setData: Function, queryParams: any = { per_page: 10 }, setPagination: Function) => {
        const response = await TESTING_SERVICES_APIs.deleteTestingCenterById(id);
        const { status, message } = response || {};
        if (status) {
            successToaster(message);
            getTestingCenters(setData, queryParams, setPagination);
        }
    }
    const createTestingCenterRoom = async (body: any,test_center_id: string, addMore: boolean, reset: Function) => {
        const response = await TESTING_SERVICES_APIs.createTestingCenterRoom(body,test_center_id);
        const { status, message } = response || {};
        if (status) {
            successToaster(message);
            if (addMore) {
                reset();
            } else {
                navigate(-1);
            }
        }
    }
    const updateTestingCenterRoom = async (id: number, body: any) => {
        const response = await TESTING_SERVICES_APIs.updateTestingCenterRoom(id, body);
        const { status, message } = response || {};
        if (status) {
            successToaster(message);
            navigate(-1);
        }
    }

    const getTestingCenterRooms = async (setData: Function, queryParams: any = { per_page: 'All' }, setPagination?: Function) => {
        const response = await TESTING_SERVICES_APIs.getTestingCenterRooms(queryParams);
        const { status } = response || {};
        if (status) {
            const data = response?.response?.data;
            if (data) {
                const {
                    total: totalRecords,
                    current_page: page,
                    per_page,
                } = response?.response;
                if (setPagination) {
                    setPagination({ per_page, totalRecords, page });
                }
                setData([...data]);
            } else {
                setData(response?.response);
            }
        }
    }

    const getTestingCenterRoomById = async (id: number, formData: any, setValue: Function, setFormData: Function) => {
        const response = await TESTING_SERVICES_APIs.getTestingCenterRoomById(id);
        const { response: data } = response || {};
        for (let key in formData) {
            formData[key] = data[key];
            setValue(key, formData[key]);
        }

        setFormData({ ...formData });
    }

    const deleteTestingCenterRoom = async (id: number, setData: Function, queryParams: any = { per_page: 10 }, setPagination: Function) => {
        const response = await TESTING_SERVICES_APIs.deleteTestingCenterRoom(id);
        const { status, message } = response || {};
        if (status) {
            successToaster(message);
            getTestingCenterRooms(setData, queryParams, setPagination);
        }
    }
    const createTestSchedule = async (body: any, addMore: boolean, reset: Function) => {
        const response = await TESTING_SERVICES_APIs.createTestSchedule(body);
        const { status, message } = response || {};
        if (status) {
            successToaster(message);
            if (addMore) {
                reset();
            } else {
                navigate(-1);
            }
        }
    }
    const updateTestSchedule = async (id: number, body: any) => {
        const response = await TESTING_SERVICES_APIs.updateTestSchedule(id, body);
        const { status, message } = response || {};
        if (status) {
            successToaster(message);
            navigate(-1);
        }
    }

    const getTestSchedules = async (setData: Function, queryParams: any = { per_page: 'All' }, setPagination?: Function) => {
        const response = await TESTING_SERVICES_APIs.getTestSchedules(queryParams);
        const { status } = response || {};
        if (status) {
            const data = response?.response?.data;
            if (data) {
                const {
                    total: totalRecords,
                    current_page: page,
                    per_page,
                } = response?.response;
                if (setPagination) {
                    setPagination({ per_page, totalRecords, page });
                }
                setData([...data]);
            } else {
                setData(response?.response);
            }
        }
    }

    const getTestScheduleById = async (id: number, formData: any, setValue: Function, setFormData: Function) => {
        const response = await TESTING_SERVICES_APIs.getTestScheduleById(id);
        const { response: data } = response || {};
        for (let key in formData) {
            formData[key] = data[key];
            setValue(key, formData[key]);
        }

        setFormData({ ...formData });
    }

    const deleteTestSchedule = async (id: number, setData: Function, queryParams: any = { per_page: 10 }, setPagination: Function) => {
        const response = await TESTING_SERVICES_APIs.deleteTestSchedule(id);
        const { status, message } = response || {};
        if (status) {
            successToaster(message);
            getTestingCenters(setData, queryParams, setPagination);
        }
    }

    const getSeatingPlans = async (setData: Function, queryParams: any = { per_page: 'All' }, setPagination?: Function) => {
        const response = await TESTING_SERVICES_APIs.getSeatingPlans(queryParams);
        const { status } = response || {};
        if (status) {
            const data = response?.response?.data;
            if (data) {
                const {
                    total: totalRecords,
                    current_page: page,
                    per_page,
                } = response?.response;
                if (setPagination) {
                    setPagination({ per_page, totalRecords, page });
                }
                setData([...data]);
            } else {
                setData(response?.response);
            }
        }
    }

    const getSeatingPlanById = async (id: number, formData: any, setValue: Function, setFormData: Function) => {
        const response = await TESTING_SERVICES_APIs.getSeatingPlanById(id);
        const { response: data } = response || {};
        for (let key in formData) {
            formData[key] = data[key];
            setValue(key, formData[key]);
        }

        setFormData({ ...formData });
    }

    const deleteSeatingPlan = async (id: number, setData: Function, queryParams: any = { per_page: 10 }, setPagination: Function) => {
        const response = await TESTING_SERVICES_APIs.deleteSeatingPlan(id);
        const { status, message } = response || {};
        if (status) {
            successToaster(message);
            getSeatingPlans(setData, queryParams, setPagination);
        }
    }

    const removeApplicantSeatingPlan = async (id: number, setData: Function, queryParams: any = { per_page: 10 }, setPagination: Function) => {
        const response = await TESTING_SERVICES_APIs.removeApplicantSeatingPlan(id);
        const { status, message } = response || {};
        if (status) {
            successToaster(message);
            getSeatingPlanList(setData, queryParams, setPagination);
        }
    }

    const createSeatingPlan = async (body: any,ScheduleId: number, addMore: boolean, reset: Function) => {
        const response = await TESTING_SERVICES_APIs.createSeatingPlan(body,ScheduleId);
        const { status, message } = response || {};
        if (status) {
            successToaster(message);
            // if (addMore) {
            //     reset();
            // } else {
            //     navigate(-1);
            // }
        }
    }

    const updateSeatingPlan = async (body: any,shouldNavigate: boolean = true) => {
        const response = await TESTING_SERVICES_APIs.updateSeatingPlan(body.id, body);
        const { status, message } = response || {};
        if (status) {
            successToaster(message);
            if (shouldNavigate) navigate(-1);
        }
    }

    const getCenterWiseSummary = async (setData: Function, queryParams: any) => {
        const response = await TESTING_SERVICES_APIs.getCenterWiseSummary(queryParams); // Make sure this API function is being called
        const { status } = response || {};
        if (status) {
            const data = response?.response?.data;
            if (data) {
                setData([...data]);
            } else {
                setData(response?.response);
            }
        }
    };

    const getApplicants = async (setData: Function, queryParams: any = { per_page: 'All' }, setPagination?: Function) => {
        const response = await TESTING_SERVICES_APIs.getApplicants(queryParams);
        const { status } = response || {};
        if (status) {
            const data = response?.response?.data;
            if (data) {
                const {
                    total: totalRecords,
                    current_page: page,
                    per_page,
                } = response?.response;
                if (setPagination) {
                    setPagination({ per_page, totalRecords, page });
                }
                setData([...data]);
            } else {
                setData(response?.response);
            }
        }
    }

    const getBatch = async (setData: Function, queryParams: any = { per_page: 'All' }, setPagination?: Function) => {
        const response = await TESTING_SERVICES_APIs.getBatch(queryParams);
        const { status } = response || {};
        if (status) {
            const data = response?.response?.data;
            if (data) {
                const {
                    total: totalRecords,
                    current_page: page,
                    per_page,
                } = response?.response;
                if (setPagination) {
                    setPagination({ per_page, totalRecords, page });
                }
                setData([...data]);
            } else {
                setData(response?.response);
            }
        }
    }

    const getPublishResult = async (setData: Function, queryParams: any = { per_page: 'All' }, setPagination?: Function) => {
        const response = await TESTING_SERVICES_APIs.getPublishResult(queryParams);
        const { status } = response || {};
        if (status) {
            const data = response?.response?.data;
            if (data) {
                const {
                    total: totalRecords,
                    current_page: page,
                    per_page,
                } = response?.response;
                if (setPagination) {
                    setPagination({ per_page, totalRecords, page });
                }
                setData([...data]);
            } else {
                setData(response?.response);
            }
        }
    }

    const uploadResult = async (setData: Function,body: any, reset: Function) => {
        const response = await TESTING_SERVICES_APIs.uploadResult(body);
        const { status, message } = response || {};
        if (status) {

            successToaster(message);
            reset();
            const data = response?.response?.data;
            if (data) {
                const {
                    total: totalRecords,
                    current_page: page,
                    per_page,
                } = response?.response;
                // if (setPagination) {
                //     setPagination({ per_page, totalRecords, page });
                // }
                setData([...data]);

            } else {
                setData(response?.response);
            }
        }
    }

    const downloadResultSampleFile = async () => {
        const response = await TESTING_SERVICES_APIs.downloadResultSampleFile();
        const { status, message,errors } = response || {};
        if (status) {
            const data = response?.response?.data;
        }else{
            errorToaster(errors);
        }
    }

    // SEAATING PLAN ROOMS
    const getSeatingPlanRooms = async (setData: Function, queryParams: any = { per_page: 'All' }, setPagination?: Function) => {
        const response = await TESTING_SERVICES_APIs.getSeatingPlanRooms(queryParams);
        const { status } = response || {};
        if (status) {
            const data = response?.response?.data;
            if (data) {
                const {
                    total: totalRecords,
                    current_page: page,
                    per_page,
                } = response?.response;
                if (setPagination) {
                    setPagination({ per_page, totalRecords, page });
                }
                setData([...data]);
                // setSeatingPlan(data[0]?.seating_plan);
                // setTestSchedule(data[0]?.seating_plan?.test_schedule);

            } else {
                setData(response?.response);
            }
        }
    }
// SEAATING PLAN LIST
    const getSeatingPlanList = async (setData: Function, queryParams: any = { per_page: 'All' }, setPagination?: Function) => {
        const response = await TESTING_SERVICES_APIs.getSeatingPlanList(queryParams);
        const { status } = response || {};
        console.log(response);
        if (status) {
            const data = response?.response?.data;
            if (data) {
                const {
                    total: totalRecords,
                    current_page: page,
                    per_page,
                } = response?.response;
                if (setPagination) {
                    setPagination({ per_page, totalRecords, page });
                }
                setData([...data]);
                // setSeatingPlan(data[0]?.seating_plan);
                // setTestSchedule(data[0]?.seating_plan?.test_schedule);

            } else {
                setData(response?.response);
            }
        }
    }
    const getSeatingPlanRoomById = async (id: number, formData: any, setValue: Function, setFormData: Function) => {
        const response = await TESTING_SERVICES_APIs.getSeatingPlanRoomById(id);
        const { response: data } = response || {};
        for (let key in formData) {
            formData[key] = data[key];
            setValue(key, formData[key]);
        }

        setFormData({ ...formData });
    }

    const deleteSeatingPlanRoom = async (id: number, setData: Function, queryParams: any = { per_page: 10 }, setPagination: Function) => {
        const response = await TESTING_SERVICES_APIs.deleteSeatingPlanRoom(id);
        const { status, message } = response || {};
        if (status) {
            successToaster(message);
        }
    }

    const createSeatingPlanRoom = async (body: any,ScheduleId: number, reset: Function) => {
        const response = await TESTING_SERVICES_APIs.createSeatingPlanRoom(body,ScheduleId);
        const { status, message } = response || {};
        if (status) {
            successToaster(message);
        }
    }

    const updateSeatingPlanRoom = async (body: any,shouldNavigate: boolean = true) => {
        const response = await TESTING_SERVICES_APIs.updateSeatingPlanRoom(body.id, body);
        const { status, message } = response || {};
        if (status) {
            successToaster(message);
            if (shouldNavigate) navigate(-1);
        }
    }

    const generateSeatingPlan = async (body: any) => {
        const response = await TESTING_SERVICES_APIs.generateSeatingPlan(body); // Post request to send body data
        const { status, message } = response || {};
        if (status) {
          successToaster(message);
        }
      };

      const publishSeatingPlan = async (body: any) => {
        const response = await TESTING_SERVICES_APIs.publishSeatingPlan(body); // Post request to send body data
        const { status, message } = response || {};
        if (status) {
          successToaster(message);
        }
      };

      const unPublishSeatingPlan = async (body: any) => {
        const response = await TESTING_SERVICES_APIs.unPublishSeatingPlan(body); // Post request to send body data
        const { status, message } = response || {};
        if (status) {
          successToaster(message);
        }
      };
      
      const getDashboard = async (setData: Function) => {
        const response = await TESTING_SERVICES_APIs.getDashboard();
        const { status } = response || {};
        if (status) {
            const data = response?.response?.data;
            if (data) {
                setData([...data]);
            } else {
                setData(response?.response);
            }
        }
    }

    const getDashboardContent = async (setData: Function, queryParams: any) => {
        const response = await TESTING_SERVICES_APIs.getDashboardContent(queryParams);
        const { status } = response || {};
        if (status) {
            const data = response?.response?.data;
          
            if (data) {
                setData([...data]);
               

            } else {
                setData(response?.response?.lists);
                console.log(response?.response?.lists,'in hook 2');
            }
        }
    }

    const downloadResultCard = async (body: any, newWindow: Window | null) => {
        const response = await TESTING_SERVICES_APIs.downloadResultCard(body); 
        const { status, message, response: htmlContent } = response || {}; 
        if (status && newWindow) {
         
            newWindow.document.open();
            newWindow.document.write(htmlContent);
            newWindow.document.close();

            newWindow.focus();
            newWindow.onload = () => {
                newWindow.print();
            };
        } else {
            // Handle error cases
            successToaster(message || 'An error occurred while generating the result card.');
        }
      };

      const additionalInformation = async (body: any,shouldNavigate: boolean = true) => {
        const response = await TESTING_SERVICES_APIs.additionalInformation(body.test_appliction_id, body);
        const { status, message } = response || {};
        if (status) {
            successToaster(message);
        }
    }

    return {
        createTestingCenter,
        updateTestingCenter,
        getTestingCenterById,
        getTestingCenters,
        deleteTestingCenter,
        createTestingCenterRoom,
        updateTestingCenterRoom,
        getTestingCenterRoomById,
        getTestingCenterRooms,
        deleteTestingCenterRoom,
        createTestSchedule,
        updateTestSchedule,
        getTestScheduleById,
        getTestSchedules,
        deleteTestSchedule,
        getSeatingPlanById,
        getSeatingPlans,
        deleteSeatingPlan,
        removeApplicantSeatingPlan,
        createSeatingPlan,
        updateSeatingPlan,
        getCenterWiseSummary,
        getApplicants,
        getBatch,
        getPublishResult,
        uploadResult,
        downloadResultSampleFile,
        getSeatingPlanRooms,
        getSeatingPlanList,
        createSeatingPlanRoom,
        updateSeatingPlanRoom,
        deleteSeatingPlanRoom,
        generateSeatingPlan,
        getDashboard,
        getDashboardContent,
        downloadResultCard,
        additionalInformation,
        publishSeatingPlan,
        unPublishSeatingPlan,
        
    }
}

export default useTestingServices;