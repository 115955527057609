import { ChangeEvent, FC, useEffect, useState } from "react";
import {
  CreateVehicleEntrySection,
  CreateVehicleEntryMain,
  CreateVehicleEntryTop,
} from "./style";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import Breadcrumb from "components/particles/breadcrumb";
import { AddMeritKeyDTO } from "utils/helpers/models/admissions/add-merit-key.dto";
import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

// interface AddHostelProps {
//     setOpen: Function;
// }

const CreateVehicleEntry: FC = () => {
  const breadcrumbLinks = [
    { title: "Student Dashboard / ", path: siteRoutes.studentDashboard },
    {
      title: "Vehicle Entry Application",
      path: siteRoutes.createStudentVehicleEntry,
    },
  ];
  const [formData, setFormData] = useState<AddMeritKeyDTO>(
    new AddMeritKeyDTO()
  );
  // const { createMeritKey, updateMeritKey, getMeritKeyById } = useAdmissions();
  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<AddMeritKeyDTO>({ defaultValues: formData });
  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const onSubmit = (formData: AddMeritKeyDTO, addMore: boolean = false) => {
    if (params?.id) {
      // updateMeritKey(params?.id, formData);
    } else {
      // createMeritKey(formData, addMore, resetForm);
    }
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger([name]);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    for (const key in formData) {
      setValue(key as keyof AddMeritKeyDTO, "");
    }
    setFormData({ ...new AddMeritKeyDTO() });
  };

  useEffect(() => {
    if (params?.id) {
      // getMeritKeyById(params?.id, formData, setValue, setFormData);
    }
  }, []);

  return (
    <CreateVehicleEntryMain>
      <CreateVehicleEntryTop>
        <div className="left">
          <span className="page-heading">Vehicle Entry Application</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right"></div>
      </CreateVehicleEntryTop>

      <CreateVehicleEntrySection className="p-custom-scrollbar-8">
        <div className="top-heading">Create Request</div>
        <form>
          <div className="common-fields">
            <div className="input-field">
              <label>Vehicle Owner’s Name</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="text" placeholder="Vehicle Owner’s Name" />
                </div>
              </div>
            </div>
            <div className="input-field">
              <label>Vehicle Owner’s CNIC NO.</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="text" />
                </div>
              </div>
            </div>
            <div className="input-field">
              <label>Vehicle Type</label>
              <div className="field-wrap">
                <div className="field">
                  <select>
                    <option value="">Select Vehicle Type</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="input-field">
              <label>Registration Number</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="number" name="" id="" />
                </div>
              </div>
            </div>
            <div className="input-field">
              <label>Color</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="text" />
                </div>
              </div>
            </div>
            <div className="input-field">
              <label>Make & Model</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="text" />
                </div>
              </div>
            </div>
            <div className="input-field">
              <label>Vehicle Reg. Copy*</label>
              <div className="upload-container">
                <div className="upload-section">
                  <label htmlFor="file-upload" className="upload-label">
                    Choose a file
                  </label>
                  <input type="file" id="file-upload" className="file-input" />
                </div>

                <div className="file-details-section" id="file-details">
                  <p className="file-placeholder">No file chosen</p>
                </div>
              </div>
            </div>
          </div>

          <div className="action-buttons">
            <div className="buttons">
              <button
                className="lg-rounded-btn gray"
                type="button"
                onClick={resetForm}
              >
                Reset
              </button>

              <button
                className="lg-rounded-btn"
                type="submit"
                onClick={handleSubmit((data: AddMeritKeyDTO) => onSubmit(data))}
              >
                {params?.id ? "Update" : "Save"} & Exit
              </button>
            </div>
          </div>
        </form>
      </CreateVehicleEntrySection>
    </CreateVehicleEntryMain>
  );
};

export default CreateVehicleEntry;
