import { FC } from "react";
import { NoticeBoardSection, NoticeBoardMain, NoticeBoardTopSection } from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import outlines from 'assets/images/common/others/outlines.png';
import { GrayDotedCalendar } from "assets/images/common/svgs";

interface NoticeBoardProps { }

const NoticeBoard: FC<NoticeBoardProps> = ({ }) => {
    const breadcrumbLinks: BreadcrumbLink[] = [
        { title: 'Student Dashboard / ', path: siteRoutes.studentDashboard },
        { title: 'Notice Board', path: siteRoutes.studentPortalNoticeBoard },
    ];

    return (
        <NoticeBoardMain>
            <NoticeBoardTopSection>
                <span className="page-heading">
                    Notice Board
                </span>
                <Breadcrumb links={breadcrumbLinks} />
            </NoticeBoardTopSection>

            <NoticeBoardSection className="content-radius-shadow">
                <div className="notice-wrapper">
                    {[1, 1, 1, 1, 1].map((item, index) => {
                        return <div className="particular-notice" key={index}>
                            <div className="left">
                                <div className="notice-thumbnail">
                                    <img src={outlines} alt="outlines" />
                                </div>
                                <div className="notice-info">
                                    <div className="notice-title">
                                        Fixation of Last Date to Deposit Annual Fee for Bachelor Degree Programs Fall 2024
                                    </div>
                                    <div className="notice-description">
                                        All Admission related states & reports
                                    </div>
                                    <div className="notice-description">
                                        Note: No other channels will be accepted for clearance from now on                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="notice-issue-info">
                                    <div className="issuance-date">
                                        <span className="date-icon">
                                            <GrayDotedCalendar />
                                        </span>
                                        <span className="date">15 November 2024</span>
                                    </div>
                                    <span className="issuance-time">
                                        09:10AM - 10:50PM
                                    </span>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </NoticeBoardSection>
        </NoticeBoardMain>
    )
}

export default NoticeBoard;