import { FC, useEffect, useRef, useState } from "react";
import {
  AddButtonPopupMain,
  Container,
  ContentWrapper,

} from "./style";
import { CloseMediumSvg, CopyPhoneSvg } from "assets/images/common/svgs";
import { useForm } from "react-hook-form";
import useUtils from "hooks/useUtils";
import useTestingServices from "containers/private/testing-services/useHooks";
import { TestApplicationDTO } from "utils/helpers/models/testing-service/test-application.dto";
import { TestingCenterDTO } from "utils/helpers/models/testing-service/testing-center.dto";
import MapComponent from "../map/mapComponent";
// import MapComponent from '';

interface buttonPopupProps {
  setOpen: Function;
  testCenter: TestingCenterDTO
  clickedFrom?: any;
}

const ButtonPopup: FC<buttonPopupProps> = ({ setOpen, testCenter, clickedFrom }) => {

  const handleCloseModal = () => {
    setOpen(false);
  };

  const [formData, setFormData] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([
    formData
  ]);
  const [isCopied, setIsCopied] = useState(false); 

  const copyToClipboard = (text: string) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        setIsCopied(true); 
        setTimeout(() => setIsCopied(false), 2000); 
      }).catch(err => {
        console.error("Failed to copy: ", err);
      });
    } else {
      const input = document.createElement('input');
      input.value = text;
      document.body.appendChild(input);
      input.select();
      try {
        document.execCommand('copy');
        setIsCopied(true); 
        setTimeout(() => setIsCopied(false), 2000); 
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(input);
    }
  };
  
  const renderField = () => {
    switch (clickedFrom) {
      case 'call':
        return (
          <div className="input-field">
            <label>Phone number</label>
            <div className="field-wrap">
              <div className="field">
                <input placeholder="Phone" value={testCenter?.phone_no} readOnly />
                <div onClick={() => copyToClipboard(testCenter?.phone_no)}>
                  <CopyPhoneSvg />
                </div>
              </div>
            </div>
            {isCopied && <small className="copied-text">Copied!</small>}
          </div>
        );
      case 'email':
        return (
          <div className="input-field">
            <label>Email</label>
            <div className="field-wrap">
              <div className="field">
                <input placeholder="Email" value={testCenter?.email} readOnly />
                <div onClick={() => copyToClipboard(testCenter?.email)}>
                  <CopyPhoneSvg />
                </div>
              </div>
            </div>
            {isCopied && <small className="copied-text">Copied!</small>}
          </div>
        );
      case 'location':
        return (
          <div className="input-field">
            <label>Location</label>
            <div className="field-wrap">
              <div className="map-wrapper">
                {/* Example: Render map with a marker based on lat/long */}
                <MapComponent
                  latitude={testCenter?.lat}
                  longitude={testCenter?.long}
                  markerTitle="Test Center Location"
                />
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <AddButtonPopupMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>{testCenter?.name}</span>
            </div>
            <div className="close-icon cp" onClick={handleCloseModal}>
              <CloseMediumSvg className="icon" />
             
            </div>
          </div>

          <form action="">
            <div className="common-fields">
          {renderField()}  {/* Conditionally render phone, email, or map */}
        </div>

            <div className="action-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" type="reset" onClick={handleCloseModal}>
                  Close
                </button>
              </div>
            </div>
          </form>

        </ContentWrapper>
      </Container>
    </AddButtonPopupMain>
  );
};

export default ButtonPopup;
