import styled from "styled-components";

export const UploadTestResultMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;

export const UploadTestResultSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em;
  }

  .tableHeading {
    font-size: 24px;
    font-weight: 700;
    border-bottom: 1px solid var(--lightgray-extralight);
    color: var(--black-text);

    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
  }

  .list-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      gap: 1em;
    }
  }

  table td {
    
    .org-logo {
      width: 38px;
      height: 35px;
      background: var(--lightgray-extralight);
    }

    .mw-150 {
      min-width: 150px;
    }
    .mw-100 {
      min-width: 100px;
    }

    .expanded-content {
      display: flex;
      flex-direction: column;
      gap: 0.7em;

      .particular-info {
        display: flex;
        gap: 1em;
        .title {
          min-width: 170px;
          color: var(--black-text);
          font-size: 15px;
          font-weight: 400;
        }

        .info {
          font-size: 15px;
          font-weight: 400;
          color: var(--lightgray-medium);

          .table-action-icons {
            @media screen and (max-width: 900px) {
              flex-wrap: wrap;
            }
          }
        }
      }
    }
    
  }
   .action-menu {
        width: 30px;
        position: relative;

        .menu-icon {
          .icon {
            rect {
              fill: var(--primary);
            }
          }
        }
      }

  .menu-dropdown {
    position: absolute;
    z-index: 9999;
    bottom: 35px;
  
    right: 3px;
    width: 270px;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 1.5em;
    background: var(--white-color);
    box-shadow: 0px 4px 4px 0px #00000040;

    .particular-menu {
      width: 100%;
      display: flex;
      gap: 1em;
      align-items: center;
      border-bottom: 1px solid var(--gray-medium);

      .title {
        color: var(--black-text);
        font-size: 16px;
        font-weight: 700;
      }

      .action-icon {
        width: 35px;
        height: 35px;

        .icon {
        }
      }
    }
  }
`;

export const UploadTestResultsTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }

  .right {
    .menu-bar {
      position: relative;
      .menu-button {
        svg {
          width: 40px;
          height: 40px;

          @media screen and (max-width: 450px){
            width: 30px;
            height: 30px;
          }

          rect {
            rx: 5;
          }
        }
      }

      .dropdown-menu {
        position: absolute;
        width: 440px;
        top: 42px;
        right: 0;
        border-radius: 8px;
        background: var(--white-color);
        z-index: 20;
        display: flex;
        flex-direction: column;
        padding: 1em 0.5em;

        @media screen and (max-width: 500px){
          width: 88vw;
        }

        @media screen and (max-width: 450px){
          top: 32px;
        }

        .particular-menu {
          font-size: 16px;
          font-weight: 400;
          color: var(--black-text);
          user-select: none;
          cursor: pointer;
          border-radius: 8px;
          padding: .5em 1em;
          &:hover {
            color: var(--white-constant);
            background: var(--primary);
          }
        }
      }
    }
  }
`;

export const FilterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em 3em;

  @media screen and (max-width: 630px) {
    padding: 1em 1.5em;
  }
  
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 2em;
  transition: all 2s ease-in-out;
  .upload-container {
    display: flex;
  }
  /* Upload section styling */
  .upload-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  .upload-label {
    display: flex;
    background-color: var(--field-border);
    color: var(--black-text);
    border-radius: 8px 0px 0px 8px;
    cursor: pointer;
    width: 100%;
    height: 50px;
    align-items: center;
    padding: 5px 10px;
    font-size: 14px;
  }
  .file-input {
    display: none;
  }
  /* File details section styling */
  .file-details-section {
    padding: 10px;
    background-color: var(--input-field-bg);
    border-radius: 4px;
    width: 75%;
    height: 50px;
    border: 1px solid var(--field-border);
  }
  .file-placeholder {
    margin: 0;
    color: #777;
    text-align: center;
  }

  .filter-fields {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 1.5em;
    color: var(--lightgray-medium);
    font-size: 15px;
    font-weight: 400;

    @media screen and (max-width: 450px) {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  }
  .submit-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .buttons {
      display: flex;
      gap: 1em;

      @media screen and (max-width: 500px) {
        width: 100%;
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }
`;

export const FilterHeader = styled.div<{ showFilterDropdown: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .filter-heading {
    font-size: 24px;
    font-weight: 700;
    border-bottom: 1px solid var(--lightgray-extralight);
    color: var(--black-text);

    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
  }

  .dropdown-arrow {
    .icon {
      width: 20px;
      height: 28px;
      rotate: ${({ showFilterDropdown }) =>
    showFilterDropdown ? "0" : "180deg"};
      transition: all 0.1s ease-in-out;

      path {
        fill: var(--lightgray-medium);
      }

      @media screen and (max-width: 480px) {
        width: 18px;
        height: 24px;
      }
    }
  }
`;
