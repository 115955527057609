import { FC, Fragment, useEffect, useState } from "react";
import {
  DeleteTableSvg,
  EditGreenTableSvg,
  ExcelSvg,
  MannageRoomSvg,
  PdfSvg,
  SearchFieldSvg,
  StudenSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
  ProgramSpecializationListingMain,
  ProgramSpecializationListingSection,
  ProgramSpecializationListingTop,
} from "./style";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import useAcademics from "../../useHooks";
import useUtils from "hooks/useUtils";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import { confirmationPopup } from "utils/helpers/common/alert-service";

const ProgramSpecializationListing: FC = ({}) => {
  const breadcrumbLinks = [
    { title: "Academics  /", path: "" },
    {
      title: "Program Specialization",
      path: siteRoutes.academicProgramSpecializationListing,
    },
  ];
  const navigate = useNavigate();
  const { handleSearchChange, handleTableSearch } = useUtils();

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const { hasAccess } = useStore();

  const columns: string[] = [
    "Title",
    "Programs",
    "Start Date",
    "End Date",
    "Status",
    "Action",
  ];

  const goToCreate = () => {
    navigate(siteRoutes.academicCreateProgramSpecialization);
  };

  const goToManageSpecialization = () => {
    navigate(siteRoutes.academicManageSpecializationListing);
  };
  const goToManageStudent = () => {
    navigate(siteRoutes.academicManageStudentListing);
  };

  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
  };

  const [openedDropdownIndex, setOpenedDropdownIndex] = useState<number | null>(
    null
  ); // State to track the open dropdown by index
  const [isTableOpened, setIsTableOpened] = useState(false);

  const toggleDropdown = (index: number) => {
    setOpenedDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    setIsTableOpened((prevState) => !prevState);
  };

  const { getProgramSpecialization, deleteProgramSpecialization } =
    useAcademics();
  const [data, setData] = useState<any[]>([]);

  const { getDateFromDateTime } = useUtils();
  const [search, setSearch] = useState("");

  const goToEdit = (id: number) => {
    navigate(`${siteRoutes.academicCreateProgramSpecialization}?id=${id}`);
  };
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
      };
      deleteProgramSpecialization(id, setData, queryParams, setPagination);
      // getProgramSpecialization(setData);
    }
  };
  const getAllProgramSpecializations = (page: number, search: string) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
    };
    getProgramSpecialization(setData, queryParams, setPagination);
  };
  useEffect(() => {
    getAllProgramSpecializations(pagination.page, search);
  }, []);

  return (
    <ProgramSpecializationListingMain>
      <ProgramSpecializationListingTop>
        <div className="left">
          <span className="page-heading">Program Specialization</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.createTestingServicesTestSchedule) && (
            <div className="create-org-btn">
              <button onClick={goToCreate} className="lg-rounded-btn">
                + Add Session
              </button>
            </div>
          )}
        </div>
      </ProgramSpecializationListingTop>

      <ProgramSpecializationListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              onChange={(e) =>
                handleSearchChange(e, setSearch, getAllProgramSpecializations)
              }
              onKeyUp={(e) =>
                handleTableSearch(e, getAllProgramSpecializations)
              }
            />
          </div>
        </div>
        <div className={`data-table ${isTableOpened ? "menu-opened" : ""}`}>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <tr>
                      <td>{item?.session?.type}</td>
                      <td>{item?.program?.title} </td>
                      <td>{getDateFromDateTime(item?.created_at)}</td>
                      <td>25-05-2025</td>
                      <td>
                        <div className="mw-150">
                          {item?.is_active === 1 ? (
                            <span className="status-tile green">Active</span>
                          ) : (
                            <span className="status-tile red">InActive</span>
                          )}
                        </div>
                      </td>

                      <td>
                        <div className="action-menu">
                          <div
                            className="menu-icon cp"
                            onClick={() => toggleDropdown(index)}
                          >
                            <TabPrimaryActionMenu className="icon" />
                          </div>
                          {openedDropdownIndex === index && (
                            <div className="table-menu-dropdown">
                              <div
                                className="particular-menu cp"
                                onClick={() => goToEdit(item.id)}
                              >
                                <div className="action-icon">
                                  <EditGreenTableSvg className="icon" />
                                </div>
                                <span className="title">Edit</span>
                              </div>
                              <div
                                className="particular-menu cp"
                                onClick={goToManageSpecialization}
                              >
                                <div className="action-icon">
                                  <MannageRoomSvg className="icon" />
                                </div>
                                <span className="title">
                                  Manage Specialization
                                </span>
                              </div>
                              <div
                                className="particular-menu cp"
                                onClick={goToManageStudent}
                              >
                                <div className="action-icon">
                                  <StudenSvg className="icon" />
                                </div>
                                <span className="title">Manage Students</span>
                              </div>

                              <div
                                className="particular-menu cp"
                                onClick={() => handleDelete(item?.id)}
                              >
                                <div className="action-icon">
                                  <DeleteTableSvg className="icon" />
                                </div>
                                <span className="title">Delete</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </ProgramSpecializationListingSection>
    </ProgramSpecializationListingMain>
  );
};

export default ProgramSpecializationListing;
