import styled from "styled-components";

export const CreateSetHolidaysMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;

export const CreateSetHolidaysTop = styled.div`
  .header {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
`;

export const CreateSetHolidaysDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em;
  }
  .date-picker-section {
    width: 50%;
    margin-top: 2em;
  }
  .rdrDefinedRangesWrapper {
    display: none;
  }
  .rdrDateRangePickerWrapper {
    display: inline-flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 100%;

    border-radius: 20px;
  }
  .rdrCalendarWrapper {
    color: var(--black-text);
    font-size: 12px;
    width: 100%;
    background-color: var(--white-color);
  }
  .rdrDateDisplayWrapper {
    background-color: var(--lightgray-extralight);
    width: 100%;
    border-radius: 15px 15px 0px 0px;
    border: 1px solid var(--black-text);
  }
  .rdrMonthAndYearWrapper {
    align-items: center;
    height: 60px;
    padding-top: 10px;
    width: 100%;
    border-left: 1px solid var(--black-text);
    border-right: 1px solid var(--black-text);
  }
  .rdrMonth {
    width: 100%;
    padding: 0 0.833em 1.666em 0.833em;
    border: 1px solid var(--black-text);
  }
  .rdrDateDisplayItem input {
    cursor: pointer;
    height: 2.5em;
    line-height: 2.5em;
    border: 0px;
    background: var(--white-color);
    width: 100%;
    color: var(--lightgray-medium);
    outline: none;
  }
  .rdrMonthAndYearPickers select {
    border: 0;
    background: var(--lightgray-extralight);
    padding: 10px 30px 10px 10px;
    border-radius: 4px;
    outline: 0;
    color: black;
    background-position: right 8px center;
    cursor: pointer;
    text-align: center;
  }
  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    background: currentColor;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 5px;
    color: var(--primary) !important;
  }
  .rdrDayNumber span {
    color: var(--black-text);
  }
`;
