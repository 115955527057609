import { FC, useState } from "react";
import {
  CreateManageMeritSection,
  CreateManageMeritListMain,
  CreateManageMeritListTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";

import { siteRoutes } from "utils/helpers/enums/routes.enum";

const CreateManageSpecialization: FC = () => {
  const breadcrumbLinks = [
    { title: "Academics /", path: siteRoutes.admissionCampaignListing },
    {
      title: "Program Specialization / ",
      path: siteRoutes.academicProgramSpecializationListing,
    },
    {
      title: "Manage Specialization /",
      path: siteRoutes.academicManageSpecializationListing,
    },
    {
      title: "Add Specialization ",
      path: siteRoutes.academicCreateManageSpecialization,
    },
  ];

  return (
    <CreateManageMeritListMain>
      <CreateManageMeritListTop>
        <div className="left">
          <span className="page-heading">Add Specialization</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right"></div>
      </CreateManageMeritListTop>

      <CreateManageMeritSection className="p-custom-scrollbar-8">
        <form>
          <div className="common-fields">
            <div className="input-field ">
              <label>Specialization </label>
              <div className="field-wrap">
                <div className="field">
                  <select name="" id="">
                    <option value="">Select Specialization</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-field ">
              <label>Plan of Study </label>
              <div className="field-wrap">
                <div className="field">
                  <select name="" id="">
                    <option value="">Select Plan of Study</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-field ">
              <label>Number of Seats </label>
              <div className="field-wrap">
                <div className="field">
                  <input type="number" />
                </div>
              </div>
            </div>
          </div>

          <div className="common-fields">
            <div className="input-field ">
              <label>Display Order </label>
              <div className="field-wrap">
                <div className="field">
                  <input type="number" />
                </div>
              </div>
            </div>
            <div className="radio-field">
              <label>Status</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="radio" id="active" value={1} />
                  <label htmlFor="active">Active</label>
                </div>
                <div className="field">
                  <input type="radio" id="deactivate" />
                  <label htmlFor="deactivate">Deactivate</label>
                </div>
              </div>
            </div>
          </div>

          <div className="action-buttons">
            <div className="buttons">
              <button className="lg-rounded-btn gray" type="button">
                Reset
              </button>
              <button className="lg-rounded-btn black">Save & Add more</button>
              <button className="lg-rounded-btn">Save & Exit</button>
            </div>
          </div>
        </form>
      </CreateManageMeritSection>
    </CreateManageMeritListMain>
  );
};

export default CreateManageSpecialization;
