export class TestApplicationDTO {
    id!: number;
    // sch_id: string = '';
    phd_program_id: string = '';
    test_center_id:string = '';
    additional_marks: string = '';
    additional_result_remarks: string = '';
    challan_upload: string = '';
    app_id: string = ''
    challan_file: any = '';
    challan_refference_no: string = '';

}