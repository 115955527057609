import { FC, Fragment, useEffect, useState } from "react";
import {
  TestingServiceListingSection,
  TestingServiceListingTop,
  TestingServiceListingMain,
  TestWrapper,
  TestCard,
  TestListingSection,
} from "./style";
import {
  DownloadApplicationSvg,
  DownloadChallanSvg,
  DownloadDocumentSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
  UploadChallanSvg,
} from "assets/images/common/svgs";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import DataNotFound from "components/particles/table/data-not-found";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { SmallWhiteRightArrow } from "assets/images/e-portal/svgs";
import ApplyForTest from "./components/apply-for-test";
import useEportal from "containers/private/e-portal/useHooks";
import useUtils from "hooks/useUtils";
import useTestingServices from "containers/private/testing-services/useHooks";
import { TestApplicationDTO } from "utils/helpers/models/testing-service/test-application.dto";
import UploadChallanPopup from "./components/upload-challan-popup";
import UploadChallan from "./components/upload-challan-popup";

const EportalTestingServices: FC = ({ }) => {
  const navigate = useNavigate();
  const { getDateFromDateTime } = useUtils();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const columns: string[] = [
    "App#",
    "Test",
    "Test Center",
    "Apply Date  ",
    "Last Date",
    "Total Marks",
    "Obtained Marks",
    "Result Status",
    "Fee Status",
    "Action",
  ];

  // Define a type for your state
  type RowData = {
    isDropdownOpen: boolean;
  };

  const [data, setData] = useState<any[]>([]);
  const [testSchedule, setTestSchedule] = useState<any[]>([]);
  const [testCenters, setTestCenters] = useState<any[]>([]);
  const [testType, setTestType] = useState<any[]>([]);
  // const [searchedTestApplications, setTestApplications] = useState<any[]>([]);
  const [searchedTestApplications, setTestApplications] = useState<
    TestApplicationDTO[]
  >([]);

  const [search, setSearch] = useState<string>("");
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [testIdToApply, setTestIdToApply] = useState<number>(0);
  const [testAppId, setTestAppId] = useState<number>(0);

  const [filters, setFilters] = useState<any>({
    test_id: "",
    session_id: "",
    test_center_id: "",
    batch: "",
  });
  const { handleSearchChange, handleTableSearch } = useUtils();
  const {
    getAppliedTests,
    getTestSchedules,
    checkTestEligibility,
    generateFeeChallan,
    downloadResultCard,
    downloadTestSlip,
  } = useEportal();

  const toggleRowExpand = (index: number) => {
    const updatedData = [...data]; // Copy the data array
    if (updatedData[index]) {
      updatedData[index].isDropdownOpen = !updatedData[index].isDropdownOpen; // Toggle the dropdown state
      setData(updatedData); // Update the state with the modified data
    }
  };

  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Testing Service / ", path: "" },
    {
      title: " Results / My Tests",
      path: siteRoutes.eportalTestingServiceListing,
    },
  ];
  const [applyTest, setApplyTest] = useState<boolean>(false);
  const [uploadChallan, setUploadChallan] = useState<boolean>(false);
  const [isPhd, setIsPhd] = useState<any[]>([]);


  const goToApplyTest = (testId: number) => {
    setTestIdToApply(testId);
    checkTestEligibility(
      testId,
      setApplyTest,
      setTestCenters,
      setTestType,
      setIsPhd
    );
  };

  const goToUploadChallan = (index: number, app_id: number) => {
    (data as any)[index].isDropdownOpen = false;

    setTestAppId(app_id)
    setUploadChallan(true)
  };

  useEffect(() => {
    if (!applyTest)
      getAllTestApplications(pagination.page, search, filters);
    getAllTestSchedules(pagination.page, search, filters);
  }, [applyTest]);

  const getAllTestSchedules = (page: number, search: string, filters: any) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
      ...filters,
    };
    getTestSchedules(setTestSchedule, queryParams, setPagination);
  };
  const getAllTestApplications = (
    page: number,
    search: string,
    filters: any
  ) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
      ...filters,
    };
    getAppliedTests(setData, queryParams, setPagination);
  };

  const generate = (app_id: number) => {
    generateFeeChallan(app_id);
  };

  const downloadResult = (app_no: number) => {
    const newWindow = window.open();

    if (newWindow) {
      newWindow.document.write();
      downloadResultCard({ app_no }, newWindow);
    }
  };

  const downloadSlip = (sch_id: number, applicant_id: number) => {
    const newWindow = window.open();

    if (newWindow) {
      newWindow.document.write();
      downloadTestSlip({ sch_id, applicant_id }, newWindow);
    }
  };
  const [openedDropdownIndex, setOpenedDropdownIndex] = useState<number | null>(
    null
  ); // State to track the open dropdown by index
  const [isTableOpened, setIsTableOpened] = useState(false);

  const toggleDropdown = (index: number) => {
    setOpenedDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    setIsTableOpened((prevState) => !prevState);
  };

  console.log(data, 'applica');

  return (
    <Fragment>
      <TestingServiceListingMain>
        <TestingServiceListingTop>
          <div className="left">
            <span className="page-heading">Results / My Tests</span>
            <Breadcrumb links={breadcrumbLinks} />
          </div>
          <div className="right"></div>
        </TestingServiceListingTop>
        <TestingServiceListingSection
          className="content-radius-shadow"
          isTableOverflowing={false}
        >
          <div>
            <span className="table-heading">Test Results </span>
          </div>
          <div className="list-header">
            <div className="table-data-export-buttons">
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>

              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            </div>
            <div className="table-search-field">
              <span className="search-icon">
                <SearchFieldSvg className="icon" />
              </span>
              <input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) =>
                  handleSearchChange(e, setSearch, getAllTestApplications)
                }
                onKeyUp={(e) => handleTableSearch(e, getAllTestApplications)}
              />
            </div>
          </div>
          <div className={`data-table ${isTableOpened ? "menu-opened" : ""}`}>
            <table className="bottom-bordered-cells">
              <thead>
                <tr>
                  {columns.map((column: string, index: number) => {
                    return <th key={index}>{column}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map((application: any, index) => {
                  return (
                    <Fragment key={index}>
                      <tr
                        className={`expandable ${(application as any).isExpanded && "opened"
                          }`}
                      >
                        <td>{application?.app_no}</td>
                        <td>
                          <div className="mw-150">{application?.test_name}</div>
                        </td>
                        <td>{application?.center_name}</td>
                        <td>{getDateFromDateTime(application?.apply_date)}</td>
                        <td>{getDateFromDateTime(application?.end_date)}</td>
                        <td>{application?.total_marks ?? '-'}</td>
                        <td>{application?.obt_marks ?? '-'}</td>
                        <td>
                          {application?.ob_marks === null ? (
                            <span className="status-tile gray">Pending</span>
                          ) : application.ob_marks >= application.passing_marks ? (
                            <span className="status-tile">Pass</span>
                          ) : (
                            <span className="status-tile red">Fail</span>
                          )}

                        </td>
                        <td>

                          {application?.verify_status == 1 ? (
                            <span className="status-tile">Paid</span>
                          ) : (
                            <span className="status-tile red">Unpaid</span>
                          )}
                        </td>

                        <td>
                          <div className="table-action-icons">
                            <div className="action-menu">
                              <div
                                className="menu-icon"
                                onClick={() => toggleDropdown(index)}
                              >
                                <TabPrimaryActionMenu className="icon" />
                              </div>
                              {openedDropdownIndex === index && (
                                <div className="table-menu-dropdown">
                                  {application.ob_marks !== null && application.is_publish === 1 && (
                                    <div className="particular-menu cp" onClick={() => downloadResult(application.app_no)}>
                                      <div className="action-icon">
                                        <DownloadApplicationSvg className="icon" />
                                      </div>
                                      <span className="title">Download Result Card</span>
                                    </div>
                                  )}
                                  {application.is_publish === 1 && (
                                    <div className="particular-menu cp" onClick={() => downloadSlip(application.sch_test_id, application.applicant_id)}>
                                      <div className="action-icon">
                                        <DownloadDocumentSvg className="icon" />
                                      </div>
                                      <span className="title">Download Roll No Slip</span>
                                    </div>
                                  )}
                                  <div className="particular-menu cp" onClick={() => generate(application.challan_no)}>
                                    <div className="action-icon">
                                      <DownloadChallanSvg className="icon" />
                                    </div>
                                    <span className="title">
                                      Download Challan
                                    </span>
                                  </div>
                                  <div className="particular-menu cp" onClick={() => goToUploadChallan(index, application?.challan_no)}>
                                    <div className="action-icon">
                                      <UploadChallanSvg className="icon" />
                                    </div>
                                    <span className="title">
                                      Upload Challan
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Fragment>
            <DataNotFound show={!data.length && !isLoading} />
          </Fragment>
        </TestingServiceListingSection>
        <TestListingSection className="content-radius-shadow">
          <div className="heading">
            <span> Available Test Schedule</span>
          </div>
          <TestWrapper>
            {testSchedule.map((item, index) => {
              return (
                <TestCard key={index}>
                  <div className="card-header">
                    <div className="header-text">
                      <span className="title">Test: {item?.test?.title}</span>
                      <span className="batch">
                        {item?.test?.title} - Batch {item?.batch}
                      </span>
                    </div>
                  </div>
                  <div className="info-section">
                    <div className="info">
                      <span className="title">Start Date:</span>
                      <span className="detail">{item?.start_date}</span>
                    </div>
                    <div className="info">
                      <span className="title">Last Date:</span>
                      <span className="detail">{item?.end_date} </span>
                    </div>
                    <div className="info">
                      <span className="title">Test Date ( Tentative):</span>
                      <span className="detail">{item?.test_date}</span>
                    </div>
                    <div className="info">
                      <span className="title">Fee:</span>
                      <span className="detail">Rs. {item?.fee}</span>
                    </div>
                  </div>

                  <div className="apply-button">
                    <button
                      className="lg-rounded-btn"
                      onClick={() => goToApplyTest(item?.id)}
                    >
                      <span className="icon">
                        <SmallWhiteRightArrow />
                      </span>
                      <span className="title">Apply Now</span>
                    </button>
                  </div>
                </TestCard>
              );
            })}
          </TestWrapper>
        </TestListingSection>
      </TestingServiceListingMain>

      {applyTest && (
        <ApplyForTest
          test_id={testIdToApply}
          testCenters={testCenters}
          testType={testType}
          phd_programs={isPhd}
          setOpen={setApplyTest}
        />)}
      {uploadChallan &&
        <UploadChallan

          app_id={testAppId}
          setOpen={setUploadChallan}
        />}
    </Fragment>
  );
};

export default EportalTestingServices;
