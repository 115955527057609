import {FC, Fragment, useEffect, useState} from "react";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
  TestCenterListingMain,
  TestCenterListingSection,
  TestCenterListingTop,
} from "./style";
import useComponentVisible from "hooks/click-outside";
import useStore from "hooks/useStore";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import useUtils from "../../../../hooks/useUtils";
import useTestingServices from "containers/private/testing-services/useHooks";
import { format } from 'date-fns';
import {confirmationPopup} from "../../../../utils/helpers/common/alert-service";

const ViewApplicants: FC = ({ }) => {
  const breadcrumbLinks = [
    { title: "Testing Services /", path: "" },

    {
      title: "Applicants Seating Plan List",
      path: siteRoutes.viewTestApplicants,
    },
  ];
  const navigate = useNavigate();

  const { hasAccess } = useStore();
  const [search, setSearch] = useState("");

  const {
    getSeatingPlanList,
    removeApplicantSeatingPlan
  } = useTestingServices();

  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const [data, setData] = useState([]);

  const [seatingPlanList, setSeatingPlanList] = useState<any>([]);

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const getAllSeatingPlansList = (page: number, search: string) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
      seating_plan_id: params?.seating_plan_id
    };
    getSeatingPlanList(setSeatingPlanList, queryParams, setPagination);
  };



  useEffect(() => {
    getAllSeatingPlansList(pagination.page, search);

  }, []);


  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
      };
      removeApplicantSeatingPlan(id, setData, queryParams, setPagination);
      getAllSeatingPlansList(pagination.page, search);
    }
  };

  const columns: string[] = [
    "ID",
    "Roll no",
    "Candidate Name",
    "CNIC",
    "Room",
    "Apply Date",
    "Seat No",
    "Slip Published",
    "Action",
  ];

  return (
    <TestCenterListingMain>
      <TestCenterListingTop>
        <div className="left">
          <span className="page-heading">Applicants Seating Plan List</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </TestCenterListingTop>

      <TestCenterListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            // onKeyUp={handleSearch}
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
            {seatingPlanList?.map((seatingPlan: any, index: number) => {
              return (
              <Fragment>
                <tr>
                  <td>
                    <div className="mw-100">{seatingPlan?.seating_id}</div>
                  </td>
                  <td>
                    <div className="mw-150">{seatingPlan?.application_no}</div>
                  </td>
                  <td>
                    <div className="mw-100">{seatingPlan?.full_name}</div>
                  </td>
                  <td>
                    <div className="mw-150">{seatingPlan?.cnic}</div>
                  </td>
                  <td>
                    <div className="mw-100">
                      {seatingPlan?.room_name}
                    </div>
                  </td>
                  <td>
                    <div className="mw-150">{format(new Date(seatingPlan?.apply_date), 'yyyy-MM-dd')}</div>
                  </td>
                  <td>
                    <div className="mw-100">{seatingPlan?.seat_no}</div>
                  </td>
                  <td>
                    <span className="status-tile green">{seatingPlan.is_active === 0 ? "No" : "Yes"}</span>
                  </td>

                  <td>
                    <div className="mw-100">
                      <div className="table-action-icons">
                        <div className="action-icon">
                          <EditTableSvg />
                        </div>

                        <div className="action-icon" onClick={() => handleDelete(seatingPlan.id)}>
                          <DeleteTableSvg />
                        </div>
                      </div>
                      <div className="action-button">
                        <button className="special-btn">Show Password</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </Fragment>
              );
            })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <Pagination
            onPageChange={(page: any) => console.log(page)}
            {...pagination}
          />
        </Fragment>
      </TestCenterListingSection>

      {/* section form */}
    </TestCenterListingMain>
  );
};

export default ViewApplicants;
