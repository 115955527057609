import styled from "styled-components";

export const CreateAuthoritiesMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;

export const CreateAuthoritiesTop = styled.div`
  .header {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
`;

export const CreateAuthoritiesFormSection = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;
  @media screen and (max-width: 630px) {
    padding: 1em;
  }

  .subform-heading{
    font-size: 18px;
    padding-bottom: 1.5em;
    font-weight: 500;
    color: var(--black-text);
  }

  .common-fields {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(308px, 1fr));
    gap: 1.5em;
    color: var(--lightgray-medium);
    font-size: 15px;
    font-weight: 400;
    padding-top: 1em;

    .input-field {
      &.last {
        .field-wrap {
          flex-direction: row;
          gap: 1em;

          .action-buttons {
            display: flex;
            gap: .5em;
            .particular-btn {
              svg {
                rect {
                  fill-opacity: 1;
                }
                path {
                  fill: var(--white-constant);
                }
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 450px) {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  }
  .submit-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 1em;

      @media screen and (max-width: 500px) {
        width: 100%;
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }
`;
