export class TestingCenterDTO {
    id!: number;
    name: string = '';
    description: string = '';
    address: string = '';
    capacity: string = '';
    phone_no: string = '';
    lat: string = '';
    long: string = '';
    is_active!: number;
    city: string = '';
    email: string = '';
    center_cities!: any;
}