import { deleteRequest, getRequest, postRequest, putRequest } from "utils/helpers/common/http-methods";
import { BankInfoDTO } from "utils/helpers/models/finance/bank-info.dto";
import { BankDiscountAdjustmentDTO, BankTransactionDTO } from "utils/helpers/models/finance/bank-transaction.dto";
import { VoucherTypeDTO } from "utils/helpers/models/finance/voucher-type.dto";

export const FINANCE_APIS = {
    createBankInfo: (body: BankInfoDTO) => postRequest(`admin/banks_info`, body),
    updateBankInfo: (id: number, body: any) => putRequest(`admin/banks_info/${id}`, body),
    getBankInfos: (params: any) => getRequest(`admin/banks_info`, params),
    getBankInfoById: (id: number) => getRequest(`admin/banks_info/${id}`),
    deleteBankInfoById: (id: number) => deleteRequest(`admin/banks_info/${id}`),
    createVoucherType: (body: VoucherTypeDTO) => postRequest(`admin/voucher_types`, body),
    updateVoucherType: (id: number, body: any) => putRequest(`admin/voucher_types/${id}`, body),
    getVoucherTypes: (params: any) => getRequest(`admin/voucher_types`, params),
    getVoucherTypeById: (id: number) => getRequest(`admin/voucher_types/${id}`),
    deleteVoucherType: (id: number) => deleteRequest(`admin/voucher_types/${id}`),
    createVoucherParticular: (body: VoucherTypeDTO) => postRequest(`admin/voucher_particulars`, body),
    updateVoucherParticular: (id: number, body: any) => putRequest(`admin/voucher_particulars/${id}`, body),
    getVoucherParticulars: (params: any) => getRequest(`admin/voucher_particulars`, params),
    getVoucherParticularById: (id: number) => getRequest(`admin/voucher_particulars/${id}`),
    deleteVoucherParticular: (id: number) => deleteRequest(`admin/voucher_particulars/${id}`),
    createVoucherTemplateHeader: (body: VoucherTypeDTO) => postRequest(`admin/voucher_template_header`, body),
    updateVoucherTemplateHeader: (id: number, body: any) => putRequest(`admin/voucher_template_header/${id}`, body),
    getVoucherTemplateHeaders: (params: any) => getRequest(`admin/voucher_template_header`, params),
    getVoucherTemplateHeaderById: (id: number) => getRequest(`admin/voucher_template_header/${id}`),
    deleteVoucherTemplateHeader: (id: number) => deleteRequest(`admin/voucher_template_header/${id}`),
    createVoucherTemplateBody: (body: VoucherTypeDTO) => postRequest(`admin/voucher_template_body`, body),
    updateVoucherTemplateBody: (id: number, body: any) => putRequest(`admin/voucher_template_body/${id}`, body),
    getVoucherTemplateBodies: (params: any) => getRequest(`admin/voucher_template_body`, params),
    getVoucherTemplateBodyById: (id: number) => getRequest(`admin/voucher_template_body/${id}`),
    deleteVoucherTemplateBody: (id: number) => deleteRequest(`admin/voucher_template_body/${id}`),
    createFinanceApplication: (body: VoucherTypeDTO) => postRequest(`admin/acc_applications`, body),
    updateFinanceApplication: (id: number, body: any) => putRequest(`admin/acc_applications/${id}`, body),
    getFinanceApplications: (params: any) => getRequest(`admin/acc_applications`, params),
    getFinanceApplicationById: (id: number) => getRequest(`admin/acc_applications/${id}`),
    deleteFinanceApplication: (id: number) => deleteRequest(`admin/acc_applications/${id}`),
    createApplicationTemplateLink: (body: VoucherTypeDTO) => postRequest(`admin/acc_application_template_link`, body),
    updateApplicationTemplateLink: (id: number, body: any) => putRequest(`admin/acc_application_template_link/${id}`, body),
    getApplicationTemplateLinks: (params: any) => getRequest(`admin/acc_application_template_link`, params),
    getApplicationTemplateLinkById: (id: number) => getRequest(`admin/acc_application_template_link/${id}`),
    deleteApplicationTemplateLink: (id: number) => deleteRequest(`admin/acc_application_template_link/${id}`),
    getReferencePrograms: (params: any) => getRequest(`admin/reference/programs`, params),
    createFineSlot: (body: VoucherTypeDTO) => postRequest(`admin/fine_slots`, body),
    updateFineSlot: (id: number, body: any) => putRequest(`admin/fine_slots/${id}`, body),
    getFineSlots: (params: any) => getRequest(`admin/fine_slots`, params),
    getFineSlotById: (id: number) => getRequest(`admin/fine_slots/${id}`),
    deleteFineSlot: (id: number) => deleteRequest(`admin/fine_slots/${id}`),
    createAccInstallment: (body: VoucherTypeDTO) => postRequest(`admin/acc_installments`, body),
    updateAccInstallment: (id: number, body: any) => putRequest(`admin/acc_installments/${id}`, body),
    getAccInstallments: (params: any) => getRequest(`admin/acc_installments`, params),
    getAccInstallmentById: (id: number) => getRequest(`admin/acc_installments/${id}`),
    deleteAccInstallment: (id: number) => deleteRequest(`admin/acc_installments/${id}`),
    createAccInstallmentSlot: (body: VoucherTypeDTO) => postRequest(`admin/acc_installment_slot`, body),
    updateAccInstallmentSlot: (id: number, body: any) => putRequest(`admin/acc_installment_slot/${id}`, body),
    getAccInstallmentSlots: (params: any) => getRequest(`admin/acc_installment_slot`, params),
    getAccInstallmentSlotById: (id: number) => getRequest(`admin/acc_installment_slot/${id}`),
    deleteAccInstallmentSlot: (id: number) => deleteRequest(`admin/acc_installment_slot/${id}`),
    createAccSlotInstallmentParticular: (body: VoucherTypeDTO) => postRequest(`admin/acc_installment_slot_details`, body),
    updateAccSlotInstallmentParticular: (id: number, body: any) => putRequest(`admin/acc_installment_slot_details/${id}`, body),
    getAccSlotInstallmentParticulars: (params: any) => getRequest(`admin/acc_installment_slot_details`, params),
    getAccSlotInstallmentParticularById: (id: number) => getRequest(`admin/acc_installment_slot_details/${id}`),
    deleteAccSlotInstallmentParticular: (id: number) => deleteRequest(`admin/acc_installment_slot_details/${id}`),
    getChallanStatuses: (params: any) => getRequest(`admin/challan/status`, params),
    masterBookListing: (params: any) => getRequest(`admin/acc_master_book`, params),
    getMasterBookLinkedParticulars: (transactionId: number, params: any) => getRequest(`admin/acc_master_book/particulars/${transactionId}`, params),
    updateMasterBookTransactionDetail: (body: BankTransactionDTO) => postRequest(`admin/transaction_detail/update`, body),
    deleteMasterBookLinkedParticular: (transactionId: number) => deleteRequest(`admin/acc_master_book/particulars/delete/${transactionId}`),
    updateTransactionDiscountAdjustment: (body: BankDiscountAdjustmentDTO) => postRequest(`admin/transaction_detail/adjustment`, body),
}