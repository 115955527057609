import { FC, useEffect, useState } from "react";
import { AllocateCourseRoomsMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import useAcademics from "containers/private/academics/useHooks";
import { AddCourseRoomDTO } from "utils/helpers/models/academics/academic-session.dto";
import { warningToaster } from "utils/helpers/common/alert-service";
interface AllocateCourseRoomsProps {
  setOpen: Function;
  course_id: any;
}

const AllocateCourseRooms: FC<AllocateCourseRoomsProps> = ({
  setOpen,
  course_id,
}) => {
  const [rooms, setRooms] = useState<any[]>([]);
  const { getRooms, addCourseRoom } = useAcademics();
  const [formData, setFormData] = useState<AddCourseRoomDTO>({...new AddCourseRoomDTO(), course_id})

  const onSubmit = () => {
    if(!formData.room_id){
      warningToaster("Please select the room");
    }else{
      addCourseRoom(formData, setOpen)
    }
  }

  useEffect(() => {
    getRooms(setRooms);
  }, []);

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setFormData({...formData, [name] : value});
  }

  return (
    <AllocateCourseRoomsMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Allocate Course Rooms</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form>
            <div className="common-fields">
              <div className="input-field">
                <label>Course Rooms</label>
                <div className="field-wrap">
                  <div className="field">
                    <select value={formData.room_id} onChange={handleChange} name="room_id">
                      <option value="">Select Course Rooms</option>
                      {rooms.map((room,index) => {
                        return <option value={room.id} key={index}>{room.room_name}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="button"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
                <button className="lg-rounded-btn" type="button" onClick={onSubmit}>Save & Close</button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </AllocateCourseRoomsMain>
  );
};

export default AllocateCourseRooms;
