import ApplicantEnrollment from 'containers/private/hostel/applicant-enrollment';
import CreateApplicant from 'containers/private/hostel/applicant-management/create';
import ApplicantListing from 'containers/private/hostel/applicant-management/listing';
import AppliedApplicant from 'containers/private/hostel/applied-applicant';
import FinanceManagement from 'containers/private/hostel/finance-managment';
import HostelApplicantMeritList from 'containers/private/hostel/hostel-applicant-merit-list';
import HostelCreate from 'containers/private/hostel/hostel-management/create';
import HostelManagement from 'containers/private/hostel/hostel-management/listing/Index';
import HostelRoomCreate from 'containers/private/hostel/hostel-room-management/create';
import RoomListing from 'containers/private/hostel/hostel-room-management/listing/Index';
import CreateMerit from 'containers/private/hostel/merit-management/create';
import MeritListing from 'containers/private/hostel/merit-management/listing';
import RegisterdApplicant from 'containers/private/hostel/registered-applicant';
import CreateSession from 'containers/private/hostel/session-management/create';
import SessionListing from 'containers/private/hostel/session-management/listing';
import useUtils from 'hooks/useUtils';
import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { siteRoutes } from 'utils/helpers/enums/routes.enum';

const HostelRoutes: FC = () => {
    const { getPathToSetRoute } = useUtils();
    return (
        <Routes>
            <Route path={getPathToSetRoute(siteRoutes.hostelListing, true)} Component={HostelManagement} />
            <Route path={getPathToSetRoute(siteRoutes.createHostel, true)} Component={HostelCreate} />
            <Route path={getPathToSetRoute(siteRoutes.hostelRoomsListing, true)} Component={RoomListing} />
            <Route path={getPathToSetRoute(siteRoutes.createHostelRoom, true)} Component={HostelRoomCreate} />
            <Route path={getPathToSetRoute(siteRoutes.applicantListing, true)} Component={ApplicantListing} />
            <Route path={getPathToSetRoute(siteRoutes.createApplicant, true)} Component={CreateApplicant} />
            <Route path={getPathToSetRoute(siteRoutes.hostelMeritListing, true)} Component={MeritListing} />
            <Route path={getPathToSetRoute(siteRoutes.createHostelMerit, true)} Component={CreateMerit} />
            <Route path={getPathToSetRoute(siteRoutes.hostelSessionListing, true)} Component={SessionListing} />
            <Route path={getPathToSetRoute(siteRoutes.createHostelSession, true)} Component={CreateSession} />
            <Route path={getPathToSetRoute(siteRoutes.hostelFinanceManagement)} Component={FinanceManagement} />
            <Route path={getPathToSetRoute(siteRoutes.appliedApplicant)} Component={AppliedApplicant} />
            <Route path={getPathToSetRoute(siteRoutes.applicantEnrollment)} Component={ApplicantEnrollment} />
            <Route path={getPathToSetRoute(siteRoutes.registeredApplicant)} Component={RegisterdApplicant} />
            <Route path={getPathToSetRoute(siteRoutes.hostelApplicantMeritList)} Component={HostelApplicantMeritList} />
        </Routes>
    )
}

export default HostelRoutes;