export class AddMeritListAutomationDTO{
    title: string = '';
    program_title_filter: string = '';
    offer_date: string = '';
    expiry_date: string = '';
    link_slots: string = '';
    ml: string = '';
    minimum_apps_to_lock: string = '';
    instructions: string = '';
    quotas: number[] = [];
}