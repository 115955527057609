import { FC, useState } from "react";
import {
  CreateSetHolidaysDiv,
  CreateSetHolidaysMain,
  CreateSetHolidaysTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { DateRangePicker } from "react-date-range";  

const CreateSetHolidays: FC = () => {
  const breadcrumbLinks = [
    { title: "HR  Management/", path: "" },
    {
      title: "Holidays /",
      path: siteRoutes.setHolidaysListing,
    },
    {
      title: "Set Holidays",
      path: siteRoutes.createSetHolidays,
    },
  ];
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleSelect = (ranges: any) => {
    const { startDate, endDate } = ranges.selection;
    setSelectedRange({ startDate, endDate });
  };

  // Date formatting
  const formattedStartDate = format(selectedRange.startDate, "MM/dd/yyyy");
  const formattedEndDate = format(selectedRange.endDate, "MM/dd/yyyy");

  const selectionRange = {
    startDate: selectedRange.startDate,
    endDate: selectedRange.endDate,
    key: "selection",
  };

  return (
    <CreateSetHolidaysMain>
      <CreateSetHolidaysTop>
        <div className="heading">
          <span className="page-heading">Set Holidays</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateSetHolidaysTop>

      <CreateSetHolidaysDiv className="content-radius-shadow">
        <div className="common-fields">
          <div className="input-field">
            <label>Title</label>
            <div className="field-wrap">
              <div className="field">
                <input type="text" />
              </div>
            </div>
          </div>
        </div>
        <DateRangePicker
          ranges={[selectionRange]}
          onChange={handleSelect}
          staticRanges={[]}
          inputRanges={[]}
        />
      </CreateSetHolidaysDiv>
    </CreateSetHolidaysMain>
  );
};

export default CreateSetHolidays;
