import { FC, useState } from "react";
import {
  EditQuotaListProgramMain,
  EditQuotaListProgramContainer,
  EditQuotaListProgramWrapper,
} from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { AddQuotaProgramLinkDTO } from "utils/helpers/models/admissions/add-quota-program-link.dto";
import useAdmissions from "containers/private/admissions/useHooks";
import useUtils from "hooks/useUtils";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
interface FormErrorMessageProps {
  error: any;
  touched?: boolean | undefined;
}
interface CreateQoutaListProgramProps {
  setOpenEditModal: (open: boolean) => void;
  headerId: number;
  item: any;
}

const EditQoutaListProgram: FC<CreateQoutaListProgramProps> = ({
  setOpenEditModal,
  headerId,
  item
}) => {
  const [formData, setFormData] = useState<AddQuotaProgramLinkDTO>({
    ...new AddQuotaProgramLinkDTO(),
  });
  // const {
  //   handleSubmit,
  //   register,
  //   formState: { errors },
  //   setValue,
  // } = useForm<AddQuotaProgramLinkDTO>();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<AddQuotaProgramLinkDTO>({
    defaultValues: formData,
    mode: "onChange",
  });

  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const { editProgramLinkQuota } = useAdmissions();

  console.log("items from page edit quota", item)

  const handleCloseModal = () => {
    setOpenEditModal(false);
  };
  const onSubmit = async () => {
    try {
      await editProgramLinkQuota(headerId, formData);
      handleCloseModal();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleChange = (event: any) => {

    const { value, name } = event.target;
    setFormData({ ...formData, [name]: value });

  };


  return (
    <EditQuotaListProgramMain>
      <EditQuotaListProgramContainer>
        <EditQuotaListProgramWrapper className="p-custom-scrollbar-8">

          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              {/* <span>Edit Seats for {quota?.title}</span> */}
            </div>
            <div className="close-icon cp" onClick={handleCloseModal}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="common-fields">
              <div className="input-field">
                <label>Quota Seats</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="number"
                      placeholder="Quota Seats"
                      // {...register("quota_seats", { required: true })}
                      // value={formData.quota_seats | item?.quota_seats}
                      // onChange={handleChange}


                      {...register("quota_seats", { required: true })}
                      value={formData?.quota_seats}


                    />
                  </div>
                </div>
              </div>
              {/* <div className="input-field">
                <label>Accounts</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="number"
                      placeholder="app id."
                      {...register("acc_app_id", { required: true })}
                      value={formData.acc_app_id}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div> */}


              {/* <div className="input-field">
                <label>Application</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      {...register("acc_app_id", { required: true })}
                      value={formData?.acc_app_id as string}
                      onChange={handleChange}
                    >
                      <option value="">Select Type</option>
                      <option value="3">Admission Processing Fee (BS &amp; MSc)</option>
                      <option value="5">MA/MSC Admission Fee</option>
                      <option value="9">PHd Admission Fee 2024</option>
                      <option value="14">GAT Test PHD</option>
                      <option value="15">Admission Test (Under Graduate)</option>
                      <option value="16">Admission Processing Fee (M.Phil / MS)</option>
                    </select>

                  </div>
                  {errors.acc_app_id && <FormErrorMessage error="app id reqired" />}

                </div>
              </div>

              <div className="input-field">
                <label>Voucher Templates</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      {...register("acc_voucher_id", { required: true })}
                      value={formData?.acc_voucher_id as string}
                      onChange={handleChange}
                    >
                      <option value="">Select Voucher Template</option>
                      <option value="3">Admissions</option>
                      <option value="4">Transport Application</option>
                      <option value="5">Hostel Portal</option>
                      <option value="6">Job Portal</option>
                      <option value="9">Testing Service</option>
                      <option value="10">Semester Fee</option>
                      <option value="12">Course Repeat</option>
                      <option value="13">Online EPortal Challan</option>
                      <option value="15">Transport</option>
                      <option value="16">Short Courses</option>

                    </select>

                  </div>

                  {errors.acc_voucher_id && <FormErrorMessage error="voucher id reqired" />}

                </div>

              </div> */}

            </div>
            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="button"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
                <button
                  className="lg-rounded-btn"
                  type="button"
                  onClick={onSubmit}
                >
                  Save & Close
                </button>
              </div>
            </div>
          </form>
        </EditQuotaListProgramWrapper>
      </EditQuotaListProgramContainer>
    </EditQuotaListProgramMain>
  );
};

export default EditQoutaListProgram;
