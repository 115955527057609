import { FC, useEffect, useState } from "react";
import { CreateCommonCoursesPoolForm, CreateCommonCoursesPoolMain, CreateCommonCoursesPoolTop } from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { useForm } from "react-hook-form";
import useFinance from "containers/private/finance/useHooks";
import useUtils from "hooks/useUtils";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { CoursePoolHeaderDTO } from "utils/helpers/models/academics/course-pool-header.dto";
import useAcademics from "../../useHooks";

interface CreateCommonCoursesPoolProps { }

const CreateCommonCoursesPool: FC<CreateCommonCoursesPoolProps> = ({ }) => {
    const breadcrumbLinks: BreadcrumbLink[] = [
        { title: 'Academics / ', path: siteRoutes.academicSessionListing },
        { title: 'Common Courses Pools /', path: siteRoutes.commonCoursesPoolsListing },
        { title: 'Common Courses Pools', path: siteRoutes.createCommonCoursesPools }
    ]
    const { handleSubmit, register, setValue, trigger, formState: { errors } } = useForm<any>();
    const [formData, setFormData] = useState<CoursePoolHeaderDTO>(new CoursePoolHeaderDTO());
    const { createPoolCourseHeader, updatePoolCourseHeader, getPoolCourseHeaderById } = useAcademics();
    const { getQueryParams } = useUtils();
    const params = getQueryParams();

    const onSubmit = () => {
        if (params?.id) {
            updatePoolCourseHeader(params?.id, formData);
        } else {
            createPoolCourseHeader(formData);
        }
    }

    const handleChange = (event: any) => {
        const { value, name } = event.target;
        setValue(name, value);
        trigger(name);
        setFormData({ ...formData, [name]: value });
    }

    const resetForm = () => {
        formData.title = '';
        for (let key in formData) {
            setValue(key, (formData as any)[key]);
        }

        setFormData({ ...formData });
    }

    useEffect(() => {
        if (params?.id) {
            getPoolCourseHeaderById(params?.id, formData, setValue, setFormData);
        }
    }, []);

    return (
        <CreateCommonCoursesPoolMain>
            <CreateCommonCoursesPoolTop>
                <div className="heading">
                    <span className="page-heading">{params?.id ? 'Update' : 'Add'} Pool Header </span>
                    {!params?.id ? <Breadcrumb links={breadcrumbLinks} /> : ''}
                </div>
            </CreateCommonCoursesPoolTop>
            <CreateCommonCoursesPoolForm className="content-radius-shadow" onSubmit={handleSubmit(onSubmit)}>
                <div className="common-fields">
                    <div className="input-field">
                        <label htmlFor="application">Title</label>
                        <div className="field-wrap">
                            <div className="field">
                                <input type="text" placeholder="Enter Title" {...register('title', { required: true })} value={formData?.title} onChange={handleChange} />
                            </div>
                            <FormErrorMessage error={errors?.title} />
                        </div>
                    </div>
                </div>
                <div className="action-buttons">
                    <div className="buttons">
                        <button
                            className="lg-rounded-btn gray"
                            type="button"
                            onClick={resetForm}
                        >
                            Reset
                        </button>
                        <button className="lg-rounded-btn">
                            {params?.id ? 'Update' : 'Save'}
                        </button>
                    </div>
                </div>
            </CreateCommonCoursesPoolForm>
        </CreateCommonCoursesPoolMain>
    )
}

export default CreateCommonCoursesPool;