import { FC, Fragment, useEffect, useState } from "react";
import {
  AcademicAccountStatementsSection,
  AcademicAccountStatementsMain,
  AcademicAccountStatementsTop,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import {
  DownloadTableActionSvg,
  UploadGreenTableSvg,
  ViewDocumentTableSvg,
} from "assets/images/common/svgs";

const AcademicAccountStatements: FC = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Student Dashboard  / ", path: "" },
    { title: "Account Statement ", path: siteRoutes.academicAccountStatements },
  ];

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const columns: string[] = [
    "Voucher Title",
    "Challan #",
    "Date",
    "Description",
    "Amount",
  ];
  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
  };

  return (
    <AcademicAccountStatementsMain>
      <AcademicAccountStatementsTop>
        <div className="left">
          <span className="page-heading">Account Statement</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right"></div>
      </AcademicAccountStatementsTop>

      <AcademicAccountStatementsSection className="content-radius-shadow">
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1].map(() => {
                return (
                  <tr>
                    <td>Fall 2023 Admission Process Fee</td>

                    <td>
                      <div className="mw-150">2398764320</div>
                    </td>

                    <td>
                      <div className="status">21-12-2024</div>
                    </td>
                    <td>
                      Amount Payable by Student, Dua Date: 23-02-2023, Remarks
                      by Account Office:
                    </td>
                    <td>1000.00</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </AcademicAccountStatementsSection>
    </AcademicAccountStatementsMain>
  );
};

export default AcademicAccountStatements;
