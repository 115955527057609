export class AcademicSectionDTO{
id!: number;
title: string = '';
program_id:string = '';
is_active!: number;
is_first_half!: number;
semester_number!: number;
sequence_number!: number;
Shift: string = '';
enrollment_size!: number;
organizations_id: string = '';
department_id: string = '';
}