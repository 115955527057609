export enum successMessages {
    templateHeaderUpdated = 'Template header updated successfully'
}

export enum errorMessages {
    somethingWentWrong = 'Something Went wrong',
}

export enum warningMessages {
    sessionExpired = 'Your session has expired. Login again',
    confirmationDefaultMsg = 'Are you sure you want to do this?',
    deleteConfirmMsg = 'Are you sure you want to do delete?',
    creditHoursRangeMsg = 'Invalid credit hours range',
    logoRequiredMsg = 'Logo is required',
    boardTypeRequiredMsg = 'Board type is required',
    subjectTypeRequiredMsg = 'Select minimum one subject type',
    startDateLargerThanEndDateMsg = 'Start date must be less than the end date',
    documentRequiredMsg = 'Document is required',
    subjectsRequiredMsg = 'Select atleast one subject',
    programsRequiredMsg = 'Select atleast one program',
    classStartDateAfterFeeDateMsg = 'Class start date should be after the fee due date',
    permissionsRequiredMsg = 'Select atleast one permission'
}