import { FC, Fragment, useEffect, useState } from "react";
import {
  AcademicStudentTranscriptSection,
  AcademicStudentTranscriptMain,
  AcademicStudentTranscriptTop,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const AcademicStudentTranscript: FC = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Student Dashboard  / ", path: "" },
    { title: "Transcript ", path: siteRoutes.academicsStudentTranscript },
  ];

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const columns: string[] = [
    "Course Code",
    "Course Title",
    "Credit Hours",
    "Quality Points",
    "Grades",
    "Remarks",
  ];
  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
  };

  return (
    <AcademicStudentTranscriptMain>
      <AcademicStudentTranscriptTop>
        <div className="left">
          <span className="page-heading">Transcript</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="create-fine-slot-btn">
            <button className="lg-rounded-btn">Download PDF</button>
          </div>
        </div>
      </AcademicStudentTranscriptTop>

      <AcademicStudentTranscriptSection className="content-radius-shadow">
        <div className="data-table">
          <div className="top-heading">First Semester Fall 2021</div>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1].map(() => {
                return (
                  <tr>
                    <td>RIN-4567</td>

                    <td>
                      <div className="mw-150">Advanced Research Methodoloy</div>
                    </td>

                    <td>
                      <div>3(2-1)</div>
                    </td>
                    <td>11</td>
                    <td>A</td>
                    <td></td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td>
                  <div className="result">SGPA</div>
                </td>
                <td>
                  <div className="result">3.66</div>
                </td>
                <td>
                  <div className="result">CGPA</div>
                </td>
                <td>
                  <div className="result">3.89</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </AcademicStudentTranscriptSection>
      <AcademicStudentTranscriptSection className="content-radius-shadow">
        <div className="data-table">
          <div className="top-heading">Second Semester Spring 2022</div>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1].map(() => {
                return (
                  <tr>
                    <td>RIN-4567</td>

                    <td>
                      <div className="mw-150">Advanced Research Methodoloy</div>
                    </td>

                    <td>
                      <div>3(2-1)</div>
                    </td>
                    <td>11</td>
                    <td>A</td>
                    <td></td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td>
                  <div className="result">SGPA</div>
                </td>
                <td>
                  <div className="result">3.66</div>
                </td>
                <td>
                  <div className="result">CGPA</div>
                </td>
                <td>
                  <div className="result">3.89</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </AcademicStudentTranscriptSection>
      <AcademicStudentTranscriptSection className="content-radius-shadow">
        <div className="data-table">
          <div className="top-heading">Third Semester Fall 2022</div>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1].map(() => {
                return (
                  <tr>
                    <td>RIN-4567</td>

                    <td>
                      <div className="mw-150">Advanced Research Methodoloy</div>
                    </td>

                    <td>
                      <div>3(2-1)</div>
                    </td>
                    <td>11</td>
                    <td>A</td>
                    <td></td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td>
                  <div className="result">SGPA</div>
                </td>
                <td>
                  <div className="result">3.66</div>
                </td>
                <td>
                  <div className="result">CGPA</div>
                </td>
                <td>
                  <div className="result">3.89</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </AcademicStudentTranscriptSection>
    </AcademicStudentTranscriptMain>
  );
};

export default AcademicStudentTranscript;
