import styled, { css } from "styled-components";

export const TestingServiceListingMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
  .content-radius-shadow {
    box-shadow: 0px 4px 70px 0px #0000001a;
    border-radius: 20px;
    background: var(--white-color);
    padding: 2em;
  }
`;

export const TestingServiceListingSection = styled.div<{
  isTableOverflowing: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em;
  }
  .table-heading {
    color: var(--black-text);
    font-size: 18px;
    font-weight: 700;
  }
  .info-div {
    margin-top: 1rem;
  }

  .table-info {
    color: var(--medium-red);
    font-size: 16px;
    font-weight: 400;
  }

  .list-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      gap: 1em;
    }
  }

  table td {
    .org-logo {
      width: 38px;
      height: 35px;
      background: var(--lightgray-extralight);
    }

    .mw-150 {
      min-width: 150px;
    }

    .expanded-content {
      display: flex;
      gap: 0.7em;
      flex-wrap: wrap;

      .action-button {
        button {
          height: 35px;
          padding: 0 1em;
          background: var(--primary);
          border-radius: 8px;
          color: var(--white-constant);
          font-size: 14px;
          font-weight: 700;

          &.red {
            background-color: var(--light-medium-crimson);
          }
        }
      }
    }
  }
  .action-menu {
    width: 30px;
    position: relative;

    .menu-icon {
      .icon {
        rect {
          fill: var(--primary);
        }
      }
    }
  }
  .content-radius-shadow {
    box-shadow: 0px 4px 70px 0px #0000001a;
    border-radius: 20px;
    background: var(--white-color);
    padding: 2em;
  }
`;

export const TestingServiceListingTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  @media screen and (max-width: 490px) {
    flex-direction: column;
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }

  .right {
    @media screen and (max-width: 490px) {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
`;
export const TestWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1.5em;
  flex-wrap: wrap;

  /* @media screen and (max-width: 1350px){
      flex-wrap: wrap;
  } */
  @media screen and (max-width: 1032px) {
    justify-content: center;
  }
`;

export const TestCard = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  border: 1px solid var(--field-border);
  border-radius: 10px;
  padding-bottom: 1.5em;

  @media screen and (max-width: 520px) {
    width: 100%;
  }

  .card-header {
    width: 100%;
    padding: 0.5em 0;
    background: var(--primary-extralight);
    display: flex;
    justify-content: center;
    border-radius: 10px 10px 0px 0px;

    .header-text {
      width: 180px;
      display: flex;
      flex-direction: column;
      color: var(--primary);
      font-size: 16px;
      font-weight: 500;

      @media screen and (max-width: 630px) {
        font-size: 14px;
      }
      span {
        text-align: center;
      }
    }
  }

  .info-section {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding-left: 1.5em;

    .info {
      display: flex;
      gap: 0.5em;
      align-items: center;
      border-bottom: 1px solid var(--lightgray-light);
      width: 92%;
      .title {
        font-size: 16px;
        font-weight: 500;
        color: var(--black-text);

        @media screen and (max-width: 520px) {
          font-size: 13px;
        }
      }
      .detail {
        font-size: 15px;
        font-weight: 400;
        color: var(--lightgray-medium);

        @media screen and (max-width: 520px) {
          font-size: 12px;
        }
      }
    }
  }

  .apply-button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 1em;

    @media screen and (max-width: 520px) {
      padding: 1em 1em 0 1em;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.5em;
      padding: 0 3em;

      @media screen and (max-width: 520px) {
        width: 100%;
        height: 40px;
      }
    }
  }
`;

export const TestListingSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;

  .heading {
    span {
      color: var(--black-text);
      font-size: 24px;
      font-weight: 500;

      @media screen and (max-width: 630px) {
        font-size: 20px;
      }
      @media screen and (max-width: 490px) {
        font-size: 18px;
      }
    }
  }
`;
