import { FC, useEffect, useState } from "react";
import { UploadStudentFileMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg, SmallUploadSvg } from "assets/images/common/svgs";
import { AddCourseSectionDTO } from "utils/helpers/models/academics/academic-session.dto";
import { useForm } from "react-hook-form";
import useAcademics from "containers/private/academics/useHooks";
import { AcademicSectionDTO } from "utils/helpers/models/academics/academic-section.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";

interface AddAllocateSectionProps {
  setOpen: Function;
}

const UploadStudentFile: FC<AddAllocateSectionProps> = ({ setOpen }) => {
  return (
    <UploadStudentFileMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Upload Student File</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form>
            <div className="common-fields">
              <div className="upload-field">
                <label>Upload Student File</label>
                <div className="field-wrapper">
                  <label className="file-name-section" htmlFor="certificate">
                    <div className="inner-content">
                      <div className="upload-text">
                        <div className="upload-icon">
                          <SmallUploadSvg className="icon" />
                        </div>
                        <span className="text">Upload Student File</span>
                      </div>
                      <div className="upload-restrictions">
                        select jpg/png image with maximum size of 900 KB
                      </div>
                    </div>
                  </label>
                  <input type="file" className="d-none" id="certificate" />
                </div>
              </div>
            </div>
            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="button"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
                <button className="lg-rounded-btn">Save & Close</button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </UploadStudentFileMain>
  );
};

export default UploadStudentFile;
