import styled from "styled-components";

export const AcademicStudentPlanOfStudiesMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;
export const AcademicStudentPlanOfStudiesTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  @media screen and (max-width: 490px) {
    flex-direction: column;
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }

  .right {
    @media screen and (max-width: 490px) {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
`;

export const AcademicStudentPlanOfStudiesSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em;
  }

  .list-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      gap: 1em;
    }
  }

  table td {
    .mw-150 {
      min-width: 150px;
    }
  }
  .top-heading {
    color: var(--black-text);
    font-size: 24px;
    font-weight: 500;
    border-bottom: 1px solid var(--lightgray-extralight);
    padding-bottom: 0.2em;
  }
`;
export const FilterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em 3em;

  @media screen and (max-width: 630px) {
    padding: 1em 1.5em;
  }
  .stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .sats-item {
    display: flex;
    flex-direction: column;
  }
  .stats-title {
    font-size: 16px;
    font-weight: 400;
    color: var(--black-text);
    border-bottom: 1px solid var(--lightgray-medium-light);
    width: 100%;
    padding-bottom: 4px;
  }
  .stats-value {
    padding-top: 10px;
    color: var(--black-text);
    font-weight: 400;
    font-size: 15px;
  }

  .table-radio-field {
    display: flex;
    gap: 15px;
    align-items: center;
    padding-top: 4px;
    .radio label {
      padding-right: 5px;
    }
  }
  .top-heading {
    color: var(--black-text);
    font-size: 24px;
    font-weight: 500;
    border-bottom: 1px solid var(--lightgray-extralight);
    padding-bottom: 0.2em;
  }
`;
