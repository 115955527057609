import { FC, useEffect, useState } from "react";
import Breadcrumb from "components/particles/breadcrumb";
import { CreateProgramMain, CreateProgramTopSection, Form } from "./style";
import { useForm } from "react-hook-form";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import useUtils from "hooks/useUtils";
import { AddProgramDTO } from "utils/helpers/models/organization/add-program.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useOrganization from "../../useHooks";
import { warningToaster } from "utils/helpers/common/alert-service";
import { warningMessages } from "utils/helpers/enums/messages.enum";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const CreateProgram: FC = () => {
  const breadcrumbLinks = [
    {
      title: "Academics /",
      path: siteRoutes.programListing,
    },
    {
      title: "Create Program",
      path: siteRoutes.createProgram,
    },
  ];
  let [formData, setFormData] = useState<AddProgramDTO>(new AddProgramDTO());
  const [certificateLevels, setCertificateLevels] = useState<any[]>([]);
  const [structureTypes, setStructureTypes] = useState<any[]>([]);
  const [currentStructureType, setCurrentStructureType] = useState<any>();
  const [structuredepartment, setStructureDepartment] = useState<any>();
  const [orgStructures, setOrgStructures] = useState<any[]>([]);
  const [dptStructures, setDepStructures] = useState<any[]>([]);

  const {
    createProgram,
    getStructureTypesAdmin,
    getProgramById,
    getCertificateLevelsAdmin,
    updateProgram,
    getOrgStructures,
  } = useOrganization();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    register,
    getValues,
  } = useForm<AddProgramDTO>({ defaultValues: formData });

  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const creditHoursChange = (value: any) => {
    setFormData({
      ...formData,
      min_credit_hrs: value[0],
      max_credit_hrs: value[1],
    });
    setValue("min_credit_hrs", value[0]);
    setValue("max_credit_hrs", value[1]);
  };

  const onSubmit = (formData: any, addMore: boolean = false) => {
    const { min_credit_hrs, max_credit_hrs } = formData;
    if (min_credit_hrs && max_credit_hrs) {
      if (params?.id) {
        updateProgram(params?.id, formData);
      } else {
        createProgram(formData, addMore, resetForm);
      }
    } else {
      warningToaster(warningMessages.creditHoursRangeMsg);
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    
    setFormData({
      ...formData,
      [name]: value
    });

    if (name === "cat_type_id") {
      setFormData({
        ...formData,
        [name]: value,
        cat_id: "",
      });
      setValue("cat_id", "");
      setValue("parent_id", "");
      getOrgStructures(setOrgStructures, { categories_types_id: value, per_page: "All" });
      setDepStructures([]);
    } else if (name === "cat_id") {
      setValue("parent_id", "");
      getOrgStructures(setDepStructures, { parent_id: value, per_page: "All" });
    }
    else {
      setValue(name, value);
    }

  };

  useEffect(() => {
    getStructureTypesAdmin(setStructureTypes);
    getCertificateLevelsAdmin(setCertificateLevels);
    if (params?.id) {
      getProgramById(
        params?.id,
        getValues,
        setValue,
        setFormData,
        setOrgStructures,
        setDepStructures
      );
    }
  }, []);

  useEffect(() => {
    if (formData.cat_type_id) {
      const structureType = structureTypes.find(
        (item: any) => item.id == formData.cat_type_id
      );
      setCurrentStructureType(structureType);
    }

    if (formData.cat_id) {
      const department = structureTypes.find(
        (item: any) => item.id == formData.cat_id
      );
      setStructureDepartment(department);
    }
  }, [formData.cat_type_id, structureTypes, formData]);

  const resetForm = () => {
    setFormData(new AddProgramDTO());
    creditHoursChange([0, 20]);
    setValue("min_credit_hrs", 0);
    setValue("max_credit_hrs", 20);
  };

  console.log("dptStructures", dptStructures[0]?.type?.title);

  return (
    <CreateProgramMain>
      <CreateProgramTopSection>
        <span className="page-heading">{params?.id ? "Update" : "Add"} Program</span>
        {!params?.id && <Breadcrumb links={breadcrumbLinks} />}
      </CreateProgramTopSection>

      <Form
        className="content-radius-shadow"
        onSubmit={handleSubmit((data: AddProgramDTO) => onSubmit(data))}
      >
        <div className="common-fields">

          <div className="input-field">
            <label>Program Level</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("level_id", { required: true })}
                  value={formData.level_id}
                  onChange={handleChange}
                >
                  <option value="">Select Program Level</option>
                  {certificateLevels.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
              <FormErrorMessage error={errors.level_id} />
            </div>
          </div>

          <div className="input-field">
            <label>Program Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  placeholder="Program Name"
                  {...register("title", { required: true })}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.title} />
            </div>
          </div>

          {/* Select Structure Type */}
          <div className="input-field">
            <label>Select Structure Type</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("cat_type_id", { required: true })}
                  value={formData.cat_type_id}
                  onChange={handleChange}
                >
                  <option value="">Select Structure Type</option>
                  {structureTypes.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Dynamic Dropdown for Category */}
          {
            currentStructureType && (
              <div className="input-field">
                <label>Select {currentStructureType.title}</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      {...register("cat_id", { required: true })}
                      value={formData.cat_id}
                      onChange={handleChange}
                    >
                      <option value="">Select {currentStructureType.title}</option>
                      {orgStructures.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <FormErrorMessage error={errors.cat_id} />
                </div>
              </div>
            )
          }

          {
            dptStructures[0]?.type?.title && (
              <div className="input-field">
                <label>Select {dptStructures[0]?.type?.title}</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      {...register("parent_id", { required: true })}
                      value={formData.parent_id}
                      onChange={handleChange}
                    >
                      <option value="">Select {dptStructures[0]?.type?.title}</option>
                      {dptStructures.map((item: any, index: number) => (
                        <option key={index} value={item?.id}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <FormErrorMessage error={errors.parent_id} />
                </div>
              </div>
            )
          }


          <div className="input-field">
            <label>Credit hours (minimum and maximum)</label>
            <div className="field-wrap">
              <div className="slider-field">
                <div className="value-display">
                  <div className="particular-display">{formData.min_credit_hrs}</div>
                  <div className="particular-display">{formData.max_credit_hrs}</div>
                </div>
                <Slider
                  range
                  min={0}
                  max={200}
                  value={[formData.min_credit_hrs, formData.max_credit_hrs]}
                  onChange={creditHoursChange}
                />
              </div>
            </div>
          </div>

          <div className="input-field">
            <label>Semesters</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("semesters", { required: true })}
                  onChange={handleChange}
                >
                  <option value="">Select Semesters</option>
                  <option value={2}>2</option>
                  <option value={4}>4</option>
                  <option value={6}>6</option>
                  <option value={8}>8</option>
                  <option value={10}>10</option>
                </select>
              </div>
              <FormErrorMessage error={errors.semesters} />
            </div>
          </div>

          <div className="input-field">
            <label>Program Shift</label>
            <div className="field-wrap">
              <div className="field">
                <select {...register("program_shift", { required: true })}>
                  <option value="">Select Program Shift</option>
                  <option value="Morning">Morning</option>
                  <option value="Evening">Evening</option>
                  <option value="Afternoon">Afternoon</option>
                </select>
              </div>
              <FormErrorMessage error={errors.program_shift} />
            </div>
          </div>

          <div className="input-field">
            <label>Program Type</label>
            <div className="field-wrap">
              <div className="field">
                <select {...register("program_type", { required: true })}>
                  <option value="">Select Semester/Annual</option>
                  <option value="Semester">Semester</option>
                  <option value="Annual">Annual</option>
                </select>
              </div>
              <FormErrorMessage error={errors.program_type} />
            </div>
          </div>

          <div className="input-field">
            <label>Status</label>
            <div className="field-wrap">
              <div className="field">
                <select {...register("status", { required: true })}>
                  <option value="">Select Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              <FormErrorMessage error={errors.status} />
            </div>
          </div>
        </div>

        <div className="submit-buttons">
          <div className="buttons">
            <button className="lg-rounded-btn gray" type="button" onClick={resetForm}>
              Reset
            </button>
            {!params?.id && (
              <button
                className="lg-rounded-btn spring"
                type="submit"
                onClick={handleSubmit((data) => onSubmit(data, true))}
              >
                Save & Add more
              </button>
            )}
            <button
              className="lg-rounded-btn"
              type="submit"
              onClick={handleSubmit((data) => onSubmit(data))}
            >
              Save & Exit
            </button>
          </div>
        </div>
      </Form>
    </CreateProgramMain>
  );
};

export default CreateProgram;
