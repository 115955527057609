import { FC, useEffect, useState } from "react";
import {
  HeadingDiv,
  ApplicationListSection,
  MainApplicationList,
  ApplicationsWrapper,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  ApplicationBtnIcon,
  ApplyIconSvg,
  FormBuildingSvg,
  InfoIconSvg,
  LastDateIconSvg,
  RedCrossIconSvg,
} from "assets/images/common/svgs";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useEportal from "../../useHooks";
import useUtils from "hooks/useUtils";
import UploadChallan from "./components/upload-challan-popup";

const ApplicationList: FC = () => {
  const breadcrumbLinks = [
    { title: "Eportal /", path: siteRoutes.ePortalDashboard },
    { title: "Application List /", path: siteRoutes.eportalAdmissionsListing },
    {
      title: "Apply for Admissions",
      path: siteRoutes.createEportalAdmissionList,
    },
  ];
  const navigate = useNavigate();
  const { getDateFromDateTime } = useUtils();
  const {
    getStudentApplications,
    admissionGenerateFeeChallan,
    admissionDownloadApplication,
  } = useEportal();
  const [data, setData] = useState<any[]>([]);
  const goToApplyOtherProgram = () => {
    navigate(siteRoutes.createEportalAdmissionList);
  };

  useEffect(() => {
    getStudentApplications(setData);
  }, []);

  const generate = (app_id: number) => {
    admissionGenerateFeeChallan(app_id);
  };

  const downloadApplication = (app_id: number) => {
    admissionDownloadApplication(app_id);
  };

  const [testAppId, setTestAppId] = useState<number>(0);
  const [uploadChallan, setUploadChallan] = useState<boolean>(false);

  const goToUploadChallan = (index: number, app_id: number) => {
    (data as any)[index].isDropdownOpen = false;

    setTestAppId(app_id);
    setUploadChallan(true);
  };

  return (
    <>
      <MainApplicationList>
        <HeadingDiv>
          <div className="left">
            <span className="page-heading">Application List</span>
            <Breadcrumb links={breadcrumbLinks} />
          </div>
          <div className="right">
            <div className="create-btn">
              <button
                className="lg-rounded-btn link-btn"
                onClick={goToApplyOtherProgram}
              >
                <div className="icon">
                  <ApplicationBtnIcon />
                </div>
                Apply Now
              </button>
            </div>
          </div>
        </HeadingDiv>

        <ApplicationsWrapper>
          {data?.map((item: any, index: number) => {
            return (
              <ApplicationListSection key={index}>
                <div className="main-heading-div">
                  <p className="form-heading">{item?.program}</p>
                  <div>
                    <FormBuildingSvg />
                    <span className="city-name">{item?.campus_title}</span>
                  </div>
                </div>
                <div className="sub-heading-div">
                  <p className="sub-heading">{item?.app_no}</p>
                  <div className="apply-date-main-div">
                    <span className="sub-heading">Apply Date</span>
                    <div className="apply-date-div">
                      <ApplyIconSvg />
                      <span className="apply-date">{item?.app_date}</span>
                    </div>
                  </div>
                </div>

                <hr className="line" />

                <div className="info-div">
                  <InfoIconSvg className="info-icon" />
                  <p className="info-para">
                    Based on the information you have provided you appeared to
                    bs eligible for consederation for admission
                  </p>
                </div>

                <div className="uploaded-parent">
                  <div className="status-div">
                    <p className="sub-heading">Application Fee Status</p>
                    <div className="status">
                      <RedCrossIconSvg />
                      <p className="status-para">Not Uploaded</p>
                    </div>
                  </div>

                  <div className="last-date-div">
                    <p className="sub-heading">
                      Last date to upload fee challan
                    </p>
                    <div className="last-date">
                      <LastDateIconSvg />
                      <p className="last-date-para">
                        {getDateFromDateTime(item?.close_date)}
                      </p>
                    </div>
                  </div>
                </div>

                <hr className="line" />

                <div className="action-button">
                  <button
                    type="button"
                    className="download-btn"
                    onClick={() => downloadApplication(item.app_no)}
                  >
                    Download Application
                  </button>
                  <button
                    type="button"
                    className=""
                    onClick={() => generate(item.id)}
                  >
                    Download Fee Challan
                  </button>
                  <button
                    type="button"
                    className="upload-btn"
                    onClick={() => goToUploadChallan(index, item?.app_no)}
                  >
                    Upload Fee Challan
                  </button>
                  <button className="pay-btn">Pay Online</button>
                </div>
              </ApplicationListSection>
            );
          })}
        </ApplicationsWrapper>
      </MainApplicationList>
      {uploadChallan && (
        <UploadChallan app_id={testAppId} setOpen={setUploadChallan} />
      )}
    </>
  );
};

export default ApplicationList;
