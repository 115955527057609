import { FC, useEffect, useState } from "react";
import { ApplyForTestMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { TestingCenterDTO } from "utils/helpers/models/testing-service/testing-center.dto";
import { TestScheduleDTO } from "utils/helpers/models/testing-service/test-schedule.dto";
import { useForm } from "react-hook-form";
import { TestApplicationDTO } from "utils/helpers/models/testing-service/test-application.dto";
import useEportal from "containers/private/e-portal/useHooks";
import FormErrorMessage from "components/particles/forms/form-error-message";
interface ApplyForTestProps {
  test_id: Number
  setOpen: Function;
  testCenters: any;
  testType: any
  phd_programs: any
}

const ApplyForTest: FC<ApplyForTestProps> = ({ test_id, testCenters, testType, phd_programs, setOpen }) => {

  const {
    createTestApplication
  } = useEportal();

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<TestApplicationDTO>();

  let [formData, setFormData] = useState<TestApplicationDTO>(
    new TestApplicationDTO()
  );

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = (data: any, addMore: boolean = false) => {
    createTestApplication(
      { ...formData, sch_id: test_id },
      setOpen()
      // resetForm
    );
  };


  return (
    <ApplyForTestMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Apply for test</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <div className="common-fields">
              <div className="input-field">
                <label>Test Center</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      {...register("test_center_id", { required: true })}
                      value={formData.test_center_id}
                      onChange={handleChange}
                    >
                      <option value="">Select Test Center</option>
                      {testCenters.map((test: any, index: number) => {
                        return (
                          <option value={test.id} key={index}>
                            {test.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <FormErrorMessage error={errors.test_center_id} />
                </div>
              </div>
              {phd_programs && phd_programs.length > 0 && (
                <div className="input-field">
                  <label>Test Type</label>
                  <div className="field-wrap">
                    <div className="field">
                      <select
                        {...register("phd_program_id", { required: false })}
                        value={formData.phd_program_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Test Type</option>
                        {phd_programs.map((test: any, index: number) => {
                          return (
                            <option value={test.id} key={index}>
                              {test.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <FormErrorMessage error={errors.phd_program_id} />
                  </div>
                </div>
              )}

            </div>
            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="button"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
                <button className="lg-rounded-btn" onClick={handleSubmit((data) => onSubmit(data))}>
                Apply & Close
                </button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </ApplyForTestMain>
  );
};

export default ApplyForTest;
