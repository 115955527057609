import { FC, Fragment, useEffect, useState } from "react";
import {
  AcademicHolidaysListingSection,
  AcademicHolidaysListingMain,
  AcademicHolidaysListingTop,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import {
  DownloadTableActionSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  UploadGreenTableSvg,
  ViewDocumentTableSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";

const AcademicHolidaysListing: FC = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Student Dashboard  / ", path: "" },
    { title: "Holiday List ", path: siteRoutes.academicHolidaysList },
  ];

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const columns: string[] = ["Holiday Title", "Date", "Description"];
  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
  };

  return (
    <AcademicHolidaysListingMain>
      <AcademicHolidaysListingTop>
        <div className="left">
          <span className="page-heading">Holiday List</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right"></div>
      </AcademicHolidaysListingTop>

      <AcademicHolidaysListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input type="search" placeholder="Search" />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1].map(() => {
                return (
                  <tr>
                    <td>Happy New Year</td>

                    <td>
                      <div className="mw-150">20-09-2023</div>
                    </td>

                    <td>
                      <div className="status">Here</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </AcademicHolidaysListingSection>
    </AcademicHolidaysListingMain>
  );
};

export default AcademicHolidaysListing;
