import { FC, Fragment, useEffect, useState } from "react";
import {
  AcademicSessionManagementListingSection,
  AcademicSessionManagementListingTop,
  AcademicSessionManagementListingMain,
  FilterSection,
  FilterHeader,
  Filters,
} from "./style";
import {
  DownArrowLightgrayMediumSvg,
  EditTableSvg,
  ExcelSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import AllocateSection from "../components/allocate-section";
import AllocateTeacher from "../components/allocate-teacher";
import GradingSchema from "../components/grading-schema";
import useAcademics from "../../useHooks";
import { useSelector } from "react-redux";
import useStore from "hooks/useStore";
import useUtils from "hooks/useUtils";
import Pagination from "components/particles/table/pagination";
import DataNotFound from "components/particles/table/data-not-found";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import AllocateCourseRooms from "../components/allocate-course-rooms";
import useOrganization from "containers/private/organization/useHooks";
import { AddOrgStructureDTO } from "utils/helpers/models/organization/add-org-structure.dto";
import { TemplateCourseDTO } from "utils/helpers/models/academics/template-course.dto";
import { AcademicSectionDTO } from "utils/helpers/models/academics/academic-section.dto";
import { AcademicStatusDTO } from "utils/helpers/models/academics/academic-status.dto";

interface AcademicSessionManagementListingProps {}

const AcademicSessionManagementListing: FC<
  AcademicSessionManagementListingProps
> = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics/ ", path: siteRoutes.academicSessionListing },
    { title: "Academic Sessions/ ", path: siteRoutes.academicSessionListing },
    {
      title: "Manage Academic Sessions",
      path: siteRoutes.academicSessionManagementListing,
    },
  ];

  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const { getSessionAllocatedCourses, getAcademicStatuses } = useAcademics();
  const { hasAccess } = useStore();
  const [teachers, setTeachers] = useState<any[]>([]);
  const [courses, setCourses] = useState<TemplateCourseDTO[]>([]);
  const [sections, setSections] = useState<AcademicSectionDTO[]>([]);
  const [academicStatuses, setAcademicStatuses] = useState<AcademicStatusDTO[]>(
    []
  );
  const { getOrgStructures } = useOrganization();
  const { getTeachers, getAcademicSections, getTemplateCourses } =
    useAcademics();
  const [orgStructures, setOrgStructures] = useState<AddOrgStructureDTO[]>([]);
  const [filters, setFilters] = useState<any>({
    session_id: "",
    course_id: "",
    campus_id: "",
    department_id: "",
    teacher_id: "",
    teacher_cnic: "",
    section_id: "",
    enrollment_status: "",
  });
  const { handleSearchChange, handleTableSearch, getQueryParams, createQuery } =
    useUtils();
  const { session_id } = getQueryParams();

  const columns: string[] = [
    "",
    "Course Title",
    "Course Code",
    "Lab Status",
    "Class Status",
    "Thesis Status",
    "Credit Hours",
    "Campus",
    "Student Enrolled",
    "Result Status",
    "Course ID",
    "Associated Section",
    "Teacher Name",
    "WorkLoad Type",
    "Action",
  ];
  const [openAllocateSectionModal, setOpenAllocateSectionModal] =
    useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [openAllocateTeacherModal, setOpenAllocateTeacherModal] =
    useState<boolean>(false);
  const [openGradeSchemaModal, setOpenGradeSchemaModal] =
    useState<boolean>(false);
  const [courseId, setCourseId] = useState<number>();
  const [allocateCourseRoom, setAlocateCourseRoom] = useState<boolean>(false);
  const navigate = useNavigate();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [search, setSearch] = useState<string>("");

  const goToGradeSchema = () => {
    setOpenGradeSchemaModal(true);
  };

  const goToGradeBooks = (course_id: number) => {
    navigate(`${siteRoutes.academicGradeBookListing}?course_id=${course_id}`);
  };

  useEffect(() => {
    getAllSessionAllocatedCourses(pagination.page, search, filters);
    getOrgStructures(setOrgStructures);
    getTemplateCourses(setCourses);
    getAcademicSections(setSections);
    getTeachers(setTeachers);
    getAcademicStatuses(setAcademicStatuses);
  }, []);

  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
    getAllSessionAllocatedCourses(page + 1, search, filters);
  };
  const getAllSessionAllocatedCourses = (
    page: number,
    search: string,
    filters: any
  ) => {
    const filtersToSend: any = {};
    for (let key in filters) {
      if (filters[key]) {
        filtersToSend[key] = filters[key];
      }
    }
    const queryParams = {
      per_page: pagination.per_page,
      page,
      // search,
      ...filtersToSend,
      session_id,
    };
    getSessionAllocatedCourses(setData, queryParams, setPagination);
  };

  const toggleRowExpand = (index: number) => {
    const dat = [...data];
    dat[index].isExpanded = !dat[index].isExpanded;
    setData([...dat]);
  };

  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  const handleOpenAllocateSectionModal = (courseId: number) => {
    setCourseId(courseId);
    setOpenAllocateSectionModal(true);
  };
  const handleOpenAllocateTeacherModal = (courseId: number) => {
    setCourseId(courseId);
    setOpenAllocateTeacherModal(true);
  };

  const goToAddCourses = () => {
    navigate(`${siteRoutes.academicCourseListing}?session_id=${session_id}`);
  };
  const handleOpenAllocateRoomModal = (courseId: number) => {
    setCourseId(courseId);
    setAlocateCourseRoom(true);
  };

  const handleFilterChange = (event: any) => {
    const { value, name } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const resetFilters = () => {
    for (let key in filters) {
      filters[key] = "";
    }

    setFilters({ ...filters });
    getAllSessionAllocatedCourses(1, search, filters);
  };

  const [openedDropdownIndex, setOpenedDropdownIndex] = useState<number | null>(
    null
  ); // State to track the open dropdown by index
  const [isTableOpened, setIsTableOpened] = useState(false);
  const toggleDropdown = (index: number) => {
    setOpenedDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    setIsTableOpened((prevState) => !prevState);
  };

  return (
    <AcademicSessionManagementListingMain>
      <AcademicSessionManagementListingTop>
        <div className="left">
          <span className="page-heading">Manage Academic Sessions</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.academicCourseListing) && (
            <div className="create-org-btn">
              <button className="lg-rounded-btn" onClick={goToAddCourses}>
                + Add New Courses
              </button>
            </div>
          )}
        </div>
      </AcademicSessionManagementListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader showFilterDropdown={showFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {showFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label htmlFor="">Campus</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="campus_id"
                      value={filters.campus_id}
                      onChange={handleFilterChange}
                    >
                      <option value="">Select Campus</option>
                      {orgStructures.map((structure, index) => {
                        return (
                          <option value={structure.id} key={index}>
                            {structure.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              {/* <div className="input-field">
                <label htmlFor="">Faculty</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="campus_id" value={filters.campus_id} onChange={handleFilterChange}>
                      <option value="">Select Faculty</option>
                      {orgStructures.map((structure, index) => {
                        return <option value={structure.id} key={index}>{structure.title}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div> */}
              <div className="input-field">
                <label htmlFor="">Department</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="department_id"
                      value={filters.department_id}
                      onChange={handleFilterChange}
                    >
                      <option value="">Select Department</option>
                      {orgStructures.map((structure, index) => {
                        return (
                          <option value={structure.id} key={index}>
                            {structure.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              {/* <div className="input-field">
                <label htmlFor="">Result Status</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="result_status" value={filters.campus_id} onChange={handleFilterChange}>
                      <option value="">Select Result Status</option>
                    </select>
                  </div>
                </div>
              </div> */}
              <div className="input-field">
                <label htmlFor="">Enrollments</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="enrollment_status"
                      value={filters.enrollment_status}
                      onChange={handleFilterChange}
                    >
                      <option value="">Select Enrolment Status</option>
                      {academicStatuses.map((status, index) => {
                        return (
                          <option value={status.id} key={index}>
                            {status.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              {/* <div className="input-field">
                <label htmlFor="">Anomalies</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">Select Anomalies</option>
                    </select>
                  </div>
                </div>
              </div> */}
              <div className="input-field">
                <label htmlFor="">Teacher CNIC</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="teacher_cnic"
                      value={filters.teacher_cnic}
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Teacher</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="teacher_id"
                      value={filters.teacher_id}
                      onChange={handleFilterChange}
                    >
                      <option value="">Select Teacher</option>
                      {teachers.map((teacher, index) => {
                        return (
                          <option
                            value={teacher.id}
                            key={index}
                          >{`${teacher?.teacher_firstname} ${teacher?.teacher_middlename} ${teacher?.teacher_lastname}`}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Associated Section</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="section_id"
                      value={filters.section_id}
                      onChange={handleFilterChange}
                    >
                      <option value="">Select Associated Section</option>
                      {sections.map((section, index) => {
                        return (
                          <option value={section.id} key={index}>
                            {section.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Course ID</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="course_id"
                      value={filters.course_id}
                      onChange={handleFilterChange}
                    >
                      <option value="">Select course</option>
                      {courses.map((course, index) => {
                        return (
                          <option value={course.id} key={index}>
                            {course.tc_title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" onClick={resetFilters}>
                  Reset
                </button>
                <button
                  className="lg-rounded-btn"
                  onClick={() =>
                    getAllSessionAllocatedCourses(1, search, filters)
                  }
                >
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>
      <AcademicSessionManagementListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(e, setSearch, getAllSessionAllocatedCourses)
              }
              onKeyUp={(e) =>
                handleTableSearch(e, getAllSessionAllocatedCourses)
              }
            />
          </div>
        </div>
        <div className={`data-table ${isTableOpened ? "menu-opened" : ""}`}>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return (
                    <th
                      className={
                        index > 6 ? `extra-head extra-head-${index - 6}` : ""
                      }
                    >
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <tr className={`expandable ${item.isExpanded && "opened"}`}>
                      <td>
                        <div
                          className="rounded-expand-button"
                          onClick={() => toggleRowExpand(index)}
                        >
                          <span>{item.isExpanded ? "-" : "+"}</span>
                        </div>
                      </td>
                      <td>
                        <div className="mw-150">{item?.c_title}</div>
                      </td>
                      <td>{item?.c_code ?? "-"}</td>
                      <td>
                        {" "}
                        <div className="status">
                          {item?.template_courses?.tc_is_lab ? (
                            <span className="status-tile green">Active</span>
                          ) : (
                            <span className="status-tile red">Inactive</span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="status">
                          {item?.is_class ? (
                            <span className="status-tile green">Active</span>
                          ) : (
                            <span className="status-tile red">Inactive</span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="status">
                          {item?.template_courses?.tc_is_research_course ? (
                            <span className="status-tile green">Active</span>
                          ) : (
                            <span className="status-tile red">Inactive</span>
                          )}
                        </div>
                      </td>
                      <td>{item?.template_courses?.tc_credit_hours}</td>
                      <td className="extra-col extra-col-1">
                        {" "}
                        {item?.campus?.title}
                      </td>
                      <td className="extra-col extra-col-2">
                        {" "}
                        {item?.enrollments_count}
                      </td>
                      <td className="extra-col extra-col-3">Not Submitted</td>
                      <td className="extra-col extra-col-4">{item?.id}</td>
                      <td className="extra-col extra-col-5">
                        {" "}
                        {item?.sections[0]?.section_details?.title}
                      </td>
                      <td className="extra-col extra-col-6">
                        {" "}
                        {item?.teachers
                          ?.map(
                            (teacher: any) =>
                              `${
                                teacher?.teacher_details?.teacher_firstname ||
                                ""
                              } ${
                                teacher?.teacher_details?.teacher_middlename ||
                                ""
                              } ${
                                teacher?.teacher_details?.teacher_lastname || ""
                              }`
                          )
                          .join(", ")}
                      </td>
                      <td className="extra-col extra-col-7">
                        {" "}
                        {item?.teachers
                          ?.map(
                            (teacher: any) => `${teacher?.course_type?.title}`
                          )
                          .join(", ")}
                      </td>
                      <td className="extra-col extra-col-8">
                        <td className="extra-col extra-col-4">
                          <div className="action-menu">
                            <div
                              className="menu-icon cp"
                              onClick={() => toggleDropdown(index)}
                            >
                              <TabPrimaryActionMenu className="icon" />
                            </div>
                            {/* modal */}
                            {openedDropdownIndex === index && (
                              <div className="table-menu-dropdown">
                                <div className="particular-menu cp">
                                  <span className="title">Edit</span>
                                </div>
                                <div className="particular-menu cp">
                                  <span className="title">
                                    Mannage Enrollments
                                  </span>
                                </div>

                                <div
                                  className="particular-menu cp"
                                  onClick={() =>
                                    handleOpenAllocateSectionModal(item?.id)
                                  }
                                >
                                  <span className="title">
                                    Allocate Section
                                  </span>
                                </div>
                                <div
                                  className="particular-menu cp"
                                  onClick={() =>
                                    handleOpenAllocateTeacherModal(item?.id)
                                  }
                                >
                                  <span className="title">
                                    {" "}
                                    Allocate Teacher
                                  </span>
                                </div>
                                <div className="particular-menu cp">
                                  <span className="title">
                                    {" "}
                                    Enrollments Data (CSV)
                                  </span>
                                </div>

                                <div
                                  className="particular-menu cp"
                                  onClick={() => goToGradeBooks(item?.id)}
                                >
                                  <span className="title">
                                    {" "}
                                    MAnage Gradebooks
                                  </span>
                                </div>
                                <div
                                  className="particular-menu cp"
                                  onClick={goToGradeSchema}
                                >
                                  <span className="title">
                                    {" "}
                                    MAnage Grading Schema
                                  </span>
                                </div>
                                <div
                                  className="particular-menu cp"
                                  onClick={() =>
                                    handleOpenAllocateRoomModal(item?.id)
                                  }
                                >
                                  <span className="title">
                                    {" "}
                                    Course Room Allocation
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                      </td>
                    </tr>

                    {item.isExpanded && (
                      <tr className="expanded-row">
                        <td colSpan={7}>
                          <div className="expanded-content bordered">
                            <div className="particular-info particular-info-1">
                              <span className="title">Campus</span>
                              <span className="info">
                                {item?.campus?.title}
                              </span>
                            </div>
                            <div className="particular-info particular-info-2">
                              <span className="title">Students Enrolled</span>
                              <span className="info">
                                {item?.enrollments_count}
                              </span>
                            </div>
                            <div className="particular-info particular-info-3">
                              <span className="title">Result Status</span>
                              <span className="info">Not Submitted</span>
                            </div>
                            <div className="particular-info particular-info-4">
                              <span className="title">Course ID</span>
                              <span className="info">{item?.id}</span>
                            </div>
                            <div className="particular-info particular-info-5">
                              <span className="title">Associated Sections</span>
                              <span className="info">
                                {item?.sections[0]?.section_details?.title}
                              </span>
                            </div>
                            <div className="particular-info particular-info-6">
                              <span className="title">Teacher Name</span>
                              <span className="info">
                                {item?.teachers
                                  ?.map(
                                    (teacher: any) =>
                                      `${
                                        teacher?.teacher_details
                                          ?.teacher_firstname || ""
                                      } ${
                                        teacher?.teacher_details
                                          ?.teacher_middlename || ""
                                      } ${
                                        teacher?.teacher_details
                                          ?.teacher_lastname || ""
                                      }`
                                  )
                                  .join(", ")}
                              </span>
                            </div>
                            <div className="particular-info particular-info-7">
                              <span className="title">Workload Type</span>
                              <span className="info">
                                {" "}
                                {item?.teachers
                                  ?.map(
                                    (teacher: any) =>
                                      `${teacher?.course_type?.title}`
                                  )
                                  .join(", ")}
                              </span>
                            </div>
                            <div className="particular-info particular-info-8">
                              <span className="title">Action</span>
                              <div className="table-action-buttons">
                                <div className="action-icon">
                                  <EditTableSvg />
                                </div>
                                <div className="table-action-button">
                                  <button className="green">
                                    Manage Enrollments
                                  </button>
                                </div>
                                <div
                                  className="table-action-button black"
                                  onClick={() =>
                                    handleOpenAllocateSectionModal(item?.id)
                                  }
                                >
                                  <button className="black">
                                    Allocate Section
                                  </button>
                                </div>
                                <div
                                  className="table-action-button black"
                                  onClick={() =>
                                    handleOpenAllocateTeacherModal(item?.id)
                                  }
                                >
                                  <button className="black">
                                    Allocate Teacher
                                  </button>
                                </div>
                                <div className="table-action-button black">
                                  <button className="yellow">
                                    Enrollments Data (CSV)
                                  </button>
                                </div>
                                <div
                                  className="table-action-button purple"
                                  onClick={() => goToGradeBooks(item?.id)}
                                >
                                  <button className="purple">
                                    Manage Gradebooks
                                  </button>
                                </div>
                                <div
                                  className="table-action-button purple"
                                  onClick={goToGradeSchema}
                                >
                                  <button className="purple">
                                    View Grading Scheme
                                  </button>
                                </div>
                                <div
                                  className="table-action-button purple"
                                  onClick={() =>
                                    handleOpenAllocateRoomModal(item?.id)
                                  }
                                >
                                  <button className="red">
                                    Course Room Allocation
                                  </button>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
          {openAllocateSectionModal && (
            <AllocateSection
              setOpen={setOpenAllocateSectionModal}
              course_id={courseId}
            />
          )}
          {openGradeSchemaModal && (
            <GradingSchema setOpen={setOpenGradeSchemaModal} />
          )}
          {openAllocateTeacherModal && (
            <AllocateTeacher
              setOpen={setOpenAllocateTeacherModal}
              course_id={courseId}
            />
          )}
          {allocateCourseRoom && (
            <AllocateCourseRooms
              setOpen={setAlocateCourseRoom}
              course_id={courseId}
            />
          )}
        </Fragment>
      </AcademicSessionManagementListingSection>
    </AcademicSessionManagementListingMain>
  );
};

export default AcademicSessionManagementListing;
