import { FC, useRef, useState } from "react";
import {
  AddAllocateTeacherMain,
  Container,
  ContentWrapper,
 
} from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { useForm } from "react-hook-form";
import useUtils from "hooks/useUtils";
import useTestingServices from "containers/private/testing-services/useHooks";
import { TestApplicationDTO } from "utils/helpers/models/testing-service/test-application.dto";

interface AddAllocateTeacherProps {
  setOpen: Function;
  id?: number;
}

const AllocateTeacher: FC<AddAllocateTeacherProps> = ({ setOpen , id}) => {

  const handleCloseModal = () => {
    setOpen(false);
  };

  const { handleSubmit, register, setValue, trigger, formState: { errors } } = useForm<TestApplicationDTO>();
  const formRef = useRef<HTMLFormElement | null>(null);
  let [formData, setFormData] = useState<TestApplicationDTO>(new TestApplicationDTO());

  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const {additionalInformation } = useTestingServices();

  const onSubmit = (data: TestApplicationDTO) => {
    const updatedFormData = { ...data, test_appliction_id: id || params?.test_appliction_id };

    additionalInformation(updatedFormData, id || params?.test_appliction_id);
    handleCloseModal();
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };
  

  return (
    <AddAllocateTeacherMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Additional Information</span>
            </div>
            <div className="close-icon cp" onClick={handleCloseModal}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form ref={formRef} onSubmit={handleSubmit(data => onSubmit(data))}>
           
              <div className="common-fields">
                <div className="input-field">
                  <label>Additional marks</label>
                  <div className="field-wrap">
                    <div className="field">
                      <input  {...register('additional_marks', { required: true })}
                      value={formData.additional_marks}
                      onChange={handleChange}>
                       
                      </input>
                    </div>
                  </div>
                </div>

                <div className="input-field">
                  <label>Remarks</label>
                  <div className="field-wrap">
                    <div className="field">
                      <input {...register('additional_result_remarks', { required: true })}
                      value={formData.additional_result_remarks}
                      onChange={handleChange}></input>
                    </div>
                  </div>
                </div>
              </div>
           
            <div className="action-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" type="reset">
                  Close
                </button>

                <button className="lg-rounded-btn"  onClick={handleSubmit(data => onSubmit(data))}>
                  Save & Close
                </button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </AddAllocateTeacherMain>
  );
};

export default AllocateTeacher;
