import { FC } from "react";
import { SADashboarMain } from "./style";

interface SuperAdminDashboardProps { }

const SuperAdminDashboard: FC<SuperAdminDashboardProps> = ({ }) => {
    return (
        <SADashboarMain></SADashboarMain>
    )
}

export default SuperAdminDashboard;