import { FC, Fragment, useEffect, useState } from "react";
import {
  AcademicStudentPlanOfStudiesSection,
  AcademicStudentPlanOfStudiesMain,
  AcademicStudentPlanOfStudiesTop,
  FilterSection,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import Pagination from "components/particles/table/pagination";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const AcademicStudentPlanOfStudies: FC = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Student Dashboard  / ", path: "" },
    { title: "Plan of Study ", path: siteRoutes.academicsStudentPlanOfStudies },
  ];

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const columns: string[] = [
    "Course Code",
    "Course",
    "Faculty",
    "Credit Hours(Theory -Lab)",
  ];
  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
  };

  return (
    <AcademicStudentPlanOfStudiesMain>
      <AcademicStudentPlanOfStudiesTop>
        <div className="left">
          <span className="page-heading">Plan of Study</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="create-fine-slot-btn">
            <button className="lg-rounded-btn">Download PDF</button>
          </div>
        </div>
      </AcademicStudentPlanOfStudiesTop>
      <FilterSection className="content-radius-shadow">
        <div className="top-heading">Plan of study</div>
        <div className="stats">
          <div className="sats-item">
            <div className="stats-title">Total Plan of Study Credit Hours</div>
            <div className="stats-value">36</div>
          </div>
          <div className="sats-item">
            <div className="stats-title">Total Fulfilled Credit Hours</div>
            <div className="stats-value">24</div>
          </div>
          <div className="sats-item">
            <div className="stats-title">Remaining Credit Hours </div>
            <div className="stats-value">12</div>
          </div>
        </div>
      </FilterSection>

      <AcademicStudentPlanOfStudiesSection className="content-radius-shadow">
        <div className="data-table">
          <div className="top-heading">Semester 1</div>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1].map(() => {
                return (
                  <tr>
                    <td>RIN-4567</td>

                    <td>
                      <div className="mw-150">
                        Advanced Theory of Computation
                      </div>
                    </td>

                    <td>
                      <div className="status">Dr. Wareesha Sharif</div>
                    </td>
                    <td>6(6-0)</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </AcademicStudentPlanOfStudiesSection>
      <AcademicStudentPlanOfStudiesSection className="content-radius-shadow">
        <div className="data-table">
          <div className="top-heading">Semester 2</div>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1].map(() => {
                return (
                  <tr>
                    <td>RIN-4567</td>

                    <td>
                      <div className="mw-150">
                        Advanced Theory of Computation
                      </div>
                    </td>

                    <td>
                      <div className="status">Dr. Wareesha Sharif</div>
                    </td>
                    <td>6(6-0)</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </AcademicStudentPlanOfStudiesSection>
      <AcademicStudentPlanOfStudiesSection className="content-radius-shadow">
        <div className="data-table">
          <div className="top-heading">Semester 3</div>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1].map(() => {
                return (
                  <tr>
                    <td>RIN-4567</td>

                    <td>
                      <div className="mw-150">
                        Advanced Theory of Computation
                      </div>
                    </td>

                    <td>
                      <div className="status">Dr. Wareesha Sharif</div>
                    </td>
                    <td>6(6-0)</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </AcademicStudentPlanOfStudiesSection>
      <AcademicStudentPlanOfStudiesSection className="content-radius-shadow">
        <div className="data-table">
          <div className="top-heading">Semester 4</div>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1].map(() => {
                return (
                  <tr>
                    <td>RIN-4567</td>

                    <td>
                      <div className="mw-150">
                        Advanced Theory of Computation
                      </div>
                    </td>

                    <td>
                      <div className="status">Dr. Wareesha Sharif</div>
                    </td>
                    <td>6(6-0)</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </AcademicStudentPlanOfStudiesSection>
    </AcademicStudentPlanOfStudiesMain>
  );
};

export default AcademicStudentPlanOfStudies;
