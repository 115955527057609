import { FC, Fragment, useState, useEffect } from "react";
import {
  DownArrowLightgrayMediumSvg,
  EditGreenTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import Breadcrumb from "components/particles/breadcrumb";
import {
  CreateManageStudentMain,
  CreateManageStudentSection,
  CreateManageStudentTop,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useAdmissions from "../../../admissions/useHooks";
import useUtils from "hooks/useUtils";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";

const CreateManageStudent: FC = ({}) => {
  const breadcrumbLinks = [
    { title: "Academics /", path: "" },
    {
      title: "Program Specialization  /",
      path: siteRoutes.academicProgramSpecializationListing,
    },
    {
      title: "Manage Students  /",
      path: siteRoutes.academicManageStudentListing,
    },
    {
      title: "Add Students",
      path: siteRoutes.academicCreateManageStudent,
    },
  ];

  const [search, setSearch] = useState("");
  const [studentData, setStudentData] = useState<any[]>([]);
  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const { hasAccess } = useStore();

  const columns: string[] = [
    "Registration No",
    "Student Name",
    "Father Name",
    "CNIC",
    "Program",
  ];

  const toggleRowExpand = (index: number) => {
    const updatedStudentData = [...studentData];
    updatedStudentData[index].isExpanded =
      !updatedStudentData[index].isExpanded;
    setStudentData(updatedStudentData);
  };

  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };

  return (
    <CreateManageStudentMain>
      <CreateManageStudentTop>
        <div className="left">
          <span className="page-heading">Add Student</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right"></div>
      </CreateManageStudentTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label> Student Name</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="text" />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Registration Number</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="text" />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>CNIC</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="text" />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Program</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="academic_session">
                      <option value="">Select Program</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  // onClick={handleResetFilters}
                >
                  Reset
                </button>
                <button className="lg-rounded-btn">Apply Filters</button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>

      <CreateManageStudentSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {hasAccess(sitePermissions.admissionStudentDownloadPDF) && (
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>
            )}
            {hasAccess(sitePermissions.admissionStudentDownloadExcel) && (
              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            )}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1].map((item: any, index: number) => (
                <Fragment key={index}>
                  <tr>
                    <td>
                      <div className="mw-150">123423536</div>
                    </td>
                    <td>
                      <div className="mw-100">shayan faisal</div>
                    </td>
                    <td>
                      <div className="mw-100">faisal maqbool</div>
                    </td>
                    <td>
                      <div className="mw-150">31303-5280-411-9</div>
                    </td>
                    <td>
                      <div className="mw-150">BSCS</div>
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          <Fragment>
            {/* <Pagination
              onPageChange={(page: any) => console.log(page)}
              {...pagination}
            /> */}
          </Fragment>
        </div>
      </CreateManageStudentSection>
    </CreateManageStudentMain>
  );
};

export default CreateManageStudent;
