export enum siteRoutes {
  superAdminLogin = "/",
  adminLogin = "/admin/login",
  forgetPassword = "/admin/login/forget-password",
  codeVerification = "/admin/login/verification-code",
  createOrganization = "/private/organization/organization-management/create",
  organizationListing = "/private/organization/organization-management/listing",
  viewOrganization = "/private/organization/organization-management/view",
  createAsset = "/private/organization/assets-management/create",
  assetsListing = "/private/organization/assets-management/listing",
  createBuilding = "/private/organization/buildings-management/building",
  buildingListing = "/private/organization/buildings-management/listing",
  createProgram = "/private/academics/programs/create",
  programListing = "/private/academics/programs/listing",
  createOrgStructure = "/private/organization/organizational-structures/create",
  orgStructureListing = "/private/organization/organizational-structures/listing",
  createStructureType = "/private/organization/structure-types/create",
  structureTypeListing = "/private/organization/structure-types/listing",
  resultTypeListing = "/private/organization/result-types/listing",
  resultTypeCreate = "/private/organization/result-types/create",
  superAdminDashboard = "/private/dashboard/super-admin-dashboard",
  adminDashboard = "/private/dashboard/admin-dashboard",
  ePortalLogin = "/e-portal/login",
  ePortalRegister = "/e-portal/register",
  ePortalResetPassword = "/e-portal/reset-password",
  ePortalDashboard = "/private/e-portal/e-portal-dashboard",
  ePortalEditProfile = "/private/e-portal/user-profile/edit-profile",
  ePortalChallanListing = "/private/e-portal/challan-listing",
  ePortalMyVouchers = "/private/e-portal/my-vouchers",
  ePortalShortCoursesListing = "/private/e-portal/short-courses-listing",
  eportalHostelPortal = "/private/e-portal/eportal-hostel-portal",
  eportalAdmissionsListing = "/private/e-portal/application-list/eportal-application-listing",
  createEportalAdmissionList = "/private/e-portal/application-list/create-eportal-application-list",
  createHostel = "/private/hostel/hostel-management/create-hostel",
  hostelListing = "/private/hostel/hostel-management/hostel-listing",
  createHostelRoom = "/private/hostel/hostel-rooms-management/create-hostel-room",
  hostelRoomsListing = "/private/hostel/hostel-rooms-management/hostel-rooms-listing",
  applicantListing = "/private/hostel/applicants-management/applicant-listing",
  createApplicant = "/private/hostel/applicants-management/create-applicant",
  createHostelMerit = "/private/hostel/merit-management/create-merit",
  hostelMeritListing = "/private/hostel/merit-management/merit-listing",
  hostelSessionListing = "/private/hostel/session-management/hostel-session-listing",
  createHostelSession = "/private/hostel/session-management/create-hostel-session",
  hostelFinanceManagement = "/private/hostel/hostel-finance-management",
  appliedApplicant = "/private/hostel/applied-applicant",
  applicantEnrollment = "/private/hostel/applicant-enrollment",
  registeredApplicant = "/private/hostel/registered-applicant",
  hostelApplicantMeritList = "/private/hostel/hostel-applicant-merit-list",
  academicSessionListing = "/private/academics/academic-session-management/academic-session-listing",
  createAdmissionSession = "/private/admissions/admission-session/create",
  viewEportalProfile = "/private/e-portal/user-profile/view-profile",
  admissionSessionListing = "/private/admissions/admission-session/listing",
  admissionCampaignListing = "/private/admissions/admission-campaigns/listing",
  createAdmissionCampaign = "/private/admissions/admission-campaigns/create",
  admissionStudentListing = "/private/admissions/admission-students-listing",
  admissionApplicantsListing = "/private/admissions/admission-applicants-listing",
  editAdmissionDocument = "/private/admissions/admission-documents-management/create",
  admissionDocumentListing = "/private/admissions/admission-documents-management/listing",
  admissionTestTypesListing = "/private/admissions/admission-test-types/listing",
  createAdmissionTestType = "/private/admissions/admission-test-types/create",
  studentRegListing = "/private/admissions/student-registration-listing",
  admissionMeritListing = "/private/admissions/admission-merit-listing",
  admissionOfferLetterListing = "/private/admissions/admission-offer-letter/listing",
  createAdmissionOfferLetter = "/private/admissions/admission-offer-letter/create",
  editAdmissionBoard = "/private/admissions/edit-admission-board",
  editAdmissionCertificate = "/private/admissions/edit-admission-certificate",
  admissionManageDocumentsMasterListing = "/private/admissions/admission-documents-master-listing",
  admissioneligibilityTemplateHeadersListing = "/private/admissions/admission-eligibility-templates/headers-listing",
  createAdmissioneligibilityTemplateHeaders = "/private/admissions/admission-eligibility-templates/create-header",
  admissioneligibilityTemplateBodiesListing = "/private/admissions/admission-eligibility-templates/bodies-listing",
  createAdmissioneligibilityTemplateBody = "/private/admissions/admission-eligibility-templates/create-body",
  admissionQuotasListing = "/private/admissions/admission-quotas/admission-quotas-listing",
  createAdmissionQuotas = "/private/admissions/admission-quotas/create-admission-quotas",
  certificateLinkListing = "/private/organization/result-type/certificate-link-listing",
  certificateLinkCreate = "/private/organization/result-type/certificate-link-create",
  roleManagementListing = "/private/system-administration/admin-role-management/listing",
  rollManagementCreate = "/private/system-administration/admin-role-management/create",
  boardManagementListing = "/private/organization/board-management/board-management-listing",
  boardManagementCreate = "/private/organization/board-management/board-management-create",
  subjectManagementListing = "/private/admissions/subject-management/subject-management-listing",
  subjectManagementCreate = "/private/admissions/subject-management/subject-management-create",
  certificateManagementListing = "/private/organization/certificate-management/certificate-management-listing",
  certificateManagementCreate = "/private/organization/certificate-management/certificate-management-create",
  superAdminManagementListing = "/private/organization/super-admin-management/listing",
  superAdminManagementCreate = "/private/organization/super-admin-management/create",
  superRoleManagementListing = "/private/organization/super-role-management/listing",
  superRoleManagementCreate = "/private/organization/super-role-management/create",
  permissionManagementListing = "/private/organization/permission-management/listing",
  permissionManagementCreate = "/private/organization/permission-management/create",
  moduleManagement = "/private/organization/module-management",
  linkedSpecializationListing = "/private/organization/programs/linked-specialization-listing",
  createLinkedSpecialization = "/private/organization/programs/create-linked-specialization",
  linkedProgramEntryTestListing = "/private/organization/programs/linked-program-entry-test-listing",
  createLinkedProgramEntryTest = "/private/organization/programs/create-linked-program-entry-test",
  academicSessionOrganizationListing = "/private/organization/academic-session-organization/academic-session-organization-listing",
  createAcademicSessionOrganization = "/private/organization/academic-session-organization/create-academic-session-organization",
  admissionProgramListing = "/private/admissions/admission-program/admission-program-listing",
  admissionSubjectManagementListing = "/private/admissions/admission-subject-mangement/admission-subject-management-listing",
  createAdmissionSubjectManagement = "/private/admissions/admission-subject-mangement/create-admission-subject-management",
  admissionBoardManagementListing = "/private/admissions/admission-board-management/admission-board-management-listing",
  createAdmissionBoardManagement = "/private/admissions/admission-board-management/create-admission-board-management",
  admissionCertificatemanagementListing = "/private/admissions/admission-certificate-management/admission-certificate-management-listing",
  createAdmissionCertificateManagement = "/private/admissions/admission-certificate-management/create-admission-certificate-management",
  meritKeysListing = "/private/admissions/merit-keys/listing",
  createMeritKeys = "/private/admissions/merit-keys/create",
  meritListFormulaListing = "/private/admissions/merit-list-formulas/listing",
  createMeritListFormula = "/private/admissions/merit-list-formulas/create",
  // linkedProgramAdmissionListing = '/private/admission/linked-program-admission/linked-program-admission-listing',
  majorCategoriesListing = "/private/organization/assets-management/major-categories-listing",
  CreateMajorCategories = "/private/organization/assets-management/create-major-categories",
  minorCategoriesListing = "/private/organization/assets-management/minor-categories-listing",
  createMinorCategories = "/private/organization/assets-management/create-minor-categories",
  roomsListing = "/private/organization/buildings-management/rooms-listing",
  createRooms = "/private/organization/buildings-management/create-rooms",
  quotasAdmissionListing = "/private/admissions/quota-management/listing",
  createQuotasAdmissionList = "/private/admissions/quota-management/create",
  manageMeritListListing = "/private/admissions/manage-merit-list/listing",
  createAdmissionManageMeritList = "/private/admissions/manage-merit-list/create",
  qoutasListProgramListing = "/private/admissions/qoutas-list-management/quota-list-program-listing",
  eligibilityTemplatesListing = "/private/admissions/admission-campaigns/program-link-template-listing",
  eligibilityTemplatesCreate = "/private/admissions/admission-campaigns/create-program-link-template",
  programLinkMeritList = "/private/admissions/admission-campaigns/program-link-merit-listing",
  createProgramLinkMeritList = "/private/admissions/admission-campaigns/create-program-link-merit",
  financeDashboard = "/private/finance/finance-dashboard",
  systemUsersListing = "/private/system-administration/system-users/system-users-listing",
  createSystemUsers = "/private/system-administration/system-users/create-system-users",
  systemMenusListing = "/private/system-administration/menus/system-menus-listing",
  createSystemMenus = "/private/system-administration/menus/create-system-menus",
  unregisteredMenusListing = "/private/system-administration/unregistered-menus-listing",
  userAuditListing = "/private/system-administration/user-audit-listing",
  workflowListing = "/private/system-administration/workflow/workflow-listing",
  createWorkflow = "/private/system-administration/workflow/create-workflow",
  eportalCareersListing = "/private/e-portal/e-portal-career/eportal-career-listing",
  createEportalCareer = "/private/e-portal/e-portal-career/create-eportal-career",
  systemLogsListing = "/private/system-administration/system-logs-listing",
  studentFinancialClearanceListing = "/private/finance/student-financial-clearance-listing",
  checkFinancialChallanStatus = "/private/finance/check-financial-challan-status",
  fineSlotsListing = "/private/finance/fine-slots/fine-slots-listing",
  createFineSlot = "/private/finance/fine-slots/create-fine-slot",
  templateProgramAccountCodeListing = "/private/finance/template-program-account-code-listing",
  voucherTypesListing = "/private/finance/voucher-types/voucher-types-listing",
  createVoucherType = "/private/finance/voucher-types/create-voucher-type",
  createFinanceApplications = "/private/finance/finance-applications/create-finance-application",
  financeApplicationsListing = "/private/finance/finance-applications/finance-applications-listing",
  applicationTemplateLinkListing = "/private/finance/application-template-link/application-template-link-listing",
  createApplicationTemplateLink = "/private/finance/application-template-link/create-application-template-link",
  createVoucherTemplateHeader = "/private/finance/voucher-template-header/create-voucher-template-header",
  voucherTemplateHeaderListing = "/private/finance/voucher-template-header/voucher-template-header-listing",
  createBank = "/private/finance/banks/create-bank",
  banksListing = "/private/finance/banks/banks-listing",
  bankTransactionLogsListing = "/private/finance/bank-transactions-logs-listing",
  bankScrollLogsListing = "/private/finance/bank-scroll-logs-listing",
  createVoucherParticular = "/private/finance/voucher-particulars/create-voucher-particular",
  voucherParticularListing = "/private/finance/voucher-particulars/voucher-particulars-listing",
  masterBookListing = "/private/finance/master-book/master-book-listing",
  masterBookTransactionDetail = "/private/finance/master-book/master-book-transaction-detail",
  showMasterBookTransactionDetail = "/private/finance/master-book/show-master-book-transaction-detail",
  eportalAppliedJobListing = "/private/e-portal/e-portal-career/eportal-applied-jobs-listing",
  eportalCarrerProfile = "/private/e-portal/e-portal-career/career-profile-listing",
  careerDesignationListing = "/private/career/designation-management/career-designation-listing",
  createCareerDesignation = "/private/career/designation-management/create-career-designation",
  careerAdvertisementListing = "/private/career/advertisement-management/career-advertisement-listing",
  createCareerAdvertisement = "/private/career/advertisement-management/create-career-advertisement",
  careerPostTemplateListing = "/private/career/post-template-management/post-template-listing",
  createCareerPostTemplate = "/private/career/post-template-management/create-post-template",
  careerPostListing = "/private/career/post-management/career-post-listing",
  createCareerPost = "/private/career/post-management/create-career-post",
  careerApplicantListing = "/private/career/career-applicant-listing",
  voucherTemplateBodiesListing = "/private/finance/voucher-template-bodies/voucher-template-bodies-listing",
  financeInstallmentListing = "/private/finance/installment-management/installment-listing",
  createFinanceInstallment = "/private/finance/installment-management/create-installment",
  installmentSlotListing = "/private/finance/installment-slot-management/installment-slot-listing",
  createInstallmentSlot = "/private/finance/installment-slot-management/create-installment-slot",
  slotsInstallmentParticulars = "/private/finance/installment-slot-management/slots-installment-particulars",
  certificateLevelList = "/private/organization/certificate-level-management/listing",
  createCertificateLevels = "/private/organization/certificate-level-management/create",
  authoritiesListing = "/private/authorities-and-communities/authorities/listing",
  createAuthorities = "/private/authorities-and-communities/authorities/create",
  viewMembers = "/private/authorities-and-communities/view-members",
  viewDocuments = "/private/authorities-and-communities/view-documents",
  senateMeetingList = "/private/authorities-and-communities/authorities/senate-meeting-listing",
  createSenateMeeting = "/private/authorities-and-communities/authorities/create-senate-meeting",
  councilNotificationsListing = "/private/authorities-and-communities/council-notification/council-notifications-listing",
  createCouncilNotifications = "/private/authorities-and-communities/council-notification/create-council-notifications",
  authorityMemberTypeListing = "/private/authorities-and-communities/authority-member-type/authority-member-type-listing",
  createAuthorityMemberType = "/private/authorities-and-communities/authority-member-type/create-authority-member-type",
  authorityTypeListing = "/private/authorities-and-communities/authority-type/authority-type-listing",
  createAuthorityType = "/private/authorities-and-communities/authority-type/create-authority-type",
  testingServicesDashboard = "/private/testing-services/testing-services-dashboard",
  testTypesListing = "/private/testing-services/test-types-management/test-types-listing",
  createTestType = "/private/testing-services/test-types-management/create-test-types",
  TestingServicesTestCenterlist = "/private/testing-services/test-center-management/test-center-listing",
  TestingServicesTestCentercreate = "/private/testing-services/test-center-management/create-test-center",
  TestingServicesTestSchedulelist = "/private/testing-services/test-schedule-management/test-schedule-listing",
  createTestingServicesTestSchedule = "/private/testing-services/test-schedule-management/create-test-schedule",
  testingServicespublishresult = "/private/testing-services/test-result-management/test-publish-result-listing",
  testingServicescandidateTestResultlist = "/private/testing-services/test-upload-result-management/test-upload-result-listing",
  testingCenterRooms = "/private/testing-services/test-center-rooms/test-center-rooms-listing",
  createTestingServicesTestCenterRooms = "/private/testing-services/test-center-rooms/create-test-center-rooms",
  testingServicesTestScheduleSeatingPlanlist = "/private/testing-services/test-schedule-management/seating-plan-listing",
  testingServicesTestScheduleSeatingPlanRoomslist = "/private/testing-services/test-schedule-management/seating-plan-room-listing",
  viewTestApplicants = "/private/testing-services/test-schedule-management/view-test-applicants",
  testingServicescandidatelisting = "/private/testing-services/candidate-applied-listing",
  reportCategoriesList = "/private/qec/report-categories/report-categories-listing",
  createreportCategories = "/private/qec/report-categories/create-report-categories",
  qecReportManagelist = "/private/qec/report-manage/report-manage-listing",
  createQecReportManage = "/private/qec/report-manage/create-report-manage",
  qecSurveyReportlist = "/private/qec/report-management/survey-report-listing",
  createQecSurveyReport = "/private/qec/report-management/create-survey-report",
  surveyListing = "/private/qec/survey-management/survey-listing",
  createSurvey = "/private/qec/survey-management/create-survey",
  surveyTypeListing = "/private/qec/survey-type-management/survey-type-listing",
  createSurveyType = "/private/qec/survey-type-management/create-survey-type",
  questionListing = "/private/qec/question-management/question-listing",
  createQuestion = "/private/qec/question-management/create-question",
  libraryPublisherlist = "/private/library/publisher-management/library-publisher-listing",
  createLibraryPublisher = "/private/library/publisher-management/create-library-publisher",
  librarySellerlist = "/private/library/seller-management/library-seller-listing",
  createLibrarySeller = "/private/library/seller-management/create-library-seller",
  libraryBillslist = "/private/library/bill-management/library-bill-listing",
  createLibraryBills = "/private/library/bill-management/create-library-bill",
  libraryBookslist = "/private/library/books-management/library-books-listing",
  createLibraryBooks = "/private/library/books-management/create-library-books",
  libraryLanguagelist = "/private/library/language-management/language-listing",
  createLibraryLanguage = "/private/library/language-management/create-language",
  libraryAccessRegisterlist = "/private/library/library-access-management/library-access-register-listing",
  legalFrameworkList = "/private/legal-matter/legal-framework-management/legal-framework-listing",
  createLegalFramework = "/private/legal-matter/legal-framework-management/create-legal-framework",
  legalOrganizationList = "/private/legal-matter/legal-organization-management/legal-organization-listing",
  createLegalOrganization = "/private/legal-matter/legal-organization-management/create-legal-organization",
  frameworkDocumentTypeList = "/private/legal-matter/framework-document-type-management/framework-document-type-listing",
  createFrameworkDocumentType = "/private/legal-matter/framework-document-type-management/create-framework-document-type",
  billRequestList = "/private/legal-matter/bill-request-management/bill-request-listing",
  createBillRequest = "/private/legal-matter/bill-request-management/create-bill-request",
  legalCasesList = "/private/legal-matter/legal-cases-management/legal-cases-listing",
  createLegalCases = "/private/legal-matter/legal-cases-management/create-legal-cases",
  legalAdvisorListing = "/private/legal-matter/legal-advisor-management/legal-advisor-listing",
  createLegalAdvisor = "/private/legal-matter/legal-advisor-management/create-legal-advisor",
  legalCourtList = "/private/legal-matter/legal-court-management/legal-court-listing",
  createLegalCourt = "/private/legal-matter/legal-court-management/create-legal-court",

  legalStatusList = "/private/legal-matter/legal-status-management/legal-status-listing",
  createLegalStatus = "/private/legal-matter/legal-status-management/create-legal-status",
  legalMatterTypeListing = "/private/legal-matter/legal-matter-type-management/legal-matter-type-listing",
  createLegalMatterType = "/private/legal-matter/legal-matter-type-management/create-legal-matter-type",
  advisorRatesListing = "/private/legal-matter/advisor-rates-management/advisor-rates-listing",
  createAdvisorRates = "/private/legal-matter/advisor-rates-management/create-advisor-rates",
  allocateCourseRoomListing = "/private/academics/common-courses-rooms/allocate-course-rooms-listing",
  academicGradeBookListing = "/private/academics/academic-session-management/grade-books-listing",
  createAcademicCourse = "/private/academics/academic-session-management/create-academic-course",
  academicCourseListing = "/private/academics/academic-session-management/academic-courses-listing",
  createAcademicCourseGroup = "/private/academics/academic-session-management/create-course-group",
  academicCourseGroupListing = "/private/academics/academic-session-management/course-groups-listing",
  academicSessionManagementListing = "/private/academics/academic-session-management/session-management-listing",
  academicEnrollmentsListing = "/private/academics/academic-enrollments/academic-enrollments-listing",
  createCommonCoursesPools = "/private/academics/common-courses-pools/create-common-courses-pool",
  commonCoursesPoolsListing = "/private/academics/common-courses-pools/common-courses-pools-listing",
  academicStudent = "/private/academics/academic-student/student-listing",
  academicStudentFine = "/private/academics/academic-student-fine/student-fine-listing",
  approvedReceiptAcknowledgedListing = "/private/academics/approved-receipt-acknowledged/approved-receipt-acknowledged-listing",
  appRecAckViewAwardList = "/private/academics/approved-receipt-acknowledged/view-award-list",
  oricDashboard = "/private/oric/oric-dashboard",
  oricEventTypesListing = "/private/oric/oric-events/event-type-listing",
  createOricEventTypes = "/private/oric/oric-events/create-event-type",
  oricConferenceListing = "/private/oric/oric-conference/oric-conference-listing",
  createOricConference = "/private/oric/oric-conference/create-oric-conference",
  oricCallListing = "/private/oric/oric-calls/oric-calls-listing",
  createOricCalls = "/private/oric/oric-calls/create-oric-call",
  oricCallCategoriesListing = "/private/oric/oric-calls-category/oric-call-category-listing",
  createOricCallCategories = "/private/oric/oric-calls-category/create-oric-call-category",
  oricCareerDevelopmentListing = "/private/oric/oric-career-development/career-development-listong",
  keyPerformanceIndicatorYearListing = "/private/oric/oric-performance-indicator/key-performance-indicator-year-listing",
  createKeyPerformanceIndicatorYear = "/private/oric/oric-performance-indicator/create-key-performance-indicator-year",
  researchIncentivesListing = "/private/oric/oric-research-incentives/research-incentives-listing",
  createResearchIncentives = "/private/oric/oric-research-incentives/create-research-incentives",
  oricCommunityListing = "/private/oric/oric-community/oric-communities-listing",
  createOricCommunity = "/private/oric/oric-community/create-oric-communities",
  oricMouPartyListing = "/private/oric/oric-mou-party/oric-mou-party-listing",
  createOricMouParty = "/private/oric/oric-mou-party/create-oric-mou-party",
  oricMouListing = "/private/oric/oric-mou/oric-mou-listing",
  createOricMou = "/private/oric/oric-mou/create-oric-mou",
  oricProjectDateListing = "/private/oric/oric-project/oric-project-date-listing",
  createOricProjectDate = "/private/oric/oric-project/create-oric-project-date",
  oricProjectlisting = "/private/oric/oric-project-management/oric-project-listing",
  createOricProject = "/private/oric/oric-project-management/create-oric-project",
  oricResearchGrantApplicationslisting = "/private/oric/oric-research-application/oric-research-application-listing",
  oricResearchProjectListing = "/private/oric/oric-research-project/oric-research-project-listing",
  createOricResearchProject = "/private/oric/oric-research-project/create-oric-research-project",
  oricMajorSubjectsListing = "/private/oric/oric-major-subject/oric-major-subjects-listing",
  createOricMajorSubjects = "/private/oric/oric-major-subject/create-oric-major-subjects",
  oricKPIScore = "/private/oric/oric-kpi-score/oric-kpi-score-listing",

  poolTeachersListing = "/private/academics/common-courses-pools/pool-teachers-listing",
  enrollPoolTeacher = "/private/academics/common-courses-pools/enroll-pool-teacher",
  consolidatedResultsListing = "/private/academics/consolidated-results/listing",
  sectionCoursesListing = "/private/academics/consolidated-results/section-courses-listing",
  submittedCoursesListing = "/private/academics/consolidated-results/submitted-courses-listing",
  financeAcademicSessionListing = "/private/academics/finance-academic-session/listing",
  createFinanceAcademicSession = "/private/academics/finance-academic-session/create",
  financeSessionStudentsListing = "/private/academics/finance-academic-session/finance-session-students",
  academicDefaulterStudent = "/private/academics/academic-defaulter-student/academic-defaulter-student-listing",
  academicFeeTypeListing = "/private/academics/academic-fee-type/academic-fee-type-listing",
  createAcademicFeeType = "/private/academics/academic-fee-type/create-academic-fee-type",
  academicStudentClearence = "/private/academics/academic-student-clearence",
  manageGradeTemplates = "/private/academics/manage-grade-templates",
  createAcademicTemplateCourse = "/private/academics/academic-template-courses/create",
  academicTemplateCoursesListing = "/private/academics/academic-template-courses/listing",
  createAcademicInternship = "/private/academics/academic-internships/create",
  academicInternshipsListing = "/private/academics/academic-internships/listing",
  createAcademicFeePlan = "/private/academics/academic-fee-plan/create",
  academicFeePlansListing = "/private/academics/academic-fee-plan/listing",
  academicManageFeePlanListing = "/private/academics/academic-session-management/manage-fee-plan-listing",
  academicFeeTransactionsListing = "/private/academics/fee-transactions/listing",
  createAcademicFeeReceipt = "/private/academics/fee-transactions/create-fee-receipt",
  academicPaidVouchersListing = "/private/academics/academic-paid-vouchers-listing",
  academicExamTypeListing = "/private/academics/academic-exam-types/listing",
  createAcademicExamType = "/private/academics/academic-exam-types/create",
  createAcademicExam = "/private/academics/academic-exams/create",
  academicExamsListing = "/private/academics/academic-exams/listing",
  createAcademicInvigilator = "/private/academics/invigilators/create",
  academicInvigilatorsListing = "/private/academics/invigilators/listing",
  createAcademicAuthority = "/private/academics/authorities/create",
  academicAuthorityListing = "/private/academics/authorities/listing",
  createAcademicAuthorityUser = "/private/academics/authorities/create-authority-user",
  academicAuthorityUsersListing = "/private/academics/authorities/authority-users-listing",
  createAcademicGradeTemplate = "/private/academics/grade-templates/create",
  academicGradeTemplatesListing = "/private/academics/grade-templates/listing",
  createAcademicStatus = "/private/academics/academic-status/create",
  academicStatusListing = "/private/academics/academic-status/listing",
  createClearanceAuthority = "/private/academics/clearance-authorities/create",
  clearanceAuthoritiesListing = "/private/academics/clearance-authorities/listing",
  createStudentStatus = "/private/academics/student-status/create",
  studentStatusListing = "/private/academics/student-status/listing",
  createStudentGroup = "/private/academics/student-groups/create",
  studentGroupListing = "/private/academics/student-groups/listing",
  createStudentSpecialization = "/private/academics/student-specializations/create",
  studentSpecializationsListing = "/private/academics/student-specializations/listing",
  createAcademicSemesterType = "/private/academics/semester-types/create",
  academicSemesterTypesListing = "/private/academics/semester-types/listing",
  createAcademicLetterGrade = "/private/academics/academic-letter-grades/create",
  academicLetterGradesListing = "/private/academics/academic-letter-grades/listing",
  createAcademicSection = "/private/academics/academic-section/create",
  academicSectionsListing = "/private/academics/academic-section/listing",
  academicSectionRoomsListing = "/private/academics/academic-section/rooms",
  academicManageExamIncharge = "/private/academics/manage-exam-incharge/manage-exam-incharge-listing",
  academicExamIncharge = "/private/academics/manage-exam-incharge/exam-incharge-listing",
  academicSyncGradeTemplates = "/private/academics/grade-templates/sync-grade-templates",
  studentClearenceListing = "/private/academics/student-clearence/student-clearence-listing",
  hrManagementLeaveDashboard = "/private/hr-management/leaves-dashboard",
  createOvertimeSlot = "/private/hr-management/overtime-slots/create",
  overtimeSlotsListing = "/private/hr-management/overtime-slots/listing",
  absentEmployeesListing = "/private/hr-management/absent-employees-listing",
  leaveCancellationListing = "/private/hr-management/leave-cancellation-listing",
  releasedLeaveBalancingReportListing = "/private/hr-management/released-leave-balancing-report-listing",
  remunerationListing = "/private/hr-management/remuneration-listing",
  overtimeManualEntryListing = "/private/hr-management/overtime-manual-entry-listing",
  employeesOvertimeListing = "/private/hr-management/employees-overtime-listing",
  requestedLeavesListing = "/private/hr-management/requested-leaves-listing",
  myAttendanceList = "/private/hr-management/my-attendance-list",
  createLeaveRequestion = "/private/hr-management/leave-requestion",
  leaveTypeListing = "/private/hr-management/leave-type/leave-type-listing",
  createLeaveTypes = "/private/hr-management/leave-type/create-leave-type",
  setOffDays = "/private/hr-management/set-off-days",
  requestDetails = "/private/hr-management/request-details",
  academicPlanofStudies = "/private/academics/plan-of-studies/plan-of-studies-listing",
  createStudyPlans = "/private/academics/plan-of-studies/create-plan-of-studies",
  createAcademicSession = "/private/academics/academic-session-management/create-academic-session",
  planOfStudyCourseListing = "/private/academics/plan-of-studies/course-listing",
  planOfStudyStudentListing = "/private/academics/plan-of-studies/student-listing",
  createAuthorityBoard = "/private/authorities-and-communities/authority-boards/create",
  authorityBoardsListing = "/private/authorities-and-communities/authority-boards/listing",
  remunerationSettingsListing = "/private/hr-management/remuneration-settings-listing",
  hrManagementLeavesLedgerListing = "/private/hr-management/leaves-ledger-listing",
  createTeachersTitle = "/private/academics/teachers-titles/create",
  teachersTitlesListing = "/private/academics/teachers-titles/listing",
  createCourseType = "/private/academics/course-types/create",
  courseTypesListing = "/private/academics/course-types/listing",
  setHolidaysListing = "/private/hr-management/set-holidays/set-holidays-listing",
  eportalTestingServiceListing = "/private/e-portal/eportal-testing-service-listing",
  //student portal routes
  studentPortalNoticeBoard = "/private/student-portal/notice-board",
  studentPortalDocumentsListing = "/private/student-portal/my-links/student-portal-documents",
  studentPortalProfile = "/private/student-portal/my-links/student-portal-profile",
  studentPortalLMS = "/private/student-portal/LMS",
  studentPortalLibraryBooks = "/private/student-portal/library-books-listing",
  studentPortalSupportTickets = "/private/student-portal/support-tickets-listing",
  studentDashboard = "/private/dashboard/student-dashboard",
  campaignProgramsListing = "/private/admissions/admission-campaigns/programs-listing",
  createSetHolidays = "/private/hr-management/set-holidays/create-set-holidays",
  //student portal routes

  studentPortalSupportTicketsListing = "/private/student-portal/support-tickets/listing",
  createStudentPortalSupportTicket = "/private/student-portal/support-tickets/create",
  academicsCurrentEnrollment = "/private/academics/academic-current-enrollment/academic-current-enrollment-listing",
  academicsStudentPlanOfStudies = "/private/academics/academic-student-plan-of-studies/academic-student-plan-of-studies-listing",
  academicsStudentTranscript = "/private/academics/academic-student-transcript/academic-student-transcript-listing",
  academicFinancialVouchers = "/private/academics/academic-financial-vouchers/academic-financial-vouchers-listing",
  academicAccountStatements = "/private/academics/academic-account-students/academic-account-students-listing",
  academicHolidaysList = "/private/academics/academic-holidays/academic-holidays-listing",
  academicStudentClearance = "/private/academics/academic-student-clearance/academic-student-clearance-listing",
  studentLMSMyFiles = "/private/student-portal/LMS/my-files",
  studentAssignments = "/private/student-portal/LMS/assignments",
  studentCourseMaterial = "/private/student-portal/LMS/course-material",
  studentVehicalEntryListing = "/private/student-portal/vehicle-entry/vehicle-entry-listing",
  createStudentVehicleEntry = "/private/student-portal/vehicle-entry/create-vehicle-entry",
  studentTransportScheduleList = "/private/student-portal/transport-schedule/transport-schedule-listing",
  studentAcademicCourseChallan = "/private/academics/academic-course-challan/academic-course-challan-listing",
  admissionLockedMeritListing = "/private/admissions/admission-locked-merit/admission-locked-merit-Listing",
  academicProgramSpecializationListing = "/private/academics/program-specialization/program-specialization-listing",
  academicCreateProgramSpecialization = "/private/academics/program-specialization/create-program-specialization",
  academicManageSpecializationListing = "/private/academics/program-specialization/manage-specialization-listing",
  academicCreateManageSpecialization = "/private/academics/program-specialization/create-manage-specialization",
  academicManageStudentListing = "/private/academics/program-specialization/manage-student-listing",
  academicCreateManageStudent = "/private/academics/program-specialization/create-manage-student",
  academicAppliedSpecialization = "/private/academics/program-specialization/applied-specialization-listing",
  organizationAdminListing = "/private/organization/admin-management/admin-listing",
  createOrganizationAdmin = "/private/organization/admin-management/create-admin",
  updateOrganizationAdmin = "/private/organization/update-organization",
}
