export class UserRegisterDTO {
    first_name: string = '';
    last_name: string = '';
    email: string = '';
    password: string = '';
    password_confirmation: string = '';
    gender: string = '';
    phone_no: string = '';
    city: string = '';
    passport_no: string = '';
    cnic: string = '';
    address: string = '';
    father_name: string = ''
}