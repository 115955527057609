import { ChangeEvent, FC, useEffect, useState } from "react";
import { ApplyForTestMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg, SmallUploadSvg } from "assets/images/common/svgs";
import { useForm } from "react-hook-form";
import { TestApplicationDTO } from "utils/helpers/models/testing-service/test-application.dto";
import useEportal from "containers/private/e-portal/useHooks";
import FormErrorMessage from "components/particles/forms/form-error-message";
import squareAvatar from "assets/images/common/others/avatar-square-image.png";
import useUtils from "hooks/useUtils";

interface UploadChallanPopup {
  app_id: Number
  setOpen: Function;

}

const UploadChallan: FC<UploadChallanPopup> = ({ app_id, setOpen }) => {
  const {  getLocalFilePath  } = useUtils();
  const {
    uploadChallan
  } = useEportal();

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<TestApplicationDTO>();

  let [formData, setFormData] = useState<TestApplicationDTO>(
    new TestApplicationDTO()
  );

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };

  // const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
  //   const { name, files } = event.target as any;
  //   const file = files[0];
    
  //   const path = getLocalFilePath(file);
  //   console.log(path,'filejjj')
  //   setFormData({ ...formData, challan_file: path });
  //   setValue(name, file);
  // };
 
  // const onSubmit = (data: TestApplicationDTO, addMore: boolean = false) => {
  //   uploadChallan(
  //     { ...data,challan_file :formData?.challan_file, app_id: app_id },
  //     setOpen()
  //   );
  // };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target as any;
    const file = files[0];
  
    const path = getLocalFilePath(file);
    setFormData({ ...formData, challan_file: path });
    setValue(name, file);
  };
 
  const onSubmit = (data: TestApplicationDTO, addMore: boolean = false) => {
    const form_data = new FormData();
    for(let key in formData){
      if(key === 'challan_file'){
        form_data.append(key, data[key]);
      }else{
        form_data.append(key, (formData as any)[key])
      }
    }
    uploadChallan(
      form_data,
      app_id,
      setOpen
    );
  };
  

  return (
    <ApplyForTestMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Upload Challan</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <div className="common-fields">
              <div className="input-field">
                <label>Challan/Receipt Date</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="date"
                      {...register("challan_upload", { required: true })}
                      value={formData.challan_upload}
                      onChange={handleChange}
                    />
                  </div>
                  <FormErrorMessage error={errors.challan_upload} />
                </div>
              </div>

              <div className="input-field">
                <label>Challan/Receipt Reference No.</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="number"
                      {...register("challan_refference_no", { required: false })}
                      value={formData.challan_refference_no}
                      onChange={handleChange}
                    />
                  </div>
                  <FormErrorMessage error={errors.challan_refference_no} />
                </div>
              </div>
            </div>

            <div className="upload-field">
              <label htmlFor="challan_file">Upload Challan/Receipt Snapshot</label>
              <label className="field-wrapper" htmlFor="challan_file">
                <div className="file-name-section">
                  <div className="inner-content">
                    <div className="upload-text">
                      <div className="upload-icon">
                        <SmallUploadSvg className="icon" />
                      </div>
                      <span className="text">Upload Challan/Receipt Snapshot</span>
                    </div>
                    <div className="upload-restrictions">
                      Select a 300x300 jpg image with maximum size of 400 KB
                    </div>
                  </div>
                </div>
                <div className="uploaded-image domicile">
                  <img src={formData?.challan_file ?? squareAvatar} alt="" />
                </div>
                <input
                  type="file"
                  id="challan_file"
                  {...register("challan_file", { required: false })}
                  className="d-none"
                  onChange={handleFileUpload}
                />
              </label>
              <FormErrorMessage error={errors.challan_file} />
            </div>

            <div className="action-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" type="button" onClick={() => setOpen(false)}>
                  Reset
                </button>
                <button className="lg-rounded-btn" onClick={handleSubmit((data) => onSubmit(data))}>
                  Upload Challan
                </button>
              </div>
            </div>
          </form>

        </ContentWrapper>
      </Container>
    </ApplyForTestMain>
  );
};

export default UploadChallan;
