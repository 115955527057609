import styled from "styled-components";

export const NoticeBoardMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;

export const NoticeBoardTopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

export const NoticeBoardSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 1em 1.5em;

  .notice-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;
    flex-wrap: wrap;

    .particular-notice {
      width: 100%;
      display: flex;
      gap: 1.5em;
      justify-content: space-between;
      align-items: start;
      flex-wrap: wrap;

      .left {
        display: flex;
        gap: 1em;
        align-items: flex-start;
        flex-wrap: wrap;
        .notice-thumbnail {
          width: 77px;
          height: 90px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .notice-info {
          display: flex;
          flex-direction: column;
          /* gap: .5em; */

          .notice-title {
            font-size: 15px;
            font-weight: 500;
            color: var(--black-text);

            @media screen and (max-width: 500px) {
              font-size: 14px;
            }
          }

          .notice-description {
            color: var(--lightgray-light);
            font-size: 15px;
            font-weight: 400;

            @media screen and (max-width: 500px) {
              font-size: 13px;
            }
          }
        }
      }

      .right {
        .notice-issue-info {
          display: flex;
          flex-direction: column;
          color: var(--lightgray-light);
          font-weight: 400;
          font-size: 14px;

          .issuance-date {
            display: flex;
            gap: 0.5em;
            align-items: center;

            .date-icon {
              width: 16px;
              height: 16px;
              svg {
                width: 100%;
                height: 100%;
                path {
                  fill: var(--lightgray-light);
                }
              }
            }
          }
        }
      }
    }
  }
`;
