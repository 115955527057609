import { FC, Fragment, useEffect, useState } from "react";
import {
    AcademicCourseGroupsListingSection,
    AcademicCourseGroupsListingMain,
    AcademicCourseGroupsListingTop,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
    BlackSettingTableSvg,
    DeleteTableSvg,
    EditTableSvg,
    ExcelSvg,
    PdfSvg,
    SearchFieldSvg,
    SettingsSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useFinance from "containers/private/finance/useHooks";
// import { PoolCourseHeaderDTO } from "utils/helpers/models/finance/bank-info.dto";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useAcademics from "../../useHooks";
import { CoursePoolHeaderDTO } from "utils/helpers/models/academics/course-pool-header.dto";

interface AcademicCourseGroupsListingProps { }

const CommonCoursesPoolsListing: FC<AcademicCourseGroupsListingProps> = ({ }) => {
    const breadcrumbLinks: BreadcrumbLink[] = [
        { title: 'Academics / ', path: siteRoutes.academicSessionListing },
        { title: 'Common Courses Pools', path: siteRoutes.commonCoursesPoolsListing },
    ]
    const navigate = useNavigate();
    // const { getPoolCourseHeaders, deletePoolCourseHeader } = useFinance();
    const { getPoolCourseHeaders, deletePoolCourseHeader } = useAcademics();
    const [data, setData] = useState<CoursePoolHeaderDTO[]>([]);
    const { isLoading } = useSelector((state: any) => state.sharedReducer);
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 10,
        totalRecords: 1,
    });
    const [search, setSearch] = useState<string>("");
    const columns: string[] = [
        "Title",
        "Total Teachers",
        "Action",
    ];

    const { hasAccess } = useStore();
    const { handleSearchChange, handleTableSearch } = useUtils();

    const goToCreateCommonCoursePool = () => {
        navigate(siteRoutes.createCommonCoursesPools);
    };

    useEffect(() => {
        getAllPoolCourseHeaders(pagination.page, search);
    }, []);

    const handleDelete = async (id: number) => {
        const response = await confirmationPopup();
        if (response.isConfirmed) {
            const queryParams = {
                page: 1,
                per_page: pagination.per_page,
            };
            deletePoolCourseHeader(id, setData, queryParams, setPagination);
        }
    };

    const goToPoolTeachers = (poolId: number) => {
        navigate(`${siteRoutes.poolTeachersListing}?pool_id=${poolId}`);
    }

    const onPageChange = (pageInfo: { selected: number }) => {
        const { selected: page } = pageInfo;
        setPagination({ ...pagination, page: page + 1 });
        getAllPoolCourseHeaders(page + 1, search);
    };
    const getAllPoolCourseHeaders = (page: number, search: string) => {
        const queryParams = {
            per_page: pagination.per_page,
            page,
            search,
        };
        getPoolCourseHeaders(setData, queryParams, setPagination);
    };

    const goToEdit = (id: number) => {
        navigate(`${siteRoutes.createAcademicCourse}?id=${id}`);
    }

    return (
        <AcademicCourseGroupsListingMain>
            <AcademicCourseGroupsListingTop>
                <div className="left">
                    <span className="page-heading">Common Courses Pools</span>
                    <Breadcrumb links={breadcrumbLinks} />
                </div>
                <div className="right">
                    {hasAccess(sitePermissions.createAcademicCourse) && (
                        <div className="create-fine-slot-btn">
                            <button className="lg-rounded-btn" onClick={goToCreateCommonCoursePool}>
                                + Add Pool
                            </button>
                        </div>
                    )}
                </div>
            </AcademicCourseGroupsListingTop>

            <AcademicCourseGroupsListingSection className="content-radius-shadow">
                <div className="list-header">
                    <div className="table-data-export-buttons">
                        <div className="export-btn">
                            <span>
                                <PdfSvg className="icon" />
                            </span>
                            <span className="text">PDF</span>
                        </div>
                        <div className="export-btn">
                            <span>
                                <ExcelSvg className="icon" />
                            </span>
                            <span className="text">Excel</span>
                        </div>
                    </div>
                    <div className="table-search-field">
                        <span className="search-icon">
                            <SearchFieldSvg className="icon" />
                        </span>
                        <input
                            type="search"
                            placeholder="Search"
                            value={search}
                            onChange={(e) =>
                                handleSearchChange(e, setSearch, getAllPoolCourseHeaders)
                            }
                            onKeyUp={(e) => handleTableSearch(e, getAllPoolCourseHeaders)}
                        />
                    </div>
                </div>
                <div className="data-table">
                    <table className="bottom-bordered-cells">
                        <thead>
                            <tr>
                                {columns.map((column: string, index: number) => {
                                    return <th key={index}>{column}</th>;
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((pool, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div className="mw-150">
                                                {pool.title}
                                            </div>
                                        </td>
                                        <td>17</td>
                                        <td>
                                            <div className="table-action-icons">
                                                <div className="action-icon" onClick={() => goToEdit(pool.id)}>
                                                    <EditTableSvg />
                                                </div>
                                                {hasAccess(sitePermissions.poolTeachersListing) && <div className="action-icon" title="Manage Teachers" onClick={() => goToPoolTeachers(pool.id)}>
                                                    <BlackSettingTableSvg />
                                                </div>}
                                                <div className="action-icon" onClick={() => handleDelete(pool.id)}>
                                                    <DeleteTableSvg />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Fragment>
                    {/* <DataNotFound show={!isLoading && !data.length} /> */}
                    <Pagination
                        {...pagination}
                        onPageChange={onPageChange}
                    />
                </Fragment>
            </AcademicCourseGroupsListingSection>
        </AcademicCourseGroupsListingMain>
    );
};

export default CommonCoursesPoolsListing;
