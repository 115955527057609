import CreateSurveyTypes from "containers/private/qec/survey-types/create";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/helpers/common/http-methods";

export const QEC_APIS = {
  getSurveyTypes: () => getRequest(`admin/qec/survey_types`),
  createSurveyTypes: (body: any) => postRequest(`admin/qec/survey_types`, body),
};
