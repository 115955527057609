import StudentAssignments from "containers/private/student-portal/assignments";
import StudentCourseMaterial from "containers/private/student-portal/course-material";
import StudentDashboard from "containers/private/student-portal/dashboard";
import LibraryBooksListing from "containers/private/student-portal/library";
import LMSEnrollmentsListing from "containers/private/student-portal/LMS";
import LMSMyFiles from "containers/private/student-portal/my-files";
import StudentPortalDocumentsListing from "containers/private/student-portal/my-links/documents";
import StudentPortalProfile from "containers/private/student-portal/my-links/profile";
import NoticeBoard from "containers/private/student-portal/notice-board";
import CreateSupportTicket from "containers/private/student-portal/support-tickets/create";
import SupportTicketsListing from "containers/private/student-portal/support-tickets/listing";
import TransportSceduleList from "containers/private/student-portal/transport-schedule";
import CreateVehicleEntry from "containers/private/student-portal/vehical-entry/create";
import VehicleEntryListing from "containers/private/student-portal/vehical-entry/listing";
import useUtils from "hooks/useUtils";
import { Route, Routes } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const StudentPortalRoutes = () => {
  const { getPathToSetRoute } = useUtils();
  return (
    <Routes>
      <Route
        path={getPathToSetRoute(siteRoutes.studentDashboard)}
        Component={StudentDashboard}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentPortalProfile, true)}
        Component={StudentPortalProfile}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentPortalDocumentsListing, true)}
        Component={StudentPortalDocumentsListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentPortalNoticeBoard)}
        Component={NoticeBoard}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentPortalLMS)}
        Component={LMSEnrollmentsListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentPortalLibraryBooks)}
        Component={LibraryBooksListing}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.studentPortalSupportTicketsListing,
          true
        )}
        Component={SupportTicketsListing}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.createStudentPortalSupportTicket,
          true
        )}
        Component={CreateSupportTicket}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentVehicalEntryListing, true)}
        Component={VehicleEntryListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createStudentVehicleEntry, true)}
        Component={CreateVehicleEntry}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentTransportScheduleList, true)}
        Component={TransportSceduleList}
      />
    </Routes>
  );
};

export default StudentPortalRoutes;
