import { FC, Fragment, useEffect, useState } from "react";
import {
  AddFacultyVisitorsMain,
  ContentWrapper,
  Container,
  AddFacultyVisitorsSection,
} from "./style";
import {
  CloseMediumSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import useAcademics from "containers/private/academics/useHooks";
import { CoursePoolBodyDTO } from "utils/helpers/models/academics/course-pool-body.dto";
import useUtils from "hooks/useUtils";
import { warningToaster } from "utils/helpers/common/alert-service";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";

interface AddFacultyVisitorsProps {
  setOpen: Function;
}

const AddFacultyVisitors: FC<AddFacultyVisitorsProps> = ({ setOpen }) => {
  const handleCloseModal = () => {
    setOpen(false);
  };
  const { getQueryParams, handleSearchChange, handleTableSearch } = useUtils();
  const { pool_id } = getQueryParams();
  const [formData, setFormData] = useState<CoursePoolBodyDTO>({ ...new CoursePoolBodyDTO(), pool_id });
  const [search, setSearch] = useState<string>('');
  const [data, setData] = useState<any[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const { getTeachers, createPoolCourseBody } = useAcademics();
  const columns: string[] = [
    "",
    "Name",
    "CNIC",
    "Designation",
    // "Department",
    "Job Status",
  ];
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  useEffect(() => {
    getAllTeachers(pagination.page, search);
  }, []);

  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
    getAllTeachers(page + 1, search);
  };
  const getAllTeachers = (page: number, search: string) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
    };
    getTeachers(setData, queryParams, setPagination);
  };

  const handleSelectTeacher = (teacherId: number) => {
    if (formData.teacher_id.includes(teacherId)) {
      const index = formData.teacher_id.indexOf(teacherId);
      formData.teacher_id.splice(index, 1);
    } else {
      formData.teacher_id.push(teacherId);
    }

    setFormData({ ...formData });
  }

  const onSubmit = () => {
    if (!formData.teacher_id.length) {
      warningToaster('Please select atleast one teacher');
    } else {
      createPoolCourseBody(formData, setOpen);
    }
  }

  const resetSelection = () => {
    setFormData({ ...formData, teacher_id: [] });
  }

  return (
    <AddFacultyVisitorsMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Add Teachers From Existing Visiting Faculty</span>
            </div>
            <div className="close-icon cp" onClick={handleCloseModal}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <AddFacultyVisitorsSection
            isTableOverflowing={false}
            className="content-radius-shadow"
          >
            <div className="list-header">
              <div className="table-data-export-buttons">
                <div className="export-btn">
                  <span>
                    <PdfSvg className="icon" />
                  </span>
                  <span className="text">PDF</span>
                </div>

                <div className="export-btn">
                  <span>
                    <ExcelSvg className="icon" />
                  </span>
                  <span className="text">Excel</span>
                </div>
              </div>
              <div className="table-search-field">
                <span className="search-icon">
                  <SearchFieldSvg className="icon" />
                </span>
                <input
                  type="search"
                  placeholder="Search"
                  value={search}
                  onChange={(e) =>
                    handleSearchChange(e, setSearch, getAllTeachers)
                  }
                  onKeyUp={(e) => handleTableSearch(e, getAllTeachers)}
                />
              </div>
            </div>
            <div className="data-table">
              <table className="bottom-bordered-cells">
                <thead>
                  <tr>
                    {columns.map((column: string, index: number) => {
                      return <th key={index}>{column}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data.map((teacher, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="table-checkbox-field">
                            <input type="checkbox" checked={formData.teacher_id.includes(teacher.id)} onChange={() => handleSelectTeacher(teacher.id)} />
                          </div>
                        </td>
                        <td>
                          <div className="mw-150">
                            {`${teacher?.teacher_firstname} ${teacher?.teacher_middlename} ${teacher?.teacher_lastname}`}
                          </div>
                        </td>
                        <td>
                          <div className="mw-100">
                            {teacher?.cnic}
                          </div>
                        </td>
                        <td>Visiting Teacher/Part time Teacher</td>
                        {/* <td>
                          Department of Information & Communication Engineering
                        </td> */}
                        <td>
                          {teacher?.is_active ? <span className="status-tile">Active</span> : <span className="status-tile red">Inactive</span>}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <Fragment>
              <DataNotFound show={!isLoading && !data.length} />
              <Pagination onPageChange={onPageChange} {...pagination} />
            </Fragment>
            <div className="action-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" onClick={resetSelection}>
                  Reset
                </button>
                <button className="lg-rounded-btn" onClick={onSubmit}>Add Selected</button>
              </div>
            </div>
          </AddFacultyVisitorsSection>
        </ContentWrapper>
      </Container>
    </AddFacultyVisitorsMain>
  );
};

export default AddFacultyVisitors;
