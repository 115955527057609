import useUtils from "hooks/useUtils";
import { QEC_APIS } from "libs/apis/qec.api";
import { useNavigate } from "react-router-dom";
import { successToaster } from "utils/helpers/common/alert-service";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const useQEC = () => {
  const navigate = useNavigate();
  const { getQueryParams } = useUtils();

  const getSurveyTypes = async (setData: Function) => {
    const response = await QEC_APIS.getSurveyTypes();
    console.log(response);
    if (response?.status) {
      setData(response?.response?.data);
    }
  };

  const createSurveyTypes = async (body: any) => {
    const response = await QEC_APIS.createSurveyTypes(body);
    if (response?.status) {
      successToaster(response?.message);
    }
  };

  return {
    getSurveyTypes,
    createSurveyTypes,
  };
};

export default useQEC;
