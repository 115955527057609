import CreateAdvertisement from "containers/private/careers/advertisement/create";
import AdvertisementListing from "containers/private/careers/advertisement/listing";
import CareerApplicantListing from "containers/private/careers/applicants";
import CreateDesignation from "containers/private/careers/designation/create";
import DesignationListing from "containers/private/careers/designation/listing";
import CreatePostTemplate from "containers/private/careers/post-templates/create";
import PostTemplateListing from "containers/private/careers/post-templates/listing";
import CreatePost from "containers/private/careers/post/create";
import PostListing from "containers/private/careers/post/listing";
import useUtils from "hooks/useUtils";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const CareerRoutes: FC = () => {
  const { getPathToSetRoute } = useUtils();
  return (
    <Routes>
      <Route
        path={getPathToSetRoute(siteRoutes.careerDesignationListing, true)}
        Component={DesignationListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createCareerDesignation, true)}
        Component={CreateDesignation}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.careerAdvertisementListing, true)}
        Component={AdvertisementListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createCareerAdvertisement, true)}
        Component={CreateAdvertisement}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.careerPostTemplateListing, true)}
        Component={PostTemplateListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createCareerPostTemplate, true)}
        Component={CreatePostTemplate}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.careerPostListing, true)}
        Component={PostListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createCareerPost, true)}
        Component={CreatePost}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.careerApplicantListing)}
        Component={CareerApplicantListing}
      />
    </Routes>
  );
};

export default CareerRoutes;
