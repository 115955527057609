import { FC, useState, useEffect } from "react";
import {
  CreateManageMeritSection,
  CreateManageMeritListMain,
  CreateManageMeritListTop,
} from "./style";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import Breadcrumb from "components/particles/breadcrumb";
import useAdmissions from "../../../admissions/useHooks";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { ProSpececilizationDTO } from "utils/helpers/models/academics/program-specialization.dto";
import useAcademics from "../../useHooks";
import MultiselectDropdown from "components/particles/forms/multiselect-dropdown";
import useComponentVisible from "hooks/click-outside";
import useOrganization from "containers/private/organization/useHooks";
import { warningToaster } from "utils/helpers/common/alert-service";
import { warningMessages } from "utils/helpers/enums/messages.enum";
import useUtils from "hooks/useUtils";
import { useSelector } from "react-redux";

const CreateProgramSpecialization: FC = () => {
  const [sessions, setSessions] = useState<any[]>([]);
  const [programs, setPrograms] = useState<any[]>([]);
  const [formData, setFormData] = useState<ProSpececilizationDTO>(
    new ProSpececilizationDTO()
  );
  const [searchedPrograms, setSearchedPrograms] = useState<any[]>([]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<ProSpececilizationDTO>({
    defaultValues: formData,
    mode: "onChange",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue(name, value);
    formData[name as keyof ProSpececilizationDTO] = value as never;
    setFormData({ ...formData });
    trigger(name);
  };
  const {
    createProgramSpecialization,
    getAcademicSessions,
    updateProgramSpecialization,
    getProgramSpecializationById,
  } = useAcademics();
  const { getPrograms } = useOrganization();
  const { getQueryParams } = useUtils();

  const params = getQueryParams();

  const onSubmit = () => {
    if (params?.id) {
      updateProgramSpecialization(params?.id, formData);
    } else {
      createProgramSpecialization(formData);
    }
  };
  useEffect(() => {
    const queryParams = {
      per_page: "All",
    };
    if (params?.id) {
      getProgramSpecializationById(params?.id, formData, setValue, setFormData);
    }
    getPrograms(setPrograms, queryParams);
    getAllAcademicSessions(pagination.page, search);
  }, []);
  const {
    isComponentVisible: showDropdown,
    setIsComponentVisible: setShowDropdown,
    ref: dropdownRef,
  } = useComponentVisible();
  const [search, setSearch] = useState("");
  const handleSearchChange = (event: any) => {
    const { value } = event.target;
    setSearch(value);
    const programsHelper = programs.filter((program) =>
      program.title.toLowerCase().includes(search.toLowerCase())
    );
    setSearchedPrograms([...programsHelper]);
  };
  const [length, setLength] = useState<any>();

  const onSelectPrograms = (program: any) => {
    const { selected, id } = program;

    setFormData((prevFormData) => {
      const programIdsArray = prevFormData.program_id
        ? prevFormData.program_id.split(",").filter(Boolean)
        : [];

      const updatedProgramIds = selected
        ? [...programIdsArray, String(id)]
        : programIdsArray.filter((programId) => programId !== String(id));

      const programIdsString = updatedProgramIds.join(",");
      setLength(updatedProgramIds);

      // Log the actual count of selected IDs, not the character length of the string
      console.log("Count of selected Program IDs:", updatedProgramIds.length);
      console.log("Updated Program IDs:", programIdsString);

      return {
        ...prevFormData,
        program_id: programIdsString,
      };
    });
  };

  // Optional: useEffect to track changes to program_id as an array count
  useEffect(() => {
    if (formData.program_id) {
      const selectedProgramIds = formData.program_id.split(",").filter(Boolean);
      console.log(
        "Count of selected Program IDs in effect:",
        selectedProgramIds.length
      );
    }
  }, [formData.program_id]);

  const [data, setData] = useState<[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const getAllAcademicSessions = (page: number, search: string) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
    };
    getAcademicSessions(setData, queryParams, setPagination);
  };
  const resetForm = () => {
    for (let key in formData) {
      (formData as any)[key] = new ProSpececilizationDTO()[
        key as keyof ProSpececilizationDTO
      ];
      setValue(key as keyof ProSpececilizationDTO, (formData as any)[key]);
    }

    setFormData({ ...formData });
  };

  const breadcrumbLinks = [
    { title: "Academics /", path: siteRoutes.admissionCampaignListing },
    {
      title: "Program Specialization Session / ",
      path: siteRoutes.academicProgramSpecializationListing,
    },
    {
      title: `${params?.id ? "Update" : "Add"} Program Specialization`,
      path: siteRoutes.academicCreateProgramSpecialization,
    },
  ];

  return (
    <CreateManageMeritListMain>
      <CreateManageMeritListTop>
        <div className="left">
          <span className="page-heading">
            {params?.id ? "Update" : "Add"} Program Specialization Session
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right"></div>
      </CreateManageMeritListTop>

      <CreateManageMeritSection className="p-custom-scrollbar-8">
        <form>
          <div className="common-fields">
            <div className="input-field ">
              <label>Academic Session </label>
              <div className="field-wrap">
                <div className="field">
                  <select
                    {...register("session_id", { required: true })}
                    value={formData.session_id}
                    onChange={handleChange}
                  >
                    <option value="">Select Academic Session</option>
                    {data?.map((session: any, index: number) => {
                      return (
                        <option value={session.id} key={index}>
                          {session.type}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <FormErrorMessage error={errors?.session_id} />
              </div>
            </div>
            <div className="multiselect-field" ref={dropdownRef}>
              <div
                className="input-field"
                onClick={() => setShowDropdown(true)}
              >
                <label>Program Listing</label>
                <div className="field-wrap">
                  <div className="field">
                    <div className="selected-items">
                      <input
                        type="search"
                        placeholder="Select Program(s)"
                        value={search}
                        onChange={handleSearchChange}
                      />
                      {formData.program_id.length ? (
                        <div className="item">{length?.length} selected</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {showDropdown && (
                <MultiselectDropdown
                  options={search === "" ? programs : searchedPrograms}
                  value={
                    formData.program_id
                      ? formData.program_id.split(",").map((id) => Number(id))
                      : []
                  }
                  onSelect={onSelectPrograms}
                />
              )}
            </div>

            <div className="input-field ">
              <label>Start Date </label>
              <div className="field-wrap">
                <div className="field">
                  <input
                    type="date"
                    {...register("start_date", { required: true })}
                    value={formData.start_date}
                    onChange={handleChange}
                  />
                </div>
                <FormErrorMessage error={errors?.start_date} />
              </div>
            </div>

            <div className="input-field ">
              <label>End Date </label>
              <div className="field-wrap">
                <div className="field">
                  <input
                    type="date"
                    {...register("end_date", { required: true })}
                    value={formData.end_date}
                    onChange={handleChange}
                  />
                </div>
                <FormErrorMessage error={errors?.end_date} />
              </div>
            </div>
          </div>

          <div className="common-fields">
            <div className="radio-field">
              <label>Status</label>
              <div className="field-wrap">
                <div className="field">
                  <input
                    type="radio"
                    {...register("is_active", { required: true })}
                    checked={formData.is_active == 1}
                    onChange={handleChange}
                    value={1}
                  />
                  <label htmlFor="active">Active</label>
                </div>
                <div className="field">
                  <input
                    type="radio"
                    {...register("is_active", { required: true })}
                    checked={formData.is_active == 0}
                    onChange={handleChange}
                    value={0}
                  />
                  <label htmlFor="deactivate">Deactivate</label>
                </div>
                <FormErrorMessage error={errors?.is_active} />
              </div>
            </div>
          </div>

          <div className="action-buttons">
            <div className="buttons">
              <button
                className="lg-rounded-btn gray"
                type="button"
                onClick={resetForm}
              >
                Reset
              </button>

              <button
                onClick={onSubmit}
                type="button"
                className="lg-rounded-btn"
              >
                Save & Exit
              </button>
            </div>
          </div>
        </form>
      </CreateManageMeritSection>
    </CreateManageMeritListMain>
  );
};

export default CreateProgramSpecialization;
