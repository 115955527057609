import { FC, Fragment, useEffect, useState } from "react";
import {
  BlackArrowSvg,
  CallTableSvg,
  DarkEyeSvg,
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  DownloadYellowSvg,
  EditTableSvg,
  ExcelSvg,
  MessageTableSvg,
  PdfSvg,
  SearchFieldSvg,
  StopActionSvg,
  UserActionSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import {
  UsersListingMain,
  UsersListingSection,
  UsersListingTop,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useSystemAdministration from "../../useHooks";
import useUtils from "hooks/useUtils";
import useOrganization from "containers/private/organization/useHooks";
import useAlert from "hooks/useAlert";
import { UserManagementDTO } from "utils/helpers/models/system-administration/user-management.dto";
import KalaAvatar from "../../../../../assets/images/common/others/avatar-9.jpg";
import Breadcrumb from "components/particles/breadcrumb";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
const UsersListing: FC = () => {
  const breadcrumbLinks = [
    { title: "System Administration  /", path: "" },
    { title: "Users", path: siteRoutes.systemUsersListing },
  ];
  const navigate = useNavigate();
  const { getUsers, deleteUserById, getRoles } = useSystemAdministration();
  const { getCitiesAdmin } = useOrganization();
  const [search, setSearch] = useState<string>("");
  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const [data, setData] = useState<UserManagementDTO[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [filters, setFilters] = useState({
    city: "",
    cnic: "",
    status: "",
  });
  const { hasAccess } = useStore();
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const { handleSearchChange, handleTableSearch } = useUtils();
  const [role, setRole] = useState<any[]>([]);
  useEffect(() => {
    getAllUsers(pagination.page, search, filters);
    getRoles(setRole);
    getCitiesAdmin(setCities);
  }, []);
  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);

  const columns: string[] = [
    "",
    "image",
    "Name",
    "CNIC",
    "Gender",
    "City",
    "Country",
    "status",
    "Adrress",
    "Passport No.",
    "Created at",
    "CNIC Image",
    "Action",
  ];
  const { confirmationPopup } = useAlert();
  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
    getAllUsers(page + 1, search, filters);
  };

  const getAllUsers = (page: number, search: string, filters: any = {}) => {
    const filtersToSend: any = {};
    for (let key in filters) {
      if (filters[key]) {
        filtersToSend[key] = filters[key];
      }
    }
    const queryParams: any = {
      per_page: pagination.per_page,
      page,
      search,
      ...filtersToSend,
    };
    getUsers(setData, queryParams, setPagination);
  };

  const toggleRowExpand = (index: number) => {
    (data as any)[index].isExpanded = !(data as any)[index].isExpanded;
    setData([...data]);
  };

  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };

  const goToCreateUSer = () => {
    navigate(siteRoutes.createSystemUsers);
  };

  const handleChangeFilter = (event: any) => {
    const { value, name } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const goToUpdateUser = (userId: number) => {
    navigate(`${siteRoutes.createSystemUsers}?id=${userId}`);
  };

  const handleDelete = async (id: number) => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      deleteUserById(id, setData, pagination, setPagination);
    }
  };

  const goToViewCnicImage = (path: string | null) => {
    if (path) window.open(path, "_blank");
  };

  return (
    <UsersListingMain>
      <UsersListingTop>
        <div className="left">
          <span className="page-heading">Users</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="create-org-btn">
            <button className="lg-rounded-btn" onClick={goToCreateUSer}>
              + Add New
            </button>
          </div>
        </div>
      </UsersListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              {/* <div className="input-field">
                <label> User Type</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">All</option>
                    </select>
                  </div>
                </div>
              </div> */}
              <div className="input-field">
                <label>CNIC</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="cnic"
                      value={filters.cnic}
                      onChange={handleChangeFilter}
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>City</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.city}
                      onChange={handleChangeFilter}
                      name="city"
                    >
                      <option value="">Select City</option>
                      {cities?.map((city, index) => {
                        return (
                          <option value={city.id} key={index}>
                            {city.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="no">Status</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.status}
                      onChange={handleChangeFilter}
                      name="status"
                    >
                      <option value="">Select status</option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                {/* <button className="lg-rounded-btn gray" onClick={handleResetFilters}>Reset</button> */}
                <button
                  className="lg-rounded-btn"
                  onClick={() => getAllUsers(1, search, filters)}
                >
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>
      <UsersListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {hasAccess(sitePermissions.downloadStudentRegPDF) && (
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>
            )}

            {hasAccess(sitePermissions.downloadStudentRegExcel) && (
              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            )}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => handleSearchChange(e, setSearch, getAllUsers)}
              onKeyUp={(e) => handleTableSearch(e, getAllUsers)}
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return (
                    <th
                      className={
                        index > 7 ? `extra-head extra-head-${index - 7}` : ""
                      }
                    >
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <tr className={`expandable ${item.isExpanded && "opened"}`}>
                      <td>
                        <div
                          className="rounded-expand-button"
                          onClick={() => toggleRowExpand(index)}
                        >
                          <span>{item.isExpanded ? "-" : "+"}</span>
                        </div>
                      </td>
                      {/* <img src={KalaAvatar} alt="" /> */}
                      <td>
                        <img
                          src={
                            item?.profile_image && item.profile_image !== ""
                              ? item.profile_image
                              : KalaAvatar
                          }
                          alt="Profile"
                          style={{
                            height: "35px", // Adjust height as needed
                            width: "35px", // Adjust width as needed
                            borderRadius: "50%", // For circular image
                            objectFit: "cover", // Ensures the image fits within the given dimensions
                          }}
                        />
                      </td>

                      <td>
                        <div>{item?.name}</div>
                      </td>
                      <td>
                        <div className="mw-150">{item?.cnic}</div>
                      </td>
                      <td>
                        <div className="mw-100">{item?.gender}</div>
                      </td>
                      <td>
                        <div className="mw-150">
                          {item?.city_relation?.title}
                        </div>
                      </td>
                      <td>
                        <div className="mw-150">
                          {item?.country_relation?.name}
                        </div>
                      </td>
                      <td>
                        <div className="">
                          {" "}
                          {item?.status ? (
                            <span className="status-tile">Active</span>
                          ) : (
                            <span className="status-tile red">Inactive</span>
                          )}
                        </div>
                      </td>
                      <td className="extra-col extra-col-1">{item?.address}</td>
                      <td className="extra-col extra-col-2">
                        {item?.passport_no}
                      </td>

                      <td className="extra-col extra-col-3">
                        {" "}
                        {new Date(item?.created_at).toISOString().split("T")[0]}
                      </td>
                      {item?.cnic_image ? (
                        <td
                          className="extra-col extra-col-4"
                          onClick={() => goToViewCnicImage(item?.cnic_image)}
                        >
                          {" "}
                          <DarkEyeSvg />
                        </td>
                      ) : (
                        <td>--</td>
                      )}

                      <td className="extra-col extra-col-5">
                        {" "}
                        <div className="table-action-icons">
                          {hasAccess(sitePermissions.studentRegEdit) && (
                            <div
                              className="action-icon"
                              onClick={() => goToUpdateUser(item?.id)}
                            >
                              <EditTableSvg />
                            </div>
                          )}

                          <div className="action-icon">
                            <CallTableSvg />
                          </div>

                          <div className="action-icon">
                            <MessageTableSvg />
                          </div>
                          {hasAccess(sitePermissions.deleteUserByAdmin) && (
                            <div
                              className="action-icon"
                              onClick={() => handleDelete(item.id)}
                            >
                              <DeleteTableSvg />
                            </div>
                          )}
                          <div className="action-icon">
                            <UserActionSvg />
                          </div>
                          <div className="action-icon">
                            <StopActionSvg />
                          </div>
                          <div className="action-icon">
                            <DownloadYellowSvg />
                          </div>
                          <div className="action-icon">
                            <BlackArrowSvg />
                          </div>
                        </div>
                      </td>
                    </tr>

                    {item.isExpanded && (
                      <tr className="expanded-row">
                        <td></td>
                        <td colSpan={7}>
                          <div className="expanded-content">
                            <div className="particular-info particular-info-1">
                              <span className="title">Address</span>
                              <span className="info">{item?.address}</span>
                            </div>
                            <div className="particular-info particular-info-2">
                              <span className="title">Passport No.</span>
                              <span className="info">{item?.passport_no}</span>
                            </div>
                            <div className="particular-info particular-info-3">
                              <span className="title">Created Date</span>
                              <div className="info">
                                <span className="status">
                                  {
                                    new Date(item?.created_at)
                                      .toISOString()
                                      .split("T")[0]
                                  }
                                </span>
                              </div>
                            </div>
                            {item?.cnic_image ? (
                              <div className="particular-info particular-info-4">
                                <span className="title">CNIC Image</span>
                                <div className="info">
                                  <span className="status">
                                    <div
                                      className="action-icon cp"
                                      onClick={() =>
                                        goToViewCnicImage(item?.cnic_image)
                                      }
                                    >
                                      <DarkEyeSvg />
                                    </div>
                                  </span>
                                </div>
                              </div>
                            ) : null}

                            <div className="particular-info particular-info-5">
                              <span className="title">Action</span>
                              <div className="info">
                                <div className="table-action-icons">
                                  {hasAccess(
                                    sitePermissions.studentRegEdit
                                  ) && (
                                    <div
                                      className="action-icon"
                                      onClick={() => goToUpdateUser(item?.id)}
                                    >
                                      <EditTableSvg />
                                    </div>
                                  )}

                                  <div className="action-icon">
                                    <CallTableSvg />
                                  </div>

                                  <div className="action-icon">
                                    <MessageTableSvg />
                                  </div>
                                  {hasAccess(
                                    sitePermissions.deleteUserByAdmin
                                  ) && (
                                    <div
                                      className="action-icon"
                                      onClick={() => handleDelete(item.id)}
                                    >
                                      <DeleteTableSvg />
                                    </div>
                                  )}
                                  <div className="action-icon">
                                    <UserActionSvg />
                                  </div>
                                  <div className="action-icon">
                                    <StopActionSvg />
                                  </div>
                                  <div className="action-icon">
                                    <DownloadYellowSvg />
                                  </div>
                                  <div className="action-icon">
                                    <BlackArrowSvg />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </UsersListingSection>
    </UsersListingMain>
  );
};

export default UsersListing;
